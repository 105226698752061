import React, { useEffect, useRef, useState } from 'react';
import Page from 'src/components/Page';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  CardHeader,
  Button,
  MenuItem,
  Menu,
  Divider,
  Tooltip,
} from '@material-ui/core';
import {
  LineSegment,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTheme,
} from 'victory';
import {
  countReducer,
  getAttendanceMetrics,
  getAttendanceTrendline,
  getContractorsStats,
  getCountsData,
  getFieldNoteMetrics,
  getFinancialProgressMetrics,
  getLabourExpenseMetrics,
  getLabourTurnoverMetrics,
  getMandays,
  getPayments,
  getWorkeforce,
  getWorkerSkills,
  getWorkforceMetrics,
} from 'src/redux/count';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { getSkills } from 'src/redux/jobs';
import activeProjectsImage from '../../assests/active projects.png';
import defaultProjectImage from '../../assests/Projects Icon.png';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { VictoryTooltip } from 'victory-tooltip';
import AddIcon from '@material-ui/icons/Add';

import { Bar } from 'react-chartjs-2';

import FieldNoteCardComponent from 'src/components/FieldNoteCardComponent';
// import Carousel from "react-material-ui-carousel";
import {
  downloadFIeldNoteReport,
  fieldNoteReducer,
  getFieldNoteList,
  markFieldNoteAction,
} from 'src/redux/fieldNote';
import toast, { Toaster } from 'react-hot-toast';

import DownloadButtonFieldNote from '../../assests/downloadButtonFieldNote.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import TimerIcon from '@material-ui/icons/Timer';
import RestoreIcon from '@material-ui/icons/Restore';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import BlockIcon from '@material-ui/icons/Block';
import {
  getBOQProgress,
  getFinancialProgressData,
  getProjectBudget,
  productivityReducer,
} from 'src/redux/productivity';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BOQProgressGreenContainer from 'src/components/productivity/BOQProgressGreenContainer';
import chart from '../../assests/chart.png';
import ImageGallery from 'react-image-gallery';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { Doughnut } from 'react-chartjs-2';
import { getContractors } from 'src/redux/users';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '10px',

    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    borderRadius: '3px',

    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  cardPaddingRight1: {
    paddingRight: '1.5rem',
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '100%',
  },

  caroselHeight: {
    height: '38em',
  },
  cardMarginRight: {
    marginRight: '2rem',
  },
  cardHeight: {
    height: '450px',
    marginBottom: '0px',
  },
  paddingRight: {
    paddingRight: '1.5rem',
  },
  customWidth: {
    maxWidth: 80,
  },
  robotoFont: {
    fontFamily: 'Roboto',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },

    padRight: {
      paddingRight: '0',
    },
    padLeft: {
      paddingLeft: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
    cardMarginRight: {
      marginRight: '0px',
    },
    cardHeight: {
      height: '40em',
      marginBottom: '1.5rem',
    },
    paddingRight: {
      paddingRight: '0',
    },
    cardPaddingRight1: {
      paddingRight: '0',
    },
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Dashboard() {
  const [classifiedProject, setClassifiedProject] = useState('');
  const [pieSelectedDate, setPieSelectedDate] = useState(new Date());
  const [currentProjectContractor, setCurrentProjectContractor] = useState('');
  const [currentProjectSkillset, setCurrentProjectSkillset] = useState('');

  //Attendance Graph
  const [attendanceSelectedDate, setAttendanceSelectedDate] = useState(null);
  const [attendanceCurrentWeek, setAttendanceCurrentWeek] = useState(null);
  const [currentProject, setCurrentProject] = useState('');

  //Payments Graph
  const [currentProjectPayments, setCurrentProjectPayments] = useState('');
  const [paymentsSelectedDate, setPaymentsSelectedDate] = useState(null);
  const [paymentsCurrentWeek, setPaymentsCurrentWeek] = useState(null);

  //Carousel
  // const [activeIndex, setActiveIndex] = useState(0);
  const [items, setItems] = useState([]);
  const emptyItems = [null, null];
  const [currentProjectCarousel, setCurrentProjectCarousel] = useState('');

  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [filterCarosuel, setFilterCarosuel] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterName, setFilterName] = React.useState('Filter');
  const [filteredFiledNote, setFilteredFiledNote] = React.useState(null);

  const [currentProjectFinancial, setCurrentProjectFinancial] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;
  const [currentProjectBudget, setCurrentProjectBudget] = useState('');

  const [LabourContractor, setLabourContractor] = useState('');
  const [currentProjectLabourTurnover, setCurrentProjectLabourTurnover] =
    useState('');
  const [labourTurnoverDate, setLabourTurnoverDate] = useState('');

  const handleItemClick = (index) => {
    setCurrentIndex(index);
  };
  const handleLabourContractor = async (newValue) => {
    setLabourContractor(newValue);

    if (!newValue) {
      dispatch(getFieldNoteMetrics(currentProjectCarousel?.projectId));

      let resp = await dispatch(
        getFieldNoteList(currentProjectCarousel?.projectId)
      );
      if (resp?.status === 200) {
        setItems(resp?.data);
      }

      return;
    }

    dispatch(
      getFieldNoteMetrics(currentProjectCarousel?.projectId, newValue?.userId)
    );
    let resp = await dispatch(getFieldNoteList(0, newValue?.userId));
    if (resp?.status === 200) {
      setItems(resp?.data);
    }
  };
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let currentUserRole = JSON.parse(localStorage.getItem('userDetails')).user
    .userTypeId;
  let leadTypeId = JSON.parse(localStorage.getItem('userDetails')).user
    .leadTypeId;
  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const { fieldNoteList } = useSelector(fieldNoteReducer);
  const {
    financialGraphData,
    projectBudgetLoading,

    projectBudgetData,
  } = useSelector(productivityReducer);
  const {
    loading,
    counts,
    // workforce,
    // mandays,
    workerSkill,
    // attendanceTrendlineLoading,
    attendanceTrendline,
    contractorsStats,
    paymentsLoading,
    payments,
    contractorsStatsLoading,
    attendanceMetrics,
    workforceMetrics,
    fieldNoteMetrics,
    fieldNoteMetricsLoading,
    labourExpenseMetrics,
    labourExpenseMetricsLoading,
    financialProgressMetrics,
    labourTurnoverMetrics,
  } = useSelector(countReducer);
  const [zoomDomain, setZoomDomain] = useState();
  const chartRef = useRef(null);

  const classifyProjectBOQGreenContainer = (project) => {
    if (project) {
      setCurrentProject(project);
      setCurrentProjectPayments(project);
      setCurrentProjectContractor(project);
      setCurrentProjectCarousel(project);
      setCurrentProjectFinancial(project);
      setCurrentProjectBudget(project);
      setCurrentProjectSkillset(project);
      setCurrentProjectLabourTurnover(project);
      dispatch(getLabourTurnoverMetrics(project?.projectId));
      dispatch(getContractors(project?.projectId));

      dispatch(getBOQProgress(project?.projectId));
      dispatch(getBOQProgress(project?.projectId));
      dispatch(getFieldNoteList(project?.projectId));
      dispatch(getBOQProgress(project?.projectId));

      //! METRICS
      dispatch(getLabourExpenseMetrics(project?.projectId));
      dispatch(getFieldNoteMetrics(project?.projectId));
      dispatch(getFinancialProgressMetrics(project?.projectId));
      dispatch(getLabourTurnoverMetrics(project?.projectId));
      dispatch(getWorkforceMetrics(project?.projectId));
      dispatch(getAttendanceMetrics(project?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          project.projectId
        )
      );
      dispatch(
        getAttendanceTrendline(
          project.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      dispatch(
        getContractorsStats(project.projectId, moment().format('YYYY-MM-DD'))
      );
      dispatch(
        getPayments(
          project.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    } else {
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
      setCurrentProjectLabourTurnover(projectClassificationList[0]);

      setCurrentProject(projectClassificationList[0]);
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getFieldNoteList(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));

      //! METRICS
      dispatch(
        getLabourExpenseMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(getFieldNoteMetrics(projectClassificationList[0]?.projectId));
      dispatch(
        getFinancialProgressMetrics(projectClassificationList[0][0]?.projectId)
      );
      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(getWorkforceMetrics(projectClassificationList[0]?.projectId));
      dispatch(getAttendanceMetrics(projectClassificationList[0]?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          projectClassificationList[0].projectId
        )
      );
      dispatch(
        getAttendanceTrendline(
          projectClassificationList[0].projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      dispatch(
        getContractorsStats(
          projectClassificationList[0].projectId,
          moment().format('YYYY-MM-DD')
        )
      );
      dispatch(
        getPayments(
          projectClassificationList[0].projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );

      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      dispatch(getFieldNoteList(projectClassificationList[0]?.projectId));
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
    }
  };

  const labels = workerSkill?.map(
    (item) =>
      `${item.skillCode} ${item.data.reduce(
        (total, num) => total + num.count,
        0
      )}`
  );

  const options = {
    tooltips: {
      callbacks: {
        // Customize tooltip label for each dataset
        label: function (tooltipItem, data) {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || '';
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          return `${
            workerSkill[tooltipItem.index]?.skill
          } - ${datasetLabel}: ${value}`;
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Skilled by workers',
        color: 'rgba(112, 112, 112, 1)',
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false, // Set to false to define custom width and height
    // width: 500, // Set the desired width
    // height: 300,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderRadius: 10, // Set the desired border radius for each bar
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: `Supervisor`,

        data: workerSkill?.map(
          (item) =>
            item?.data.filter((item) => item.skillType === 'Supervisor')[0]
              ?.count ?? 0
        ),
        backgroundColor: '#9872EF',
        stack: '1', // Add this line to stack the dataset
      },
      {
        label: `Skilled`,

        // data: workerSkill?.map((item) => item?.data[1]?.count ?? 0),
        data: workerSkill?.map(
          (item) =>
            item?.data.filter((item) => item.skillType === 'Skilled')[0]
              ?.count ?? 0
        ),
        backgroundColor: 'RGB(133, 186, 89)',
        stack: '1', // Add this line to stack the dataset
      },
      {
        label: `Helper`,

        // data: workerSkill?.map((item) => item?.data[2]?.count ?? 0),
        data: workerSkill?.map(
          (item) =>
            item?.data.filter((item) => item.skillType === 'Helper')[0]
              ?.count ?? 0
        ),
        backgroundColor: 'rgba(52, 152, 183, 1)',
        stack: '1', // Add this line to stack the dataset
      },
    ],
  };

  const markFieldNote = async (id, value) => {
    let resp = await dispatch(markFieldNoteAction(id, value));
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
      handleClose();
    } else {
      toast.error('Field Note Marked unsuccessful!', {
        position: 'top-right',
      });
    }
  };

  const markTheFieldNoteFromServer = async (id, action) => {
    let formData = new FormData();
    //formData.append('VerificationImage', image);
    formData.append('Action', action);
    let resp = await dispatch(markFieldNoteAction(id, formData));
    if (resp?.status === 200) {
      //dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      toast.error('Something went wrong! Please try again later', {
        position: 'top-right',
      });
    }
    //setOpenFileUploadModal(false);
  };

  const printStatus = (action) => {
    if (action == 'MarkAsResolved') {
      return 'Resolved';
    } else if (action == 'ReworkRequired') {
      return 'Rework';
    } else {
      return 'Pending';
    }
  };

  const aggregatedData = {};
  function getRandomColor(monthArray) {
    // const letters = "0123456789ABCDEF";
    // let color = "#";
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;
    let data = monthArray.map((item, index) => {
      return financialGraphData[index].costbySOW.colorCode;
    });
  }
  let data1 = {};

  // Ensure that financialGraphData is defined
  if (financialGraphData?.length > 0) {
    financialGraphData.forEach((item) => {
      const month = item.month;
      const costData = item.costbySOW;

      if (!aggregatedData[month]) {
        aggregatedData[month] = {};
      }

      if (Array.isArray(costData)) {
        costData.forEach((costItem) => {
          const label = costItem.label;
          const actualCost = costItem.actualCost;

          if (!aggregatedData[month][label]) {
            aggregatedData[month][label] = 0;
          }

          aggregatedData[month][label] += actualCost;
        });
      } else if (costData) {
        const label = costData.label;
        const actualCost = costData.actualCost;

        if (!aggregatedData[month][label]) {
          aggregatedData[month][label] = 0;
        }

        aggregatedData[month][label] += actualCost;
      }
    });

    const months = financialGraphData.map((item) => item.month); // Use the months from financialGraphData

    const labelSet = new Set();

    // Collect all unique labels across all months
    months.forEach((month) => {
      const labels = Object.keys(aggregatedData[month]);
      labels.forEach((label) => labelSet.add(label));
    });

    const datasets = Array.from(labelSet).map((label) => {
      return {
        label,
        data: months.map((month) => aggregatedData[month][label] || 0),
        backgroundColor: financialGraphData
          .filter(
            (item) =>
              Array.isArray(item.costbySOW) &&
              item.costbySOW.some((cost) => cost.label === label)
          )
          .map((item) => {
            const costItem = item.costbySOW.find(
              (cost) => cost.label === label
            );
            return costItem ? `#${costItem.colorCode}` : 'red'; // fallback to red if colorCode not found
          }),

        stack: '1',
      };
    });

    // Define your chart data and options
    data1 = {
      labels: months,
      datasets,
    };
  } else {
    // Handle the case where financialGraphData is empty
    // You might want to display an error message or take other appropriate actions.
  }

  const options1 = {
    plugins: {
      title: {
        display: true,
        text: 'Skilled by workers',
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    // width: 400, // Set the desired width
    // height: 289,

    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      yAxes: [
        {
          ticks: {
            callback: (label, index, labels) => {
              return '₹ ' + label;
            },
          },
        },
      ],
    },
    indexAxis: 'y',
  };

  //! LIFE-CYCLE-METHODS --------------------------------
  useEffect(() => {
    if (!counts) {
      dispatch(getCountsData());
      dispatch(getMandays(0));

      dispatch(getProjectsClassificationList());
      dispatch(getSkills());
    }
    if (projectClassificationList.length > 0) {
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      setCurrentProjectLabourTurnover(projectClassificationList[0]);
      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(
        getLabourExpenseMetrics(projectClassificationList[0]?.projectId)
      );
      dispatch(
        getFinancialProgressMetrics(projectClassificationList[0]?.projectId)
      );
      setCurrentProjectCarousel(projectClassificationList[0]);
      dispatch(getFieldNoteMetrics(projectClassificationList[0]?.projectId));
      dispatch(getFieldNoteList(projectClassificationList[0]?.projectId));
      // setCurrentProjectCarousel(projectClassificationList[0]);
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          projectClassificationList[0].projectId
        )
      );
      dispatch(
        getAttendanceTrendline(
          projectClassificationList[0].projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      dispatch(
        getContractorsStats(
          projectClassificationList[0].projectId,
          // moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
          moment().format('YYYY-MM-DD')

          // moment().endOf("year").format("YYYY-MM-DD HH:mm:ss")
        )
      );
      dispatch(
        getPayments(
          projectClassificationList[0].projectId,
          // moment().format("YYYY-MM-DD"),
          // moment().format("YYYY-MM-DD")
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );

      dispatch(getAttendanceMetrics(projectClassificationList[0]?.projectId));
      dispatch(getWorkforceMetrics(projectClassificationList[0].projectId));
      if (currentUserRole !== 'SuperAdmin') {
        dispatch(getWorkeforce(projectClassificationList[0].projectId));

        setCurrentProject(projectClassificationList[0]);
        setCurrentProjectContractor(projectClassificationList[0]);
        setCurrentProjectSkillset(projectClassificationList[0]);
        setCurrentProjectPayments(projectClassificationList[0]);
        setCurrentProjectFinancial(projectClassificationList[0]);
        classifyProjectBudget(projectClassificationList[0]);

        setClassifiedProject(projectClassificationList[0]);
      } else {
        classifyProjectBudget(projectClassificationList[0]);

        setCurrentProject(projectClassificationList[0]);
        setCurrentProjectContractor(projectClassificationList[0]);
        setCurrentProjectSkillset(projectClassificationList[0]);
        setCurrentProjectPayments(projectClassificationList[0]);
        setCurrentProjectFinancial(projectClassificationList[0]);

        setClassifiedProject(projectClassificationList[0]);
        dispatch(getWorkeforce(0));
      }
    }
    dispatch(getFinancialProgressData(projectClassificationList[0]?.projectId));
  }, [projectClassificationList?.length]);

  useEffect(() => {
    if (fieldNoteList?.length === 0) {
      setItems(null);
    }
  }, [fieldNoteList?.length, items?.length]);

  const classifyProjectFinancial = (project) => {
    if (project) {
      setCurrentProjectFinancial(project);
      dispatch(getFinancialProgressData(project?.projectId));
    } else {
      setCurrentProjectFinancial(projectClassificationList[0]);
      dispatch(
        getFinancialProgressData(projectClassificationList[0]?.projectId)
      );
    }
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
  };
  const handleDateRangeLabourTurnover = (date) => {
    if (!date) {
      dispatch(
        getLabourTurnoverMetrics(currentProjectLabourTurnover?.projectId)
      );
      setLabourTurnoverDate('');
    } else {
      dispatch(
        getLabourTurnoverMetrics(
          currentProjectLabourTurnover?.projectId,
          moment(date).format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setLabourTurnoverDate(date);
    }
  };
  let layout = 'app';
  let paymentYAxisLimit =
    payments?.length > 0 && payments?.map((item) => item.DueAmount);

  const workersCardObj = [
    {
      id: 3,
      name: 'Total Projects',
      workers: counts?.totalProjects,
      // img: "/static/images/bettamintImages/vector2.png",
      img: '/static/images/bettamintImages/dashboard1.png',

      percent: '+12%',
      link: `/${layout}/project`,
    },
    {
      id: 2,
      name:
        leadTypeId === 'LabourContractor'
          ? 'Active Worker'
          : 'Total Contractors',
      workers:
        leadTypeId === 'LabourContractor'
          ? counts?.activeWorkers
          : counts?.totalContractor,
      img: '/static/images/bettamintImages/active-workers.png',
      percent: '-5%',
      // link: `/${layout}/users`,
    },
    {
      id: 4,
      name: 'Worker Present',
      workers: counts?.workerPresent,
      // img: '/static/images/bettamintImages/vector2.png',
      img: '/static/images/bettamintImages/active-workers.png',

      link: `/${layout}/attendance`,

      percent: '+10%',
    },
    {
      id: 1,
      name: 'Worker Absent',
      workers: counts?.workerAbsent,
      // img: '/static/images/bettamintImages/dashboard1.png',
      img: '/static/images/bettamintImages/active-workers.png',

      percent: '+10%',
      link: `/${layout}/attendance`,
    },
  ];

  const getBarData = () => {
    return attendanceTrendline?.map((item) => ({
      x: item.Day,
      Absent: item.Absent ?? 0,
      Present: item.Present ?? 0,
    }));
  };

  let currentPresent = attendanceTrendline?.filter((item) => {
    if (moment().format('ddd').toUpperCase() === item.Day) {
      return item;
    }
  });

  let currentAbsent = attendanceTrendline?.filter((item) => {
    if (moment().format('ddd').toUpperCase() === item.Day) {
      return item;
    }
  });

  const classifyProject = (project) => {
    if (project) {
      dispatch(getAttendanceMetrics(project?.projectId));

      dispatch(
        getAttendanceTrendline(
          project.projectId,
          attendanceCurrentWeek?.startOfWeek
            ? attendanceCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          attendanceCurrentWeek?.endOfWeek
            ? attendanceCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setCurrentProject(project);
      const { projectId } = project;
      setClassifiedProject(
        projectClassificationList.filter(
          (project) => project.projectId === projectId && project
        )
      );
      // dispatch(getWorkeforce(projectId));
      // dispatch(getMandays(projectId));
    } else {
      dispatch(getAttendanceMetrics(projectClassificationList[0]?.projectId));

      setCurrentProject(projectClassificationList[0]);
      if (currentUserRole !== 'SuperAdmin') {
        setClassifiedProject(projectClassificationList[0]);
        dispatch(getWorkeforce(projectClassificationList[0]?.projectId));
        dispatch(getMandays(projectClassificationList[0]?.projectId));
      } else {
        setClassifiedProject('');
        dispatch(getWorkeforce(0));
        dispatch(getMandays(0));
      }
      dispatch(
        getAttendanceTrendline(
          projectClassificationList[0]?.projectId,
          attendanceCurrentWeek?.startOfWeek
            ? attendanceCurrentWeek?.startOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          attendanceCurrentWeek?.endOfWeek
            ? attendanceCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  const classifyProjectContractor = (project) => {
    if (project) {
      dispatch(
        getContractorsStats(
          project.projectId,
          moment(pieSelectedDate).format('YYYY-MM-DD')
          // moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
        )
      );
      setCurrentProjectContractor(project);
    } else {
      setCurrentProjectContractor(projectClassificationList[0]);

      dispatch(
        getContractorsStats(
          projectClassificationList[0].projectId,
          moment(pieSelectedDate).format('YYYY-MM-DD')
        )
      );
    }
  };

  const classifyProjectSkillset = (project) => {
    if (project) {
      dispatch(getWorkforceMetrics(project?.projectId));

      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          project.projectId
        )
      );
      setCurrentProjectSkillset(project);
    } else {
      dispatch(getWorkforceMetrics(projectClassificationList[0]?.projectId));

      setCurrentProjectSkillset(projectClassificationList[0]);
      dispatch(
        getWorkerSkills(
          '2022-08-31T19:00:00Z',
          moment().utc().format(),
          projectClassificationList[0].projectId
        )
      );
    }
  };
  const classifyProjectPayments = (project) => {
    if (project) {
      dispatch(getLabourExpenseMetrics(project?.projectId));

      dispatch(
        getPayments(
          project.projectId,
          paymentsCurrentWeek?.startOfWeek
            ? paymentsCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          paymentsCurrentWeek?.endOfWeek
            ? paymentsCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setCurrentProjectPayments(project);
    } else {
      dispatch(
        getLabourExpenseMetrics(projectClassificationList[0]?.projectId)
      );

      setCurrentProjectPayments(projectClassificationList[0]);
      dispatch(
        getPayments(
          projectClassificationList[0].projectId,
          paymentsCurrentWeek?.startOfWeek
            ? paymentsCurrentWeek.startOfWeek.format('YYYY-MM-DD HH:mm:ss')
            : moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          paymentsCurrentWeek?.endOfWeek
            ? paymentsCurrentWeek?.endOfWeek?.format('YYYY-MM-DD HH:mm:ss')
            : moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  const onChangeDatePayments = (date) => {
    if (!date) {
      setPaymentsSelectedDate(null);
      setPaymentsCurrentWeek(null);
      return dispatch(
        getPayments(
          currentProjectPayments.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
    // Calculate the start and end of the week
    const startOfWeek = moment(date).startOf('week'); // Sunday
    const endOfWeek = moment(date).endOf('week'); // Saturday
    setPaymentsSelectedDate(date);
    setPaymentsCurrentWeek({
      startOfWeek,
      endOfWeek,
    });

    dispatch(
      getPayments(
        currentProjectPayments.projectId,
        startOfWeek.format('YYYY-MM-DD HH:mm:ss'),
        endOfWeek.format('YYYY-MM-DD HH:mm:ss')
      )
    );
  };
  const onChangePieDate = (date) => {
    setPieSelectedDate(date);
    dispatch(
      getContractorsStats(
        currentProjectContractor.projectId,
        // moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
        moment(date).format('YYYY-MM-DD')
        // moment().endOf("year").format("YYYY-MM-DD HH:mm:ss")
      )
    );
  };
  const onChangeDateAttendance = (date) => {
    setAttendanceSelectedDate(date);
    // Calculate the start and end of the week
    // const startOfWeek = moment(date).startOf("week"); // Sunday
    // const endOfWeek = moment(date).endOf("week"); // Saturday
    const formatedDate = moment(date);

    if (date) {
      dispatch(
        getAttendanceTrendline(
          currentProject?.projectId,
          formatedDate.format('YYYY-MM-DD HH:mm:ss'),
          formatedDate.format('YYYY-MM-DD HH:mm:ss')
        )
      );
      setAttendanceCurrentWeek({
        startOfWeek: date,
        endOfWeek: date,
      });
    } else {
      setAttendanceCurrentWeek(null);
      dispatch(
        getAttendanceTrendline(
          currentProject?.projectId,
          moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
        )
      );
    }
  };

  // const handleDownloadExcel = () => {
  //   downloadExcel({
  //     fileName: File,
  //     sheet: "Filed Notes Sheet",
  //   });
  // };
  function generateRandomHexColors(numColors) {
    const colors = [];

    for (let i = 0; i < numColors; i++) {
      const randomColor =
        '#' + Math.floor(Math.random() * 16777215).toString(16); // Generate a random 6-digit hex color
      colors.push(randomColor);
    }

    return colors;
  }

  const classifyProjectCarousel = async (project) => {
    if (project) {
      dispatch(getContractors(project?.projectId));
      dispatch(getFieldNoteMetrics(project?.projectId));
      let resp = await dispatch(getFieldNoteList(project?.projectId));
      if (resp?.status === 200) {
        setItems(resp?.data);
      }
      setCurrentProjectCarousel(project);
    } else {
      setCurrentProjectCarousel(projectClassificationList[0]);
      dispatch(getFieldNoteMetrics(projectClassificationList[0]?.projectId));
      dispatch(getContractors(projectClassificationList[0]?.projectId));

      let resp = await dispatch(
        getFieldNoteList(projectClassificationList[0]?.projectId)
      );

      if (resp?.status === 200) {
        setItems(resp?.data);
      }
    }
  };

  const classifyProjectLabourTurnover = async (project) => {
    if (project) {
      dispatch(getLabourTurnoverMetrics(project?.projectId));

      setCurrentProjectLabourTurnover(project);
    } else {
      setCurrentProjectLabourTurnover(projectClassificationList[0]);
      dispatch(
        getLabourTurnoverMetrics(projectClassificationList[0]?.projectId)
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCarouselFilter = (filterBy, value) => {
    handleClose();
    if (filterBy === '') {
      setFilterName('Filter');
      setFilteredFiledNote(null);
    } else {
      setFilterName(filterBy);
      setFilteredFiledNote(
        fieldNoteList?.filter((item) => item.action === value)
      );
    }
  };
  function getMaxPresent(data) {
    if (data.length === 0) {
      return 0; // Return 0 if the array is empty
    }

    let maxPresent = data[0].present; // Initialize maxPresent with the first element's present value

    for (let i = 1; i < data.length; i++) {
      if (data[i].present > maxPresent) {
        maxPresent = data[i].present; // Update maxPresent if a larger value is found
      }
    }

    return maxPresent;
  }
  const handleFielNoteDownload = async () => {
    let resp = await dispatch(
      downloadFIeldNoteReport(currentProjectCarousel?.projectId)
    );
    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute(
        'download',
        `Field Note_ProjectId_${
          currentProjectCarousel?.projectId
        } ${moment().format('MMM - YYYY')}.xlsx`
      );

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };
  const renderWorkersCard = () => (
    <Grid
      container
      item
      xs={12}
      // style={{ border: '2px solid red' }}
      justify="space-between"
      alignItems="center"
      className={classes.cardPaddingRight}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        className={classes.cardPaddingRight1}
        alignItems="center"
        justify="space-between"
        // style={{ border: "2px solid red" }}
      >
        {workersCardObj.map(
          (val, index) =>
            index <= 1 && (
              <Grid
                container
                item
                md={6}
                xs={12}
                style={{
                  marginBottom: '1em',
                }}
                key={index + 1}
                // justify='center'
                alignItems="center"
                className={`${
                  index === 0 ? classes.padRight : classes.padLeft
                }`}
              >
                <Card
                  elevation={6}
                  style={{
                    borderRadius: 10,
                    width: '100%',
                    cursor: 'pointer',
                    height: '70px',
                  }}
                  onClick={() =>
                    val?.link
                      ? navigate(val.link, {
                          replace: true,
                          // state: { from: location },
                        })
                      : null
                  }
                >
                  <CardContent style={{ padding: '10px' }}>
                    <Grid
                      container
                      justify="space-between"
                      // alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid item>
                        <Grid item xs>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 12,
                              color: '#B2BEC7',
                            }}
                          >
                            {val.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          justify="space-between"
                          item
                          xs={12}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 20,
                                fontWeight: '500',
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{
                                    color: '#81B734',
                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              ) : (
                                val.workers
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={
                            {
                              // background: '#A179F2',
                              // height: 50,
                              // width: 50,
                              // borderRadius: 10,
                            }
                          }
                        >
                          {val.name === 'Total Contractors' ||
                          val.name === 'Worker Present' ? (
                            <img
                              src={val.img}
                              alt="logo1"
                              // style={{ width: '30px' }}
                            />
                          ) : (
                            <img
                              src={val.img}
                              alt="logo1"
                              // style={{ width: '30px' }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        // className={classes.cardPaddingRight}
        alignItems="center"
        justify="space-between"
      >
        {workersCardObj.map(
          (val, index) =>
            index > 1 && (
              <Grid
                container
                item
                md={6}
                xs={12}
                style={{
                  marginBottom: '1em',
                }}
                // className={index === 0 && classes.cardPaddingRight}
                key={index + 1}
                // justify='center'
                alignItems="center"
                // justifyContent="space-between"
                className={`${
                  index === 2 ? classes.padRight : classes.padLeft
                }`}
              >
                <Card
                  elevation={6}
                  style={{
                    borderRadius: 10,
                    width: '100%',
                    cursor: 'pointer',
                    height: '70px',
                  }}
                  onClick={() =>
                    navigate(val.link, {
                      replace: true,
                      // state: { from: location },
                    })
                  }
                >
                  <CardContent style={{ padding: '10px' }}>
                    <Grid
                      container
                      justify="space-between"
                      // alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid item>
                        <Grid item xs>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 12,
                              color: '#B2BEC7',
                            }}
                          >
                            {val.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          justify="space-between"
                          item
                          xs={12}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 20,
                                fontWeight: '500',
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{
                                    color: '#81B734',
                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              ) : (
                                val.workers
                              )}
                            </Typography>
                            {/* <Typography
                              fullWidth
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "9px",
                                fontWeight: "400",
                                color: "#B2BEC7",
                              }}
                            >
                              Daily Stats*
                            </Typography> */}
                          </Grid>
                          {/* <Grid item xs={1}>
                        <Typography
                          style={{
                            fontFamily: "Roboto",
                            color: val.percent === "-5%" ? "#FF0000" : "#81B734",
                            fontSize: 12,
                            fontWeight: "600",
                          }}
                        >
                          {val.percent}
                        </Typography>
                      </Grid> */}
                        </Grid>
                        {/* <Grid item xs>
                      <Typography
                        style={{
                          fontSize: 8,
                          fontFamily: "Roboto",
                          color: "#B2BEC7",
                        }}
                      >
                        Daily Status
                      </Typography>
                    </Grid> */}
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={{
                            background: '#A179F2',
                            // height: 50,
                            // width: 50,
                            borderRadius: 10,
                          }}
                        >
                          {val.name === 'Total Contractors' ||
                          val.name === 'Worker Present' ? (
                            <img
                              src={val.img}
                              alt="logo1"
                              // style={{ width: '30px' }}
                            />
                          ) : (
                            <img
                              src={val.img}
                              alt="logo1"
                              // style={{ width: '30px' }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );

  const renderCarts = () => (
    <Grid
      container
      item
      xs={12}
      className={classes.cardPaddingRight}
      alignItems="center"
      justify="space-between"
    >
      <Grid item md={6} xs={12} className={classes.cardPaddingRight1}>
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
            height: '520px',
          }}
          className={classes.cardHeight}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Attendance
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      fullWidth
                      className={classes.buttonPurpleOpacity}
                      // size="small"
                      onClick={() =>
                        navigate('../attendance', {
                          replace: true,
                          // state: { from: location },
                        })
                      }
                    >
                      Attendance
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: '.6rem' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item md={9} xs={12}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProject}
                      onChange={classifyProject}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    {' '}
                    <DatePicker
                      selected={attendanceSelectedDate}
                      onChange={onChangeDateAttendance}
                      // startDate={startDate}
                      // endDate={endDate}
                      // selectsRange
                      isClearable={true}
                      className="border-datepicker"
                      placeholderText="Date Filter"
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid
              // maxWidth="md"
              style={{
                height: '250px',
              }}
            >
              <VictoryChart
                domainPadding={{ x: 15, y: 30 }}
                theme={VictoryTheme.grayscale}
                width={520}
                padding={{ top: 15, bottom: 30, left: 10, right: 0 }}
                // height={250}
                // padding={{ top: 30, bottom: 30, left: 30, right: 0 }}
              >
                {/* <VictoryLegend
                  x={0}
                  y={0}
                  orientation="horizontal"
                  gutter={20}
                  colorScale={["RGB(133, 186, 89)", "#9872EF"]}
                  data={[{ name: "Present " }, { name: "Absent" }]}
                /> */}
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: 'black' }, // Adjust x-axis color as needed
                    ticks: { stroke: 'black' },
                    tickLabels: {
                      fill: 'rgba(112, 112, 112, 1)',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                    }, // Change label color
                  }}
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: 'black' }, // Adjust x-axis color as needed
                    ticks: { stroke: 'black' },
                    tickLabels: {
                      fill: 'rgba(112, 112, 112, 1)',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                    }, // Change label color
                  }}
                />
                <VictoryGroup
                  offset={20}
                  colorScale={['RGB(133, 186, 89)', '#9872EF', , 'grey']}
                >
                  <VictoryBar
                    cornerRadius={{ top: 4, bottom: 0 }}
                    barWidth={16}
                    data={getBarData()}
                    x="x"
                    labels={({ datum }) => datum.Present?.toLocaleString()}
                    // labelComponent={<VictoryLabel dy={50} />}
                    y="Present"
                    style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                    alignment="start"
                    barRatio={0.8}
                    labelComponent={
                      <VictoryTooltip
                        label={({ datum }) =>
                          `${datum.x}: ${datum.Present || datum.Absent}`
                        }
                        style={{
                          fill: 'white', // Set the background color to white
                        }}
                        flyoutStyle={{
                          padding: 10, // Adjust the padding as needed

                          // padding: "5px 1rem",
                          // color: "red",
                          fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                          stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                          strokeWidth: 2, // Border width of the tooltip box
                          // You can further customize other flyout styles as needed
                        }}
                        textStyle={{
                          padding: 8, // Add padding to the text content
                          // You can also customize other text styles like font size, font family, etc. here
                        }}
                      />
                    }
                  />
                  <VictoryBar
                    cornerRadius={{ top: 4, bottom: 0 }}
                    barWidth={16}
                    data={getBarData()}
                    x="x"
                    labels={({ datum }) => datum.Absent.toLocaleString()}
                    // labelComponent={<VictoryLabel dy={50} />}
                    y="Absent"
                    style={{ labels: { fill: '#9872EF' } }}
                    alignment="start"
                    barRatio={0.8}
                    labelComponent={
                      <VictoryTooltip
                        label={({ datum }) =>
                          `${datum.x}: ${datum.Present || datum.Absent}`
                        }
                        style={{
                          fill: 'white', // Set the background color to white
                        }}
                        flyoutStyle={{
                          padding: '10px', // Adjust the padding as needed
                          fill: '#9872EF', // Background color of the tooltip box
                          stroke: '#9872EF', // Border color of the tooltip box
                          strokeWidth: 2, // Border width of the tooltip box
                          // You can further customize other flyout styles as needed
                        }}
                        textStyle={{
                          padding: 8, // Add padding to the text content
                          // You can also customize other text styles like font size, font family, etc. here
                        }}
                      />
                    }
                  />
                </VictoryGroup>
              </VictoryChart>
            </Grid>
            <Grid container justify="space-between">
              <Grid item xs={6} container>
                {TooltipChart(
                  'Avg. Hours',
                  attendanceMetrics?.averageHoursWorked ?? 'N/A',
                  '',
                  6
                )}
                {TooltipChart(
                  'Over Time',
                  attendanceMetrics?.overTimeHours ?? 'N/A',
                  '',
                  6
                )}
              </Grid>
              <Grid
                item
                xs={6}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Present Workers
                  </Typography>
                  <div className="skilled-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Absent Workers
                  </Typography>
                  <div className="supervisor-box"></div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-bottom-dotted"></div>

            <Grid
              container
              item
              xs={12}
              justify="space-between"
              style={{ marginTop: '1.5rem' }}
            >
              <Grid
                container
                alignItems="center"
                item
                md={5}
                xs={12}
                justify="space-around"
                style={{
                  background: '#F9F9F9',
                  borderRadius: 10,
                  width: '100%',
                  // marginBottom: '1.5rem',
                }}
              >
                <Grid>
                  <Typography style={{ fontFamily: 'Roboto', fontSize: 13 }}>
                    Present <br /> Workers Today
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 40,
                      color: '#21D940',
                    }}
                  >
                    {currentPresent?.length > 0 ? (
                      currentPresent[0]?.Present
                    ) : (
                      <CircularProgress
                        style={{
                          color: '#21D940',

                          width: '25px',
                          height: '25px',
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                item
                md={5}
                xs={12}
                className={classes.marginTop}
                justify="space-around"
                style={{
                  background: '#F9F9F9',
                  borderRadius: 10,
                  width: '100%',
                }}
              >
                <Grid>
                  <Typography style={{ fontFamily: 'Roboto', fontSize: 13 }}>
                    Absent
                    <br />
                    Workers Today
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 40,
                      color: '#AF9CF6',
                    }}
                  >
                    {currentAbsent?.length > 0 ? (
                      currentAbsent[0]?.Absent
                    ) : (
                      <CircularProgress
                        style={{
                          color: '#AF9CF6',

                          width: '25px',
                          height: '25px',
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item md={6} xs={12} className={classes.cardPaddingRight}>
        <Card
          elevation={6}
          style={{
            width: '100%',
            height: '520px',

            borderRadius: 10,
            // height: '25em',
            // overflowX: 'scroll',
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid item container justify="space-between">
                  <Grid item>
                    <Typography
                      variant="subtitle"
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      {' '}
                      Workforce Composition
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      size="small"
                      fullWidth
                      className={classes.buttonPurpleOpacity}
                      onClick={() =>
                        navigate('../attendance', {
                          replace: true,
                          // state: { from: location },
                        })
                      }
                    >
                      View All
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  // justify="center"
                  xs={12}
                  style={{ marginTop: '.6rem' }}
                >
                  <FilterProjectComponent
                    projectClassificationList={projectClassificationList}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                    value={currentProjectSkillset}
                    onChange={classifyProjectSkillset}
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Container
              // maxWidth="md"
              style={{
                height: '250px',
              }}
            >
              {' '}
              <Bar options={options} data={data} />
            </Container>
            <Grid container>
              {TooltipChart('Shortfall', workforceMetrics, '')}

              <Grid
                item
                xs={6}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Supervisor
                  </Typography>
                  <div className="supervisor-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Skilled
                  </Typography>
                  <div className="skilled-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                      fontSize: '11px',
                    }}
                  >
                    Helper
                  </Typography>
                  <div className="helper-box"></div>
                </Grid>
              </Grid>
            </Grid>
            <div className="border-bottom-dotted"></div>

            <Grid
              container
              item
              xs={12}
              alignItems="center"
              I
              // justify="space-between"
              style={{ marginTop: '1.5rem' }}
            >
              <Grid
                item
                md={4}
                xs={12}
                container
                style={{
                  // background: "#F9F9F9",
                  // borderRadius: 10,
                  paddingRight: '10px',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  item
                  xs={12}
                  justify="space-around"
                  style={{
                    background: '#F9F9F9',
                    borderRadius: 10,
                    paddingRight: '10px',
                  }}
                >
                  <Grid>
                    <Typography style={{ fontFamily: 'Roboto', fontSize: 13 }}>
                      Total <br /> Supervisors
                    </Typography>
                  </Grid>
                  <Grid
                  // style={{
                  //   marginRight: "10px",
                  // }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 40,
                        color: '#AF9CF6',
                      }}
                    >
                      {workerSkill?.length > 0 ? (
                        workerSkill
                          ?.map((item) =>
                            item.data.find(
                              (skill) => skill.skillType === 'Supervisor'
                            )
                          )
                          .filter((item) => item !== undefined)
                          .reduce((total, value) => total + value.count, 0) ?? 0
                      ) : (
                        <CircularProgress
                          style={{
                            color: '#AF9CF6',

                            width: '25px',
                            height: '25px',
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                container
                style={{
                  // background: "#F9F9F9",
                  // borderRadius: 10,
                  paddingRight: '10px',
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  item
                  xs={12}
                  className={classes.marginTop}
                  justify="space-around"
                  style={{
                    background: '#F9F9F9',
                    borderRadius: 10,
                    width: '100%',
                  }}
                >
                  <Grid>
                    <Typography style={{ fontFamily: 'Roboto', fontSize: 13 }}>
                      Total
                      <br />
                      Skilled
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 40,
                        color: '#21D940',
                      }}
                    >
                      {workerSkill?.length > 0 ? (
                        workerSkill
                          ?.map((item) =>
                            item.data.find(
                              (skill) => skill.skillType === 'Skilled'
                            )
                          )
                          .filter((item) => item !== undefined)
                          .reduce((total, value) => total + value.count, 0) ?? 0
                      ) : (
                        <CircularProgress
                          style={{
                            color: '#21D940',

                            width: '25px',
                            height: '25px',
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                item
                md={4}
                xs={12}
                className={classes.marginTop}
                justify="space-around"
                style={{
                  background: '#F9F9F9',
                  borderRadius: 10,
                  width: '100%',
                }}
              >
                <Grid>
                  <Typography style={{ fontFamily: 'Roboto', fontSize: 13 }}>
                    Total
                    <br />
                    Helpers
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 40,
                      color: 'rgba(52, 152, 183, 1)',
                    }}
                  >
                    {workerSkill?.length > 0 ? (
                      workerSkill
                        ?.map((item) =>
                          item.data.find(
                            (skill) => skill.skillType === 'Helper'
                          )
                        )
                        .filter((item) => item !== undefined)
                        .reduce((total, value) => total + value.count, 0) ?? 0
                    ) : (
                      <CircularProgress
                        style={{
                          color: 'rgba(52, 152, 183, 1)',

                          width: '25px',
                          height: '25px',
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {leadTypeId === 'Contractor' && (
        <Grid
          item
          // md={6}
          xs={12}
          className={classes.marginTop}
          style={{ marginTop: '15px' }}
          container
          // justify='flex-end'
        >
          <Card
            elevation={12}
            style={{
              width: '100%',
              borderRadius: 10,
              minHeight: '400px', // Adjust the minimum height as needed
            }}
          >
            <CardHeader
              title={
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 25,
                        fontWeight: '400',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Contractors Data
                    </Typography>
                  </Grid>

                  {/* Your FilterProjectComponent goes here */}
                </Grid>
              }
              action={
                <Grid container alignItems="center">
                  <Grid item style={{ marginRight: '10px' }}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProjectContractor}
                      onChange={classifyProjectContractor}
                    />
                  </Grid>
                  <Grid
                    item
                    // container
                    // alignItems="center"
                    // justify="center"
                  >
                    {/* <Grid item> */}
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: '12px' }}
                    >
                      Date:
                    </Typography>
                    {/* </Grid> */}
                    {/* <Grid item style={{ width: "250px" }}> */}
                    <DatePicker
                      selected={pieSelectedDate}
                      onChange={onChangePieDate}
                      // startDate={startDate}
                      // endDate={endDate}
                      // selectsRange
                      isClearable={true}
                      className="border-datepicker"
                      placeholderText="Date Filter"
                    />
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* <VictoryPie
                  data={
                    contractorsStats?.length >= 0
                      ? contractorsStats[0]?.map((item) => ({
                          x: item.contractor,
                          y: item.present,
                        }))
                      : [
                          { x: "dummy", y: 100 },
                          { x: "dummy1", y: 30 },
                          { x: "dummy1", y: 10 },
                          { x: "dummy1", y: 220 },
                          { x: "dummy1", y: 330 },
                          { x: "dummy1", y: 310 },
                          { x: "dummy1", y: 390 },
                        ]
                  }
                  width={700} // Adjust the width as needed
                  height={700} // Adjust the height as needed
                  labels={({ datum }) => `${datum.x}`}
                  labelComponent={
                    <VictoryLabel
                      style={{ fontSize: 10 }} // Adjust the font size as needed
                      dy={-10} // Adjust the vertical position of labels
                    />
                  }
                  responsive={true}
                  padding={20} // Adjust the padding to control the spacing between slices and the container
                  innerRadius={80} // Adjust the inner radius for a donut chart effect
                  colorScale={generateRandomHexColors(
                    contractorsStats?.length >= 0
                      ? contractorsStats[0]?.length
                      : 100
                  )}
                  // Specify your custom colors here

                  // [("#21D940", "#A179F2")]}
                /> */}
              </div>
              <Grid container>
                <Grid item xs={5}>
                  <Typography
                    variant="subtitle"
                    style={{
                      color: 'rgba (173, 186,199, 0.2)',
                      fontSize: '18px',
                    }}
                  >
                    Contractors
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    variant="subtitle"
                    style={{
                      color: 'rgba (173, 186,199, 0.2)',
                      fontSize: '18px',
                    }}
                  >
                    {' '}
                    Workers Present
                  </Typography>
                </Grid>
                {contractorsStats?.length > 0
                  ? contractorsStats[0]?.map((item) => (
                      <Grid container key={item.contractor} alignItems="center">
                        <Grid item xs={5}>
                          <Typography
                            variant="subtitle"
                            style={{
                              color: 'rgba (246, 247, 249, 1)',
                              fontSize: '12px',
                            }}
                          >
                            {item.contractor ?? 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item xs={7} container>
                          <div
                            style={{
                              // width: item.present + "px" ?? 0,
                              width:
                                (item?.present *
                                  getMaxPresent(contractorsStats[0])) /
                                  100 +
                                '%',
                              backgroundColor: '#81B734',
                              height: '10px',
                            }}
                          ></div>
                          <small> {item.present ?? 'N/A'}</small>
                        </Grid>
                      </Grid>
                    ))
                  : contractorsStatsLoading
                  ? 'Loading...'
                  : 'No Data Found!'}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );

  const classifyProjectBudget = (project) => {
    if (project) {
      setCurrentProjectBudget(project);
      dispatch(getProjectBudget(project?.projectId));
    } else {
      setCurrentProjectBudget(projectClassificationList[0]);
      dispatch(getProjectBudget(projectClassificationList[0]?.projectId));
    }
  };

  function TooltipChart(title, value, tooltipTitle = '', size = 6) {
    return (
      <Grid
        item
        xs={size}
        container
        alignItems="center"
        // style={{ border: '2px solid red' }}
      >
        <Grid item>
          <Typography
            style={{
              fontSize: '10px',
              fontWeight: '400',
              color: 'rgba(25, 36, 52, 1)',
              marginRight: '4px',
            }}
          >
            {title}
          </Typography>
        </Grid>
        {tooltipTitle !== '' ? (
          <BootstrapTooltip
            classes={{ tooltip: classes.customWidth }}
            title={tooltipTitle}
            placement="right"
          >
            <Grid
              item
              style={{
                backgroundColor: 'rgba(129, 183, 52, 1)',
                borderRadius: '8px',
                color: '#fff',
                padding: '3px 8px',
                cursor: 'pointer',
                fontSize: '13px',
              }}
            >
              {value}
            </Grid>
          </BootstrapTooltip>
        ) : (
          <Grid
            item
            style={{
              backgroundColor: 'rgba(129, 183, 52, 1)',
              borderRadius: '8px',
              color: '#fff',
              padding: '3px 8px',
              cursor: 'pointer',
              fontSize: '13px',
            }}
          >
            {value}
          </Grid>
        )}
      </Grid>
    );
  }

  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  const getBarDataBudget = () => {
    return projectBudgetData?.graphData?.map((item) => ({
      x: truncateString(item.label, 6),
      skill: truncateString(item.label, 6),
      percentage: item.budgetedCost <= 0 ? 0 : item.budgetedCost,
    }));
  };
  const getBarDataCommittedCost = () => {
    return projectBudgetData?.graphData?.map((item) => ({
      x: truncateString(item.label, 6),
      skill: truncateString(item.label, 6),
      percentage: item?.committedCost <= 0 ? 0 : item?.committedCost,
    }));
  };

  const getBarDataActualCost = () => {
    return projectBudgetData?.graphData?.map((item) => ({
      x: truncateString(item.label, 6),
      skill: truncateString(item.label, 6),
      percentage: item.actualCost <= 0 ? 0 : item.actualCost,
    }));
  };

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const PieChart = () => {
    return (
      <Grid item xs={12} style={{ marginTop: '0.8rem' }} container>
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid item container xs={7} alignItems="center">
                  <Grid item>Labour Turnover</Grid>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider
                      variant="middle"
                      flexItem
                      orientation="vertical"
                      style={{
                        height: '20px',
                        width: '2px',
                      }}
                    />
                  </div>

                  <Grid item>
                    <Button
                      size="small"
                      className={classes.buttonPurpleOpacity}
                      style={{
                        padding: '10px 1.3rem',
                        borderRadius: '9px',
                        marginRight: '10px',
                      }}
                      // startIcon={
                      //   <img
                      //     src={DownloadButtonFieldNote}
                      //     alt="FieldNote Download Button"
                      //   />
                      // }
                    >
                      View All
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography style={{ fontSize: '12px' }}>
                        Filter By Month:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <DatePicker
                        showMonthYearPicker
                        // dateFormat="MMM, yyyy"
                        selected={labourTurnoverDate}
                        onChange={(update) => {
                          handleDateRangeLabourTurnover(update);
                        }}
                        isClearable={true}
                        className="border-datepicker"
                        placeholderText={moment().format('MMMM')}
                      />
                      {!startDate && (
                        <EventNoteIcon
                          style={{
                            position: 'absolute',
                            right: '5px',
                            top: '9px',
                            color: '#ADBAC3',
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={5} justify="flex-end">
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Average <br /> Turnover Rate
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {labourTurnoverMetrics?.hasOwnProperty(
                            'averageTurnOverRate'
                          ) ? (
                            labourTurnoverMetrics?.averageTurnOverRate ?? 'N/A'
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      // paddingRight: "10px",
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Workers <br /> Exited
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {labourTurnoverMetrics?.hasOwnProperty(
                            'workersExited'
                          ) ? (
                            labourTurnoverMetrics?.workersExited ?? 'N/A'
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',
                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item container xs={5} alignItems="center">
                    <Grid item xs={8} style={{ paddingRight: '20px' }}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectLabourTurnover}
                        onChange={classifyProjectLabourTurnover}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid
              container
              style={{ height: '450px' }}
              justify="space-between"
              // alignItems="center"
            >
              <Grid item xs={5}>
                <Typography variant="h6">Voluntary Exits</Typography>
                {/* <div style={{ height: "200px", border: "1px solid red" }}> */}
                {labourTurnoverMetrics?.voluntaryExits?.takingBreak === 0 &&
                labourTurnoverMetrics?.voluntaryExits?.emergency === 0 &&
                labourTurnoverMetrics?.voluntaryExits?.delayedPayment === 0 &&
                labourTurnoverMetrics?.voluntaryExits?.betterWages === 0 ? (
                  <Doughnut
                    height={200}
                    data={{
                      labels: ['No Data'],
                      datasets: [
                        {
                          label: '# of Votes',
                          data: [100],
                          backgroundColor: ['#808080'],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        // display: false, // Hide the legend
                      },
                    }}
                  />
                ) : (
                  <>
                    <Doughnut
                      height={200}
                      data={{
                        labels: [
                          'Taking a break',
                          'Emergency',
                          'Delayed Payment',
                          'Better Wages',
                        ],
                        datasets: [
                          {
                            label: '# of Votes',
                            data: [
                              labourTurnoverMetrics?.voluntaryExits
                                ?.takingBreak,
                              labourTurnoverMetrics?.voluntaryExits?.emergency,
                              labourTurnoverMetrics?.voluntaryExits
                                ?.delayedPayment,
                              labourTurnoverMetrics?.voluntaryExits
                                ?.betterWages,
                            ],
                            backgroundColor: [
                              '#6ba11e',
                              '#aa86f3',
                              'rgba(255, 206, 86, 0.5)',
                              'rgba(75, 192, 192, 0.5)',
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        legend: {
                          // display: false, // Hide the legend
                        },
                        plugins: {
                          datalabels: {
                            formatter: (value) => {
                              return value + '%';
                            },
                          },
                        },
                      }}
                    />
                    {labourTurnoverMetrics?.voluntaryExits?.takingBreak && (
                      <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: '10px' }}
                      >
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#6ba11e',
                          }}
                        >
                          Taking a break:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.takingBreak +
                              '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#aa86f3',
                          }}
                        >
                          Emergency:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.emergency +
                              '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: 'rgba(255, 206, 86, 0.5)',
                          }}
                        >
                          Delayed Payment:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations
                              ?.delayedPayment + '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: 'rgba(75, 192, 192, 0.5)',
                          }}
                        >
                          Better Wages:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.betterWages +
                              '%'}
                          </b>
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6">Terminations</Typography>
                {labourTurnoverMetrics?.terminations?.jobEnded === 0 &&
                labourTurnoverMetrics?.terminations?.noShow === 0 &&
                labourTurnoverMetrics?.terminations?.misconduct === 0 &&
                labourTurnoverMetrics?.terminations?.tardiness === 0 ? (
                  <Doughnut
                    height={200}
                    data={{
                      labels: ['No Data'],
                      datasets: [
                        {
                          label: '# of Votes',
                          data: [100],
                          backgroundColor: ['#808080'],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        // display: false, // Hide the legend
                      },
                    }}
                  />
                ) : (
                  <>
                    <Doughnut
                      height={200}
                      data={{
                        labels: [
                          'Job Ended',
                          'No Show',
                          'Misconduct',
                          'Tardiness',
                        ],
                        datasets: [
                          {
                            label: '# of Votes',
                            data: [
                              labourTurnoverMetrics?.terminations?.jobEnded,
                              labourTurnoverMetrics?.terminations?.noShow,
                              labourTurnoverMetrics?.terminations?.misconduct,
                              labourTurnoverMetrics?.terminations?.tardiness,
                            ],
                            backgroundColor: [
                              '#6ba11e',
                              '#aa86f3',
                              'rgba(255, 206, 86, 0.5)',
                              'rgba(75, 192, 192, 0.5)',
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        legend: {
                          display: true,
                          // you can adjust other legend options here if needed
                        },
                        plugins: {
                          datalabels: {
                            formatter: (value) => {
                              return value + '%';
                            },
                          },
                        },
                      }}
                    />

                    {labourTurnoverMetrics?.terminations?.jobEnded && (
                      <Grid
                        item
                        container
                        justify="center"
                        style={{ marginTop: '10px' }}
                      >
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#6ba11e',
                          }}
                        >
                          Job Ended:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.jobEnded +
                              '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#aa86f3',
                          }}
                        >
                          No Show:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.noShow + '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#ffe6aa',
                          }}
                        >
                          Misconduct:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.misconduct +
                              '%'}
                          </b>
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '11px',
                            paddingRight: '5px',
                            color: '#a5dfdf',
                          }}
                        >
                          Tardiness:{' '}
                          <b>
                            {labourTurnoverMetrics?.terminations?.tardiness +
                              '%'}
                          </b>
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <Page className={classes.root} title="Bettamint Dashboard">
      <Container maxWidth={false}>
        <Grid
          container
          style={
            {
              // padding: "1em",
            }
          }
        >
          <Grid container item xs={12} className={classes.cardPaddingRight}>
            {renderWorkersCard()}
            <Grid item container style={{ marginBottom: '1rem' }}>
              <BOQProgressGreenContainer
                projectClassificationList={projectClassificationList}
                currentProject={currentProject}
                showButtons={false}
                classifyProject={classifyProjectBOQGreenContainer}
                classifiedProject={classifiedProject}
                radius={'10px'}
              />
            </Grid>
            {renderCarts()}
            {BillingChart()}

            {ChartSecondCards()}

            {/* <Grid item xs={12} style={{ margin: "0.8rem 0 0 0" }}>
              {" "}
              <img
                src={chart}
                alt="donut chart"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid> */}
            <PieChart />

            {/* {FieldNoteComponent()} */}
            {NewFiledNoteComponent()}
          </Grid>
        </Grid>
        <Toaster />
      </Container>
    </Page>
  );

  function BillingChart() {
    return (
      <Grid item xs={12} style={{ marginTop: '0.8rem' }}>
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
            height: '450px',
          }}
          // className={classes.cardHeight}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 19,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Budgeted VS Committed Vs Actual Cost
                    </Typography>
                  </Grid>

                  <Grid xs={8} container item>
                    <Grid container item md={4} xs={12}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-around"
                        style={{
                          background: '#F9F9F9',
                          borderRadius: 10,
                          marginRight: '10px',
                          height: '60px',
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{ fontFamily: 'Roboto', fontSize: 12 }}
                          >
                            Budget <br /> Cost
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 25,
                              color: '#21D940',
                            }}
                          >
                            {projectBudgetData ? (
                              '₹' +
                              projectBudgetData?.budgetedCost?.toLocaleString()
                            ) : (
                              <CircularProgress
                                style={{
                                  color: '#21D940',

                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      md={4}
                      xs={12}
                      className={classes.marginTop}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        width: '100%',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 12 }}
                        >
                          Committed
                          <br />
                          Cost
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 25,
                            color: '#7E7E7E',
                          }}
                        >
                          {projectBudgetData ? (
                            '₹' +
                            projectBudgetData?.committedCost?.toLocaleString()
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#7E7E7E',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      item
                      md={4}
                      xs={12}
                      className={classes.marginTop}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        justify="space-around"
                        alignItems="center"
                        style={{
                          background: '#F9F9F9',
                          borderRadius: 10,
                          width: '100%',
                          marginLeft: '10px',
                          height: '60px',
                        }}
                      >
                        <Grid>
                          <Typography
                            style={{ fontFamily: 'Roboto', fontSize: 12 }}
                          >
                            Actual
                            <br />
                            Cost
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 25,
                              color: '#AF9CF6',
                            }}
                          >
                            {projectBudgetData ? (
                              '₹' +
                              projectBudgetData?.actualCost?.toLocaleString()
                            ) : (
                              <CircularProgress
                                style={{
                                  color: '#AF9CF6',

                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: '.6rem' }}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item md={8} xs={12}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProjectBudget}
                      onChange={classifyProjectBudget}
                    />
                  </Grid>
                  {/* <Grid
              item
              md={4}
              xs={12}
              className={classes.marginTop}
              container
              alignItems="center"
              justify="flex-end"
            >
              <Grid item style={{ paddingRight: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: "12px", fontWeight: 400 }}
                >
                  Search Filters:
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  width: "210px",
                  position: "relative",
                }}
              >
                <DatePicker
                  dateFormat="d MMM, yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    handleDateRange(update);
                  }}
                  isClearable={true}
                  className="border-datepicker"
                  placeholderText={
                    moment().format("DD MMM, YYYY") +
                    " - " +
                    moment().add(1, "days").format("DD MMM, YYYY")
                  }
                />
                {!startDate && (
                  <EventNoteIcon
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "7px",
                      color: "#ADBAC3",
                    }}
                  />
                )}
              </Grid>
            </Grid> */}
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container item xs={12}>
              {projectBudgetLoading ? (
                <div style={{ height: '340px', textAlign: 'center' }}>
                  Loading...
                </div>
              ) : (
                <Grid
                  style={{
                    height: '250px',
                  }}
                >
                  <VictoryChart
                    animate={{
                      duration: 1500,
                      onLoad: { duration: 1000 },
                    }}
                    // domain={{ y: [1, 100] }}
                    theme={VictoryTheme.grayscale}
                    width={1590}
                    // height={350}
                    padding={{ top: 10, bottom: 30, left: 80, right: 0 }}
                  >
                    {/* <VictoryLegend
                      x={0}
                      y={0}
                      orientation="horizontal"
                      gutter={20}
                      colorScale={["RGB(133, 186, 89)", "#9872EF"]}
                      data={[{ name: "Budget Cost " }, { name: "Actual Cost" }]}
                    /> */}
                    <VictoryGroup
                      offset={35}
                      colorScale={['RGB(133, 186, 89)', '#7E7E7E', '#9872EF']}
                    >
                      <VictoryBar
                        barWidth={20}
                        data={getBarDataBudget()}
                        x="x"
                        labels={({ datum }) =>
                          '₹' + datum.percentage?.toLocaleString()
                        }
                        // labelComponent={<VictoryLabel dy={50} />}
                        y="percentage"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                        alignment="start"
                        barRatio={0.8}
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) => {
                              return `${datum.x}: ${datum.percentage}`;
                            }}
                            style={{
                              fill: 'white', // Set the background color to white
                            }}
                            flyoutStyle={{
                              padding: 10, // Adjust the padding as needed

                              // padding: "5px 1rem",
                              // color: "red",
                              fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                              stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                              strokeWidth: 2, // Border width of the tooltip box
                              // You can further customize other flyout styles as needed
                            }}
                            textStyle={{
                              padding: 8, // Add padding to the text content
                              // You can also customize other text styles like font size, font family, etc. here
                            }}
                          />
                        }
                      />
                      <VictoryBar
                        barWidth={30}
                        data={getBarDataCommittedCost()}
                        x="x"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        labels={({ datum }) =>
                          '₹' + datum.percentage?.toLocaleString()
                        }
                        // labelComponent={<VictoryLabel dy={50} />}
                        y="percentage"
                        style={{ labels: { fill: '#7E7E7E' } }}
                        alignment="start"
                        barRatio={0.8}
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) => {
                              return `${datum.x}: ${datum.percentage}`;
                            }}
                            style={{
                              fill: 'white', // Set the background color to white
                            }}
                            flyoutStyle={{
                              padding: '10px', // Adjust the padding as needed
                              fill: '#7E7E7E', // Background color of the tooltip box
                              stroke: '#7E7E7E', // Border color of the tooltip box
                              strokeWidth: 2, // Border width of the tooltip box
                              // You can further customize other flyout styles as needed
                            }}
                            textStyle={{
                              padding: 8, // Add padding to the text content
                              // You can also customize other text styles like font size, font family, etc. here
                            }}
                          />
                        }
                      />
                      <VictoryBar
                        barWidth={20}
                        data={getBarDataActualCost()}
                        x="x"
                        cornerRadius={{ top: 5, bottom: 0 }}
                        labels={({ datum }) =>
                          '₹' + datum.percentage?.toLocaleString()
                        }
                        // labelComponent={<VictoryLabel dy={50} />}
                        y="percentage"
                        style={{ labels: { fill: '#9872EF' } }}
                        alignment="start"
                        barRatio={0.8}
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) => {
                              return `${datum.x}: ${datum.percentage}`;
                            }}
                            style={{
                              fill: 'white', // Set the background color to white
                            }}
                            flyoutStyle={{
                              padding: '10px', // Adjust the padding as needed
                              fill: '#9872EF', // Background color of the tooltip box
                              stroke: '#9872EF', // Border color of the tooltip box
                              strokeWidth: 2, // Border width of the tooltip box
                              // You can further customize other flyout styles as needed
                            }}
                            textStyle={{
                              padding: 8, // Add padding to the text content
                              // You can also customize other text styles like font size, font family, etc. here
                            }}
                          />
                        }
                      />
                    </VictoryGroup>

                    <VictoryAxis
                      dependentAxis
                      tickFormat={(tick) => `₹ ${tick.toLocaleString()}`}
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 16,
                          fontFamily: 'Roboto',
                        }, // Change label color
                      }}
                    />
                    <VictoryAxis
                      tickFormat={(tick) => tick}
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 18,
                          fontFamily: 'Roboto',
                        }, // Change label color
                      }}
                    />
                  </VictoryChart>

                  <Grid container style={{ marginTop: '0.5rem' }}>
                    {TooltipChart(
                      'Cost To Complete',
                      '₹' +
                        (
                          projectBudgetData?.budgetedCost -
                          projectBudgetData?.actualCost
                        ).toLocaleString(),
                      // "₹ Lakhs/month",
                      // "Lakhs/month",
                      '',
                      7
                    )}
                    <Grid
                      item
                      xs={5}
                      container
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        style={{
                          marginRight: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Budgeted
                        </Typography>
                        <div className="skilled-box"></div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          marginRight: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Committed
                        </Typography>
                        <div className="commited-box"></div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: 'rgba(178, 190, 199, 1)',
                            fontSize: '11px',
                          }}
                        >
                          Actual
                        </Typography>
                        <div className="supervisor-box"></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  // function FieldNoteComponent() {
  //   return (
  //     <Grid
  //       container
  //       xs={12}
  //       style={{
  //         // paddingRight: "1.5rem",
  //         marginTop: "1rem",
  //         zoom: "100%",
  //       }}
  //     >
  //       <Card
  //         elevation={6}
  //         style={{
  //           width: "100%",
  //           borderRadius: 10,
  //           marginTop: "1rem",
  //           minHeight: "45em",
  //         }}
  //       >
  //         <CardHeader
  //           title={
  //             <Grid container alignItems="center" justify="space-between">
  //               <Grid item>Field Notes</Grid>{" "}
  //               <Grid item container xs={3} justify="space-between">
  //                 {" "}
  //                 <div>
  //                   <Button
  //                     aria-controls="simple-menu"
  //                     aria-haspopup="true"
  //                     onClick={handleClick}
  //                     size="small"
  //                     className={classes.buttonPurpleOpacity}
  //                     style={{
  //                       padding: "10px 1.3rem",
  //                       borderRadius: "10px",
  //                       width: "123px",
  //                     }}
  //                     endIcon={<KeyboardArrowDownRoundedIcon />}
  //                   >
  //                     {filterName}
  //                   </Button>
  //                   <Menu
  //                     id="simple-menu"
  //                     anchorEl={anchorEl}
  //                     keepMounted
  //                     open={Boolean(anchorEl)}
  //                     onClose={handleClose}
  //                   >
  //                     <MenuItem onClick={() => handleCarouselFilter("")}>
  //                       <Grid container alignItems="center">
  //                         <BlockIcon />
  //                         <span style={{ marginLeft: "18px" }}> None</span>
  //                       </Grid>
  //                     </MenuItem>
  //                     <MenuItem
  //                       onClick={() =>
  //                         handleCarouselFilter("resolved", "MarkAsResolved")
  //                       }
  //                     >
  //                       <Grid container alignItems="center">
  //                         <CheckCircleOutlinedIcon />
  //                         <span style={{ marginLeft: "18px" }}>Resolved</span>
  //                       </Grid>
  //                     </MenuItem>
  //                     <MenuItem
  //                       onClick={() =>
  //                         handleCarouselFilter("required", "ReworkRequired")
  //                       }
  //                     >
  //                       <Grid container alignItems="center">
  //                         <RestoreIcon />

  //                         <span style={{ marginLeft: "18px" }}>Rework</span>
  //                       </Grid>
  //                     </MenuItem>
  //                     <MenuItem
  //                       onClick={() =>
  //                         handleCarouselFilter("pending", "DecisionPending")
  //                       }
  //                     >
  //                       <Grid container alignItems="center">
  //                         <TimerIcon />

  //                         <span style={{ marginLeft: "18px" }}>Pending</span>
  //                       </Grid>
  //                     </MenuItem>
  //                   </Menu>
  //                 </div>
  //                 <Link to="/app/field-notes/create">
  //                   <Button
  //                     size="small"
  //                     className={classes.buttonPurpleOpacity}
  //                     style={{
  //                       padding: "10px 1.3rem",
  //                       borderRadius: "10px",
  //                     }}
  //                     startIcon={
  //                       <AddIcon
  //                         fontSize="small"
  //                         style={{
  //                           color: "#986FEF",
  //                           textAlign: "center",
  //                         }}
  //                       />
  //                     }
  //                   >
  //                     Add New
  //                   </Button>
  //                 </Link>
  //               </Grid>
  //             </Grid>
  //           }
  //         />
  //         <CardContent>
  //           <Grid
  //             container
  //             justify="center"
  //             // md={12}
  //             style={{ position: "relative" }}
  //           >
  //             <Grid
  //               item
  //               style={{
  //                 position: "absolute",
  //                 top: "50%",
  //                 left: "0%",
  //                 // transform: "Translate(50%, 50%)",
  //                 zIndex: "100",
  //                 boxShadow: "0px 9px 16px 0px #9CB0C31A",
  //               }}
  //             >
  //               <Button
  //                 style={{ backgroundColor: "#fff" }}
  //                 onClick={() => {
  //                   if (currentIndex !== 0)
  //                     return setCurrentIndex(currentIndex - 1);
  //                   setCurrentIndex(7);
  //                 }}
  //               >
  //                 <ArrowBackIosIcon style={{ color: "#A179F2" }} />
  //               </Button>
  //             </Grid>
  //             <Grid
  //               item
  //               style={{
  //                 position: "absolute",
  //                 top: "50%",
  //                 right: "0%",
  //                 // transform: "Translate(50%, 50%)",
  //                 boxShadow: "0px 9px 16px 0px #9CB0C31A",
  //                 zIndex: "100",
  //               }}
  //             >
  //               <Button
  //                 style={{ backgroundColor: "#fff" }}
  //                 onClick={() => {
  //                   if (currentIndex !== 7) {
  //                     return setCurrentIndex(currentIndex + 1);
  //                   }

  //                   setCurrentIndex(0);
  //                 }}
  //               >
  //                 <ArrowForwardIosIcon style={{ color: "#A179F2" }} />
  //               </Button>
  //             </Grid>
  //             <Grid container xs={12}>
  //               <FilterProjectComponent
  //                 projectClassificationList={projectClassificationList}
  //                 src={
  //                   !classifiedProject[0]?.url
  //                     ? defaultProjectImage
  //                     : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
  //                 }
  //                 value={currentProjectCarousel}
  //                 onChange={classifyProjectCarousel}
  //               />
  //             </Grid>
  //             <Grid
  //               item
  //               md={6}
  //               xs={12}
  //               container
  //               justify="center"
  //               style={{ marginTop: "1rem" }}
  //             >
  //               {items === null || items === 0 ? (
  //                 <Grid
  //                   style={{ height: "250px" }}
  //                   container
  //                   alignItems="center"
  //                   justify="center"
  //                 >
  //                   <Typography align="center" style={{ marginTop: "3.5rem" }}>
  //                     No Field Note Found Against This Project.
  //                   </Typography>
  //                 </Grid>
  //               ) : (
  //                 <Carousel
  //                   centerMode
  //                   infiniteLoop={fieldNoteList?.length > 3 ? true : false}
  //                   // autoPlay
  //                   autoFocus
  //                   centerSlidePercentage={fieldNoteList?.length > 3 ? 15 : 25}
  //                   selectedItem={currentIndex}
  //                   emulateTouch
  //                   showArrows
  //                 >
  //                   {filteredFiledNote
  //                     ? filteredFiledNote?.length &&
  //                       [...emptyItems, ...filteredFiledNote, ...emptyItems]
  //                         .filter((item, index) => index <= 7 && item)
  //                         .map((item, index) => (
  //                           <div
  //                             key={index}
  //                             onClick={() => handleItemClick(index)}
  //                             style={{ cursor: "pointer" }}
  //                           >
  //                             {item ? (
  //                               <div
  //                                 style={{
  //                                   height: "650px",
  //                                   // margin: "0 10px",
  //                                 }}
  //                               >
  //                                 <FieldNoteCardComponent
  //                                   item={item}
  //                                   fieldNoteList={filteredFiledNote}
  //                                 />
  //                               </div>
  //                             ) : null}
  //                           </div>
  //                         ))
  //                     : fieldNoteList?.length &&
  //                       [...emptyItems, ...fieldNoteList, ...emptyItems]
  //                         .filter((item, index) => index <= 7 && item)
  //                         .map((item, index) => (
  //                           <div
  //                             key={index}
  //                             onClick={() => handleItemClick(index)}
  //                             style={{ cursor: "pointer" }}
  //                           >
  //                             {item ? (
  //                               <div
  //                                 style={{
  //                                   height: "650px",
  //                                   // margin: "0 10px",
  //                                 }}
  //                               >
  //                                 <FieldNoteCardComponent
  //                                   item={item}
  //                                   fieldNoteList={fieldNoteList}
  //                                 />
  //                               </div>
  //                             ) : null}
  //                           </div>
  //                         ))}
  //                 </Carousel>
  //               )}
  //             </Grid>
  //           </Grid>
  //         </CardContent>
  //       </Card>
  //     </Grid>
  //   );
  // }

  function NewFiledNoteComponent() {
    const CustomImageCard = ({ item }) => (
      <Card
        elevation={6}
        style={{
          width: '98%',
          borderRadius: 10,
          margin: '1rem',
          border: '1px solid rgba(0, 0, 0, 0.06)',

          // minHeight: "35em",
          // border: "2px solid green",
        }}
      >
        <CardContent>
          <Grid container justify="space-between">
            <Grid
              item
              style={{
                width: '53%',
                height: '387px',
              }}
            >
              <Grid
                item
                style={{
                  position: 'relative',
                  // maxWidth: "400px",
                }}
              >
                <img
                  src={item?.thumbnail}
                  alt={'FieldNote Visual'}
                  style={{
                    width: '98%',
                    maxHeight: '387px',

                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <Grid
                  container
                  justify="space-between"
                  style={{
                    maxWidth: '100px',
                    backgroundColor: 'rgba(99,99,99, 0.5)',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#fff',
                    height: '35px',
                    // paddingRight: "10px",
                  }}
                >
                  <Grid
                    style={{
                      width: '67%',
                      textAlign: 'right',
                      padding: '3px 0',
                      paddingRight: '5px',
                    }}
                    container
                    alignItems="center"
                  >
                    <p
                      style={{
                        fontSize: '10px',
                        fontWeight: 300,
                        width: '100%',
                      }}
                    >
                      STATUS
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        width: '100%',
                        // paddingTop: "3px",
                      }}
                    >
                      {item?.action === '' || !item?.action
                        ? 'N/A'
                        : item.action === 'MarkAsResolved'
                        ? 'Resolved'
                        : item.action === 'ReworkRequired'
                        ? 'Rework'
                        : item.action === 'DecisionPending' && 'Pending'}
                    </p>
                  </Grid>
                  <Grid
                    container
                    style={{
                      backgroundColor: '#f5f1fdff',
                      // height: "42px",
                      // width: "120px",
                      position: 'relative',
                      // border: "1px solid red",
                      borderRadius: '10px',
                      width: '30%',
                    }}
                  >
                    {item.action === 'MarkAsResolved' ? (
                      <CheckCircleOutlinedIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : item.action === 'ReworkRequired' ? (
                      <RestoreIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : item.action === 'DecisionPending' ? (
                      <TimerIcon
                        style={{
                          color: '#986FEF',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    ) : (
                      <ErrorOutlineOutlinedIcon
                        style={{
                          color: 'red',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          fontSize: '26px',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{
                    padding: '0 1rem',
                    position: 'absolute',
                    bottom: '20px',
                    zIndex: 100,
                  }}
                >
                  <Grid
                    item
                    container
                    style={{
                      color: '#e0e1e1ff',
                      marginTop: '1rem',
                      // backgroundColor: "rgba(99,99,99, 0.3)",
                      // borderRadius: "10px",
                      // padding: "5px 0px 5px 5px ",
                      // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                    alignItems="center"
                    // justify="flex-start"
                  >
                    <Grid item>
                      <RoomOutlinedIcon style={{ fontSize: '30px' }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        align="left"
                        style={{
                          fontSize: '10px',
                          textShadow: '2px 2px 4px #000000',
                        }}
                      >
                        LOCATION
                      </Typography>{' '}
                      <Typography
                        style={{
                          textShadow: '2px 2px 4px #000000',
                          fontSize: '14px',
                        }}
                      >
                        {item?.location ?? 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                paddingLeft: '10px',
                width: '47%',
              }}
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                    }}
                    align="left"
                  >
                    ID
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    {item?.fieldNoteId ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item>
                  <button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      width: '42px',
                      padding: '10px 0',
                      backgroundColor: 'rgba(161, 120, 242, 0.20)',
                      borderRadius: '9px',
                      cursor: 'pointer',
                      border: 'none',
                    }}
                  >
                    <img
                      src={DownloadButtonFieldNote}
                      alt="FieldNote Download Button"
                    />
                  </button>
                </Grid>
              </Grid>

              <Grid
                // container
                item
                style={{
                  borderRadius: '10px',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  padding: '10px 20px',
                  marginTop: '10px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="left"
                  style={{
                    color: 'rgba(126, 131, 134, 0.7)',
                    fontSize: '10px',
                    fontWeight: 500,
                  }}
                >
                  SCOPE OF WORK
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="left"
                  style={{
                    fontSize: '20px',
                    fontWeight: 500,
                  }}
                >
                  {item?.scopeOfWork?.name ?? 'N/A'}
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: '10px' }}>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    DESCRIPTION
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.description &&
                    item?.description?.split(' ')?.length > 15
                      ? item?.description?.split(' ')?.slice(0, 15).join(' ') +
                        '...'
                      : item?.description ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    PROJECT
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.project?.name ?? 'N/A'}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    DATE & TIME
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.dateTime
                      ? moment(item?.dateTime).format('h:mma dddd')
                      : 'N/A'}{' '}
                    |{' '}
                    {item?.dateTime
                      ? moment(item?.dateTime).format('D MMM, YYYY')
                      : 'N/A'}{' '}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '10px' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{
                      color: 'rgba(126, 131, 134, 0.7)',
                      fontSize: '10px',
                      fontWeight: 500,
                    }}
                  >
                    ASSIGNED TO
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    style={{
                      fontSize: '15px',
                      fontWeight: 400,
                    }}
                  >
                    {item?.contractor?.fullName ?? 'N/A'}
                  </Typography>
                </Grid>
                {item?.action != null && (
                  <Grid item style={{ marginTop: '10px' }}>
                    <Typography
                      variant="subtitle1"
                      align="left"
                      style={{
                        color: 'rgba(126, 131, 134, 0.7)',
                        fontSize: '10px',
                        fontWeight: 500,
                      }}
                    >
                      STATUS
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      align="left"
                      style={{
                        fontSize: '15px',
                        fontWeight: 400,
                      }}
                    >
                      {item?.action != null ? printStatus(item.action) : 'N/A'}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <div
                style={{
                  width: '100%',
                  border: '1px solid rgba(0, 0, 0, .1)',
                  margin: '10px 0',
                }}
              ></div>

              {item.action === null && (
                <Grid
                  item
                  container
                  justify="flex-end"
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      marginRight: '5px',
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      backgroundColor: 'rgba(173, 186, 195, 1)',
                      color: '#fff',
                    }}
                    // size="small"
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'DecisionPending'
                      )
                    }
                  >
                    PENDING
                  </Button>
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      marginRight: '5px',
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      color: '#fff',
                      backgroundColor: 'rgba(161, 121, 242, 1)',
                    }}
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'ReworkRequired'
                      )
                    }
                    // size="small"
                  >
                    REWORK
                  </Button>
                  <Button
                    className={classes.buttonPurpleOpacity}
                    style={{
                      borderRadius: '8px',
                      width: '110px',
                      fontSize: '12px',
                      color: '#fff',
                      backgroundColor: 'rgba(129, 183, 52, 1)',
                    }}
                    onClick={() =>
                      markTheFieldNoteFromServer(
                        item.fieldNoteId,
                        'MarkAsResolved'
                      )
                    }
                    // size="small"
                  >
                    RESOLVED
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

    return (
      <Grid
        container
        xs={12}
        style={{
          // paddingRight: "1.5rem",
          marginTop: '1rem',
          zoom: '100%',
        }}
      >
        <Card
          elevation={6}
          style={{
            width: '100%',
            borderRadius: 10,
            marginTop: '1rem',
            minHeight: '45em',
          }}
        >
          <CardHeader
            title={
              <Grid container alignItems="center" justify="space-between">
                <Grid item container xs={6} alignItems="center">
                  <Grid item>
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: '500',
                        fontFamily: 'Roboto',
                        // color: "#D9D9D9",
                      }}
                    >
                      Field Notes
                    </Typography>
                  </Grid>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider
                      variant="middle"
                      flexItem
                      orientation="vertical"
                      style={{
                        height: '20px',
                        width: '2px',
                      }}
                    />
                  </div>

                  <Grid item>
                    <Button
                      size="small"
                      className={classes.buttonPurpleOpacity}
                      style={{
                        padding: '10px 1.3rem',
                        borderRadius: '9px',
                        marginRight: '10px',
                      }}
                      onClick={handleFielNoteDownload}
                      startIcon={
                        <img
                          src={DownloadButtonFieldNote}
                          alt="FieldNote Download Button"
                        />
                      }
                    >
                      Download
                    </Button>
                    <Link to="/app/field-notes/create">
                      <Button
                        size="small"
                        className={classes.buttonPurpleOpacity}
                        style={{
                          padding: '10px 1.3rem',
                          borderRadius: '9px',
                        }}
                        startIcon={
                          <AddIcon
                            fontSize="large"
                            style={{
                              color: '#986FEF',
                              textAlign: 'center',
                            }}
                          />
                        }
                      >
                        Add New
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Resolved <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {fieldNoteMetrics?.resolved ?? (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Pending <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#adbac3',
                          }}
                        >
                          {fieldNoteMetrics?.pending ?? (
                            <CircularProgress
                              style={{
                                color: '#adbac3',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={4}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Rework <br />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#AA86F3',
                          }}
                        >
                          {fieldNoteMetrics?.rework ?? (
                            <CircularProgress
                              style={{
                                color: '#AA86F3',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid
              container
              justify="center"
              // md={12}
              style={{ position: 'relative', margin: '0 0 20px 0' }}
            >
              <Grid
                container
                xs={12}
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7} container>
                  <Grid item xs={6} style={{ paddingRight: '20px' }}>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProject[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                      }
                      value={currentProjectCarousel}
                      onChange={classifyProjectCarousel}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FilterByLabourContractorComponent
                      value={LabourContractor}
                      onChange={handleLabourContractor}
                      setInitialUser={(user) => setLabourContractor(user)}
                    />
                  </Grid>
                </Grid>
                <Grid item style={{ width: '202px' }}>
                  <DatePicker
                    dateFormat="d MMM, yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      handleDateRange(update);
                    }}
                    isClearable={true}
                    className="border-datepicker"
                    placeholderText={
                      moment().format('DD MMM, YYYY') +
                      ' - ' +
                      moment().add(1, 'days').format('DD MMM, YYYY')
                    }
                  />
                  {!startDate && (
                    <EventNoteIcon
                      style={{
                        position: 'absolute',
                        right: '5px',
                        top: '9px',
                        color: '#ADBAC3',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <ImageGallery
              thumbnailPosition="left"
              items={
                fieldNoteList?.length > 0
                  ? fieldNoteList?.map((item) => ({
                      ...item,
                      original: `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item?.imageUrl}`,
                      thumbnail: `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item?.imageUrl}`,
                    }))
                  : []
              }
              showFullscreenButton={false}
              showPlayButton={false}
              renderItem={(item) => <CustomImageCard item={item} />}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function ChartSecondCards() {
    return (
      <Grid item container style={{ marginTop: '.8rem' }}>
        <Grid item md={6} xs={12} className={classes.cardPaddingRight1}>
          <Card
            elevation={3}
            style={{
              borderRadius: 10,
              height: '500px',
            }}
          >
            <CardHeader
              title={
                <Grid container alignItems="center" justify="space-between">
                  <Grid item container justify="space-between">
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: '500',
                          fontFamily: 'Roboto',
                          // color: "#D9D9D9",
                        }}
                      >
                        Financial Progress
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        size="small"
                        fullWidth
                        className={classes.buttonPurpleOpacity}
                        // size="small"
                        onClick={() =>
                          navigate('../productivity', {
                            replace: true,
                            // state: { from: location },
                          })
                        }
                      >
                        View Detail
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: '.6rem' }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item md={7} xs={12}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectFinancial}
                        onChange={classifyProjectFinancial}
                      />
                    </Grid>
                    <Grid
                      item
                      md={5}
                      xs={12}
                      className={classes.marginTop}
                      container
                      alignItems="center"
                      justify="flex-end"
                    >
                      {/* <Grid item style={{ paddingRight: "10px" }}>
              <Typography
                variant="subtitle1"
                style={{ fontSize: "11px", fontWeight: 400 }}
              >
                Search Filters:
              </Typography>
            </Grid> */}
                      <Grid
                        item
                        style={{
                          width: '210px',
                          position: 'relative',
                        }}
                      >
                        <DatePicker
                          dateFormat="d MMM, yyyy"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            handleDateRange(update);
                          }}
                          isClearable={true}
                          className="border-datepicker"
                          placeholderText={
                            moment().format('DD MMM, YYYY') +
                            ' - ' +
                            moment().add(1, 'days').format('DD MMM, YYYY')
                          }
                        />
                        {!startDate && (
                          <EventNoteIcon
                            style={{
                              position: 'absolute',
                              right: '5px',
                              top: '9px',
                              color: '#ADBAC3',
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container direction="column" justify="space-between">
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: '250px',
                  }}
                >
                  <Bar
                    // width={800}
                    height={200}
                    options={options1}
                    data={data1}
                  />
                </Grid>
                <Grid container style={{ marginTop: '0.8rem' }}>
                  {/* {TooltipChart(
                    "Avg Production Rate",
                    "₹20 Lakhs/month",
                    "Lakhs/month",
                    12
                  )} */}
                </Grid>
                <div className="border-bottom-dotted"></div>

                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  style={{ marginTop: '1.5rem' }}
                >
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Required <br /> Production Rate
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {currentPresent?.length > 0 ? (
                            '₹' +
                            financialProgressMetrics?.requiredProductionRate ? (
                              financialProgressMetrics?.requiredProductionRate?.toLocaleString()
                            ) : (
                              'N/A'
                            )
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                      height: '60px',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Actual
                        <br />
                        Production Rate
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 30,
                          color: '#AF9CF6',
                        }}
                      >
                        {currentAbsent?.length > 0 ? (
                          '₹' +
                          financialProgressMetrics?.actualProductionRate ? (
                            financialProgressMetrics?.actualProductionRate?.toLocaleString()
                          ) : (
                            'N/A'
                          )
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#AF9CF6',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card
            elevation={6}
            style={{
              borderRadius: 10,
              height: '500px',
            }}
          >
            <CardHeader
              title={
                <Grid container alignItems="center" justify="space-between">
                  <Grid item container justify="space-between">
                    <Grid item xs={6}>
                      <Typography
                        style={{
                          fontSize: 20,
                          fontWeight: '500',
                          fontFamily: 'Roboto',
                          // color: "#D9D9D9",
                        }}
                      >
                        Labour Expense
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        size="small"
                        fullWidth
                        className={classes.buttonGreen}
                        // size="small"
                        onClick={() =>
                          navigate('../payments', {
                            replace: true,
                            // state: { from: location },
                          })
                        }
                      >
                        Pay Now
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: '.6rem' }}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item md={9} xs={12}>
                      <FilterProjectComponent
                        projectClassificationList={projectClassificationList}
                        src={
                          !classifiedProject[0]?.url
                            ? defaultProjectImage
                            : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                        }
                        value={currentProjectPayments}
                        onChange={classifyProjectPayments}
                      />
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.marginTop}>
                      {' '}
                      <DatePicker
                        selected={paymentsSelectedDate}
                        onChange={onChangeDatePayments}
                        // startDate={startDate}
                        // endDate={endDate}
                        // selectsRange
                        isClearable={true}
                        className="border-datepicker"
                        placeholderText="Date Filter"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container justify="center" alignItems="center">
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    height: '200px',
                  }}
                >
                  <VictoryChart
                    domainPadding={{ x: 15, y: 30 }}
                    theme={VictoryTheme.grayscale}
                    width={750}
                    // height={200}
                    // padding={{ top: 30, bottom: 60, left: 50, right: 30 }}
                    // padding={{ top: 30, bottom: 30, left: 60, right: 30 }}
                    padding={{ top: 15, bottom: 30, left: 10, right: 0 }}
                    domain={{
                      y: [
                        0,
                        paymentYAxisLimit && paymentYAxisLimit.length > 0
                          ? Math.max(...paymentYAxisLimit) === 0
                            ? 5000
                            : Math.max(...paymentYAxisLimit)
                          : 5000,
                      ],
                    }}
                  >
                    <VictoryAxis
                      dependentAxis
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 18,
                          fontFamily: 'Roboto',
                        }, // Change label color // Change label color
                      }}
                    />
                    <VictoryAxis
                      style={{
                        axis: { stroke: 'black' }, // Adjust x-axis color as needed
                        ticks: { stroke: 'black' },
                        tickLabels: {
                          fill: 'rgba(112, 112, 112, 1)',
                          fontSize: 18,
                          fontFamily: 'Roboto',
                        }, // Change label color // Change label color
                      }}
                    />
                    {/* <VictoryLegend
  x={0}
  y={0}
  orientation="horizontal"
  gutter={20}
  colorScale={["RGB(133, 186, 89)", "#9872EF"]}
  data={[{ name: "Present " }, { name: "Absent" }]}
/> */}

                    <VictoryGroup
                      offset={20}
                      colorScale={['RGB(133, 186, 89)', '#9872EF', , 'grey']}
                    >
                      <VictoryBar
                        samples={100}
                        minDomain={{ y: 100 }}
                        barWidth={20}
                        data={
                          payments?.length
                            ? payments.map((item) => ({
                                y: item.DueAmount,
                                x: item.Day,
                              }))
                            : []
                        }
                        x="x"
                        // labels={({ datum }) => datum.Absent}
                        labels={({ datum }) => '₹' + datum.y.toLocaleString()}
                        // labelComponent={<VictoryLabel dy={50} />}
                        y="y"
                        style={{ labels: { fill: '#9872EF' } }}
                        alignment="start"
                        barRatio={0.8}
                        labelComponent={
                          <VictoryTooltip
                            label={({ datum }) => `${datum.x}: ${datum.y}`}
                            style={{
                              fill: 'white', // Set the background color to white
                            }}
                            flyoutStyle={{
                              padding: 10, // Adjust the padding as needed

                              // padding: "5px 1rem",
                              // color: "red",
                              fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                              stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                              strokeWidth: 2, // Border width of the tooltip box
                              // You can further customize other flyout styles as needed
                            }}
                            textStyle={{
                              padding: 8, // Add padding to the text content
                              // You can also customize other text styles like font size, font family, etc. here
                            }}
                          />
                        }
                      />
                    </VictoryGroup>
                  </VictoryChart>
                </Grid>
                <Grid container style={{ marginTop: '0.8rem' }}>
                  {/* {TooltipChart(
                    "Turnover Rate",
                    "₹20 Lakhs/month",
                    "Lakhs/month",
                    12
                  )} */}
                </Grid>
                <div className="border-bottom-dotted"></div>
                <Grid
                  item
                  container
                  justify="flex-start"
                  style={{ margin: '0 0 10px 0' }}
                >
                  {TooltipChart(
                    'Turnover ',
                    workforceMetrics,
                    ''
                    // <small style={{ fontSize: "8px" }}>
                    //   <b>20</b> Carpenters <b>10</b> Masons
                    // </small>
                  )}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justify="space-between"
                  // style={{ marginTop: "1.5rem" }}
                >
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    justify="space-around"
                    style={{
                      width: '100%',
                      paddingRight: '10px',
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      justify="space-around"
                      style={{
                        background: '#F9F9F9',
                        borderRadius: 10,
                        height: '60px',
                      }}
                    >
                      <Grid>
                        <Typography
                          style={{ fontFamily: 'Roboto', fontSize: 13 }}
                        >
                          Average <br /> Daily Wage
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: 30,
                            color: '#21D940',
                          }}
                        >
                          {labourExpenseMetrics?.averageDailyWage >= 0 ? (
                            '₹' +
                            labourExpenseMetrics?.averageDailyWage?.toFixed(1)
                          ) : (
                            <CircularProgress
                              style={{
                                color: '#21D940',

                                width: '25px',
                                height: '25px',
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems="center"
                    item
                    md={6}
                    xs={12}
                    className={classes.marginTop}
                    justify="space-around"
                    style={{
                      background: '#F9F9F9',
                      borderRadius: 10,
                      width: '100%',
                      height: '60px',
                    }}
                  >
                    <Grid>
                      <Typography
                        style={{ fontFamily: 'Roboto', fontSize: 13 }}
                      >
                        Labour Cost
                        <br />
                        Per Month
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: 30,
                          color: '#AF9CF6',
                        }}
                      >
                        {labourExpenseMetrics?.totalExpenseForLast30Days >=
                        0 ? (
                          '₹' +
                          labourExpenseMetrics?.totalExpenseForLast30Days?.toFixed(
                            1
                          )
                        ) : (
                          <CircularProgress
                            style={{
                              color: '#AF9CF6',

                              width: '25px',
                              height: '25px',
                            }}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default Dashboard;
