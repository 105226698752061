import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import arrow from '../../assests/arrow.png';
import placeholder from '../../assests/group.png';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { useState } from 'react';
import TextInput from './components/TextInput';
import Label from './components/Label';
import SelectInput from './components/SelectInput';

const SetupBillingModal = (props) => {
  const { classes, open, handleClose } = props;
  const [stepCount, setStepCount] = useState(1);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNextStep = () => setStepCount((prev) => prev + 1);
  const handleBackStep = () => setStepCount((prev) => prev - 1);

  const renderCreateContractor = () => {
    const contractorOptions = [
      { label: 'Pawan K. Builders & developers', value: 1 },
    ];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Label title="Add Contractor" subTitle="Add Contractor details" />
        <Grid item container xs={12} alignItems="center">
          <Grid item sm={3} xs={12}>
            <Typography style={{ fontSize: 16, marginRight: 15 }}>
              Select Contractor
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={9} alignItems="center">
            <Grid item xs={3} className={classes.imageContainer}>
              <img
                height="23px"
                width="32px"
                src={placeholder}
                alt="placeholder"
                style={{ position: 'absolute', top: 11, left: 9 }}
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <SelectInput
                label="Contractor"
                variant="filled"
                options={contractorOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderCreateBOQ = () => {
    const boqOptions = [{ label: 'Field Work', value: 1 }];
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label title="Add BOQs" subTitle="Add or Tag BOQs" />
          <Grid item>
            <Button className={classes.buttonPurpleOpacity}>
              + Add New Boq
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <SelectInput
            label="Add / Tag BOQs"
            variant="filled"
            options={boqOptions}
          />
        </Grid>
      </Grid>
    );
  };

  const renderGSTNumContractor = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label
            title="GST number of Contractor"
            subTitle="Add GST number of Contractor"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <TextInput
            label="GST Number"
            variant="filled"
            handleChange={() => {}}
          />
        </Grid>
      </Grid>
    );
  };

  const renderBenificiaryDetails = () => {
    return (
      <Grid
        item
        container
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container>
          <Label title="Benificiary Details" subTitle="One Time Process" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Account Holder Name"
              variant="filled"
              handleChange={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Bank Name"
              variant="filled"
              handleChange={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Bank Account Number"
              variant="filled"
              handleChange={() => {}}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput label="IFSC" handleChange={() => {}} variant="filled" />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const minBillingLimit = () => {
    return (
      <Grid
        item
        container
        alignContent="flex-start"
        style={{ padding: '10px 20px' }}
      >
        <Grid item xs={12} container>
          <Label
            title="Set Minimum limit to allow LC to generate bill"
            subTitle="Setup Limits"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <TextInput
            variant="filled"
            handleChange={() => {}}
            label="Select Minimum Billing value"
            endAdornment={<InputAdornment position="end">₹</InputAdornment>}
          />
        </Grid>
      </Grid>
    );
  };

  const renderMobilizationAdvance = () => {
    return (
      <Grid
        item
        container
        alignContent="flex-start"
        style={{ padding: '10px 20px' }}
      >
        <Grid item xs={12} container>
          <Label title="Mobilization Advance" subTitle="One Time Process" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Mobilization Advance"
              handleChange={() => {}}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Advance Recovery"
              handleChange={() => {}}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Retention"
              handleChange={() => {}}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: '0px 5px 5px 5px' }}>
            <TextInput
              label="Defect Liability"
              handleChange={() => {}}
              endAdornment={
                <InputAdornment position="end">DAYS</InputAdornment>
              }
              variant="filled"
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDebitQualityPreference = () => {
    const preferenceOptions = [
      { label: 'Yes', value: 1 },
      { label: 'No', value: 9 },
    ];

    return (
      <Grid
        container
        item
        style={{ padding: '10px 20px' }}
        alignContent="flex-start"
      >
        <Grid item xs={12} container justify="space-between">
          <Label
            title="Allow for auto debit of quality related issues"
            subTitle="Auto Debit of Quality"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <SelectInput
            variant="filled"
            label="Set Preferences"
            options={preferenceOptions}
          />
        </Grid>
      </Grid>
    );
  };

  const renderIcon = (tocId = null) => {
    return tocId ? (
      <IconButton>
        <DeleteIcon />
      </IconButton>
    ) : (
      <IconButton>
        <AddIcon
          style={{
            fill: 'white',
            width: '15px',
            height: '15px',
            borderRadius: '5px',
            background: '#1069E3',
          }}
        />
      </IconButton>
    );
  };

  const renderAddTermsAndConditions = () => {
    const tocOptions = [
      { label: 'Terms', value: 1 },
      { label: 'Conditions', value: 2 },
    ];
    const maxFieldWidth = isSmallScreen ? '80%' : '90%';
    return (
      <Grid
        item
        container
        alignContent="flex-start"
        style={{ padding: '10px 20px' }}
      >
        <Grid item xs={12} container justify="space-between">
          <Label title="Terms & Conditions" subTitle="Add Terms & Conditions" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ margin: '10px 0px' }}
        >
          <Grid item style={{ width: '90%', maxWidth: maxFieldWidth }}>
            <SelectInput
              variant="filled"
              options={tocOptions}
              label="Set Terms & Conditions"
            />
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: 50, height: 50, paddingLeft: 5 }}
          >
            <Paper className={classes.tocAction}>{renderIcon()}</Paper>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item style={{ width: '90%', maxWidth: maxFieldWidth }}>
            <SelectInput
              variant="filled"
              options={tocOptions}
              label="Set Terms & Conditions"
            />
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: 50, height: 50, paddingLeft: 5 }}
          >
            <Paper className={classes.tocAction}>{renderIcon(2)}</Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const renderStepOne = () => {
    const stepVisibility = stepCount === 1 ? 'visible' : 'hidden';
    const stepHeight = stepCount === 1 ? '100%' : '0';

    return (
      <Grid
        item
        container
        style={{
          height: '100%',
          overflow: 'auto',
          visibility: stepVisibility,
          maxHeight: stepHeight,
        }}
      >
        <Divider style={{ width: '100%' }} />
        {renderCreateContractor()}
        <Divider style={{ width: '100%' }} />
        {renderCreateBOQ()}
        <Divider style={{ width: '100%' }} />
        {renderGSTNumContractor()}
        <Divider style={{ width: '100%' }} />
        {renderBenificiaryDetails()}
        <Divider style={{ width: '100%' }} />
      </Grid>
    );
  };
  const renderStepTwo = () => {
    const stepVisibility = stepCount === 2 ? 'visible' : 'hidden';
    const stepHeight = stepCount === 2 ? '100%' : '0';
    return (
      <Grid
        item
        container
        style={{
          height: '100%',
          overflow: 'auto',
          visibility: stepVisibility,
          maxHeight: stepHeight,
        }}
      >
        <Divider style={{ width: '100%' }} />
        {minBillingLimit()}
        <Divider style={{ width: '100%' }} />
        {renderMobilizationAdvance()}
        <Divider style={{ width: '100%' }} />
        {renderDebitQualityPreference()}
        <Divider style={{ width: '100%' }} />
        {renderAddTermsAndConditions()}
        <Divider style={{ width: '100%' }} />
      </Grid>
    );
  };

  const backButton = (
    <Button
      className={classes.buttonPurple}
      onClick={handleBackStep}
      style={{ marginRight: 5 }}
    >
      Back
    </Button>
  );

  const submitButton = (
    <Button
      className={classes.buttonPurple}
      endIcon={<img src={arrow} alt="submit" />}
      onClick={handleClose}
    >
      Submit Setup
    </Button>
  );

  const nextButton = (
    <Button
      className={classes.buttonPurple}
      endIcon={<img src={arrow} alt="submit" />}
      onClick={handleNextStep}
    >
      Next
    </Button>
  );

  const renderActionButton = () => {
    if (stepCount > 1)
      return (
        <Grid>
          {backButton}
          {submitButton}
        </Grid>
      );
    return nextButton;
  };

  const renderTitle = () => {
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography style={{ fontSize: 26 }}>Setup Billing</Typography>
            <Typography style={{ fontSize: 10, opacity: 0.6 }}>
              One Time Process
            </Typography>
          </Grid>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClose}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <DialogActions>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          <Typography style={{ color: '#81B734' }}>
            {`STEP - ${stepCount}/2`}
          </Typography>
          {renderActionButton()}
        </Grid>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      className={classes.modal}
      PaperProps={{ style: { width: 700, height: '90%' } }}
    >
      {renderTitle()}
      {renderStepOne()}
      {renderStepTwo()}
      {renderFooter()}
    </Dialog>
  );
};

export default SetupBillingModal;
