// Entry point app
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import { auth } from './routes';
import UserContext from './context/UserContext';
import 'react-datepicker/dist/react-datepicker.css';
import Geocode from 'react-geocode';

import './App.css';
import DashboardLayout from './layouts/DashboardLayout';
import DashboardView from './views/dashboard/index';
import NotFoundView from './views/errors/NotFoundView';
import Projects from './views/Project';
import Jobs from './views/Jobs';
import Attendance from './views/Attendance';
import WorkerProfile from './views/WorkerProfile';
import Payments from './views/Payments';
import Users from './views/Users';
import Communication from './views/Communication';
import Reports from './views/Reports';
import UserDetail from './views/Users/userDetail';
import ProjectDetails from './views/Project/projectDetails';
import ProjectEdit from './views/Project/createProjectView';
import AttendanceDetail from './views/Attendance/attendanceDetail';
import WorkerDetail from './views/WorkerProfile/workerDetail';
import UserProfile from './views/Lead/userProfile';
import CreateJob from './views/Jobs/createJob';
import JobDetails from './views/Jobs/jobDetails';
import CreateUser from './views/Users/createUser';
import LeadsList from './views/Lead/index';
import CreateWorker from './views/WorkerProfile/createWorker';
import Features from './views/features/index';
import Roles from './views/roles/index';
import AddRoles from './views/roles/add-roles';
import EditWorkerDetails from './views/WorkerProfile/editWorkerDetails';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import Billing from './views/Billing';
import CustomersIndex from './views/Customers';
import FieldNotes from './views/FieldNotes';
import Rewards from './views/Rewards';
import Productivity from './views/Productivity';
import CreateFieldNote from './views/FieldNotes/createFieldNote';
// import ProductivityListView from './views/Productivity/listView';
import EditFieldNote from './views/FieldNotes/editFieldNote';
import Subscription from './views/subscription/index';

//! GOOGLE MAP
Geocode.setApiKey('AIzaSyAp1MrpbYeGqv7hwCFDbPOd7rlrxTazAFQ');
Geocode.enableDebug();
Geocode.setLanguage('en');
export const getLoggedInUserData = () =>
  JSON.parse(localStorage.getItem('userDetails'));

export const userTypes = [
  'SuperAdmin',
  'Developer',
  'Contractor',
  'Supervisor',
  'ProjectManager',
  'SiteAdmin',
  'JobCreator',
  'OnboardingOfficer',
  'PayrollOfficer',
  'Employer',
];
export function breakString(inputString) {
  if (inputString) {
    let brokenString = inputString?.replace(/([a-z])([A-Z])/g, '$1 $2');
    return brokenString;
  }
}
export function getAbbreviation(inputString) {
  // Capitalize the first three letters of the input string
  const abbreviation = inputString?.substring(0, 3).toUpperCase();
  return abbreviation;
}

const App = () => {
  const authRoutes = useRoutes(auth);
  // Context
  const [userData, setUserData] = useState(getLoggedInUserData());
  const allRoutes = [
    {
      path: '/app/dashboard',
      route: { index: true, path: 'dashboard', element: <DashboardView /> },
    },
    {
      path: '/app/profile',
      route: { path: 'profile', element: <UserProfile /> },
    },
    { path: '/app/leads', route: { path: 'leads', element: <LeadsList /> } },

    { path: '/app/project', route: { path: 'project', element: <Projects /> } },
    {
      path: '/app/project/details',
      route: { path: 'project/details', element: <ProjectDetails /> },
    },
    {
      path: '/app/project/edit',
      route: { path: 'project/edit', element: <ProjectEdit /> },
    },
    {
      path: '/app/project/create',
      route: { path: 'project/create', element: <ProjectEdit /> },
    },

    { path: '/app/jobs', route: { path: 'jobs', element: <Jobs /> } },
    {
      path: '/app/jobs/create',
      route: { path: 'jobs/create', element: <CreateJob /> },
    },

    {
      path: '/app/jobs/detail',
      route: { path: 'jobs/detail', element: <JobDetails /> },
    },
    {
      path: '/app/jobs/create',
      route: { path: 'jobs/edit', element: <CreateJob /> },
    },

    {
      path: '/app/attendance',
      route: { path: 'attendance', element: <Attendance /> },
    },
    {
      path: '/app/attendance/detail',
      route: { path: 'attendance/detail', element: <AttendanceDetail /> },
    },

    {
      path: '/app/workerprofile',
      route: { path: 'workerprofile', element: <WorkerProfile /> },
    },
    {
      path: '/app/workerprofile/detail',
      route: { path: 'workerprofile/detail', element: <WorkerDetail /> },
    },
    {
      path: '/app/workerprofile/create-worker',
      route: { path: 'workerprofile/create-worker', element: <CreateWorker /> },
    },
    {
      path: '/app/workerprofile/edit',
      route: {
        path: 'workerprofile/edit',
        element: <EditWorkerDetails />,
      },
    },

    {
      path: '/app/payments',
      route: { path: 'payments', element: <Payments /> },
    },
    { path: '/app/users', route: { path: 'users', element: <Users /> } },
    {
      path: '/app/users/detail',
      route: { path: 'users/detail', element: <UserDetail /> },
    },
    {
      path: '/app/users/create',
      route: { path: 'users/create', element: <CreateUser /> },
    },
    {
      path: '/app/users/edit',
      route: { path: 'users/edit', element: <CreateUser /> },
    },

    {
      path: '/app/communication',
      route: { path: 'communication', element: <Communication /> },
    },
    { path: '/app/reports', route: { path: 'reports', element: <Reports /> } },

    {
      path: '/app/features',
      route: { path: 'features', element: <Features /> },
    },

    { path: '/app/roles', route: { path: 'roles', element: <Roles /> } },
    {
      path: '/app/roles/add',
      route: { path: 'roles/add', element: <AddRoles /> },
    },
    {
      path: '/app/billing',
      route: { path: 'billing', element: <Billing /> },
    },
    {
      path: '/app/customers',
      route: { path: 'customers', element: <CustomersIndex /> },
    },
    {
      path: '/app/field-notes',
      route: { path: 'field-notes', element: <FieldNotes /> },
    },
    {
      path: '/app/field-notes/create',
      route: { path: 'field-notes/create', element: <CreateFieldNote /> },
    },
    {
      path: '/app/field-notes/edit',
      route: { path: 'field-notes/edit', element: <EditFieldNote /> },
    },
    {
      path: '/app/rewards',
      route: { path: 'rewards', element: <Rewards /> },
    },
    {
      path: '/app/productivity',
      route: {
        path: 'productivity',
        // element:
        //   userData?.user?.leadTypeId === "Contractor" ||
        //   userData?.role?.name === "QualityControl" ? (
        //     <ProductivityListView />
        //   ) : (
        //     <Productivity />
        //   ),
        element: <Productivity />,
      },
    },
    {
      path: '/app/subscription',
      route: { path: 'subscription', element: <Subscription /> },
    },
  ];

  const accessPath = [];
  for (let existingRoute of allRoutes) {
    accessPath.push(existingRoute.route);
  }

  const test = [
    {
      path: '/app',
      element: <DashboardLayout />,
      children: [...accessPath],
    },
    {
      path: '/',
      element: <Navigate to="/app/dashboard" />,
    },
    {
      path: '*',
      element: <NotFoundView />,
    },
  ];

  // const publicRoutes = [
  // 	{
  // 		path: '/test',
  // 		element: <ViewLayout />,
  // 		children: [ {
  // 			index: true, path: 'hello', element: <MapFullView />,
  // 		}, ],
  // 	},
  // 	// { path: '*', element: <Navigate to='login' /> },

  // ];

  const access = useRoutes(test);

  // const publicR = useRoutes( publicRoutes )
  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <UserContext.Provider value={[userData, setUserData]}>
          {!userData ? authRoutes : access}
        </UserContext.Provider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
