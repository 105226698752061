//! DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

//! Components
import Page from 'src/components/Page';
import RenderProjectList from './projectListView';
import { indexStyles } from './theme.project';

//! Redux
import {
  getGCList,
  getProjects,
  getProjectsMapping,
  projectReducer,
  selectProject,
  submitProjectId,
} from '../../redux/projects';
import { useDispatch, useSelector } from 'react-redux';
import { contractorReducer } from 'src/redux/contractors';
import { getUsers, usersReducer } from 'src/redux/users';
import { Autocomplete } from '@material-ui/lab';
import { useToasts } from 'react-toast-notifications';
import { getLoggedInUserData } from 'src/App';
import { countReducer, getProjectMetrics } from 'src/redux/count';
import activeWorkersImage from '../../assests/active-workers.png';
import activeProjectsImage from '../../assests/active projects.png';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import useAccessPerView from 'src/common/Access';

function Projects() {
  const classes = indexStyles();
  const [setSelectedProject] = useState(null);
  const [classifiedProject, setClassifiedProject] = useState('');
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedGC, setSelectedGC] = useState(null);

  const { isEditMode } = useAccessPerView('PROJECT_INFORMATION');

  console.log(isEditMode);

  //! INTANCES
  let dispatch = useDispatch();
  let { addToast } = useToasts();
  let location = useLocation();

  //! SELECTORS
  const { loading, projectsList, mappingProjectList, GCList, GCLoading } =
    useSelector(projectReducer);
  const { contractorsList } = useSelector(contractorReducer);
  const { usersList } = useSelector(usersReducer);
  const { projectMetrics } = useSelector(countReducer);

  //! LIFE CYCLE
  useEffect(() => {
    if (!projectsList) {
      dispatch(getProjects());
      dispatch(getProjectMetrics());
    }
    if (!usersList) {
      dispatch(getUsers());
    }
    if (mappingProjectList.length === 0) {
      dispatch(getProjectsMapping());
    }
  }, [projectsList?.length]);

  useEffect(() => {
    if (location.pathname === '/app/project') {
      dispatch(selectProject(null));
    }
  }, []);

  //! METHODS
  const EmptyListView = () => (
    <Grid
      container
      item
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: '80vh' }}
    >
      <Grid>
        <img
          src="/static/images/bettamintImages/project.png"
          alt="project"
          style={{
            width: '80px',
            height: '90px',
            textAlign: 'center',
          }}
        />
      </Grid>
      <Grid>
        <Typography style={{ color: '#ADBAC3', fontSize: 12 }}>
          You Dont Have a Project yet!
        </Typography>
      </Grid>
      <Grid>
        {isEditMode && (
          <Link to="create">
            <Button
              style={{ color: '#81B734', fontSize: 12 }}
              // onClick={ () => setModals( { ...modals, createProject: true } ) }
            >
              + Create New Project
            </Button>
          </Link>
        )}
      </Grid>
    </Grid>
  );
  const classifyProject = (project) => {
    if (project) {
      setClassifiedProject(project);
    } else {
      setClassifiedProject('');
    }
    setSelectedAccountType(null);
  };
  const submitProject = async () => {
    if (selectedAccountType !== 'Developer' && selectedGC === null) {
      return addToast('Please select General Contractor', {
        appreance: 'error',
        autoDismiss: true,
      });
    }

    let resp = await dispatch(
      submitProjectId(
        classifiedProject?.projectId,
        selectedAccountType === 'General Contractor'
          ? 'Contractor'
          : selectedAccountType,
        selectedGC?.userId,
      ),
    );
    if (resp.status === 200) {
      addToast('Project Associated Successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });
      dispatch(getProjects());
    } else {
      addToast(resp?.data?.error, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleAccountType = async (value) => {
    setSelectedAccountType(value);

    if (value === 'General Contractor') {
      if (classifiedProject === '') {
        return addToast('Please select project', {
          appreance: 'error',
          autoDismiss: true,
        });
      }
      dispatch(getGCList(classifiedProject?.projectId));
    }
  };
  const handleGC = async (value) => {
    setSelectedGC(value);
  };
  const projectCardData = [
    {
      id: 3,
      name: 'Total Projects',
      count: projectMetrics?.totalProjects,
      // img: "/static/images/bettamintImages/vector2.png",
      img: '/static/images/bettamintImages/dashboard1.png',

      // percent: "+12%",
      // link: `/${layout}/project`,
    },
    {
      id: 2,
      name: 'Total Contractors',
      count: projectMetrics?.totalContractor,
      img: activeWorkersImage,
      percent: '-5%',
      // link: `/${layout}/users`,
    },
    {
      id: 4,
      name: 'Active Workers',
      count: projectMetrics?.activeWorkers,
      // img: '/static/images/bettamintImages/vector2.png',
      img: activeProjectsImage,

      percent: '+10%',
    },
    {
      id: 1,
      name: 'Prduction Rate / Month',
      count: projectMetrics?.productionRatePerMonth,
      // img: '/static/images/bettamintImages/dashboard1.png',
      img: '/static/images/bettamintImages/vector2.png',
    },
  ];
  return (
    <Page className={classes.root} id="zoom-level" title="Project">
      <Container maxWidth={false}>
        <Grid
          container
          style={{
            background: 'white',
            // padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid
            style={{
              padding: '0 16px',
            }}
            container
            item
            xs={12}
            // style={{ border: '2px solid red' }}
            justify="space-between"
            alignItems="center"
            className={classes.cardPaddingRight}
          >
            <Grid
              container
              item
              xs={12}
              md={6}
              className={classes.cardPaddingRight1}
              alignItems="center"
              justify="space-between"
              style={{ margin: '15px 0 0 0' }}
            >
              {projectCardData.map(
                (val, index) =>
                  index <= 1 && (
                    <Grid
                      container
                      item
                      md={6}
                      xs={12}
                      style={{
                        marginBottom: '1em',
                      }}
                      key={index + 1}
                      // justify='center'
                      alignItems="center"
                      className={`${classes.padRight}`}
                    >
                      <Card
                        elevation={6}
                        style={{
                          borderRadius: 10,
                          width: '100%',
                          cursor: 'pointer',
                          height: '70px',
                        }}
                        // onClick={() =>
                        //   val?.link
                        //     ? navigate(val.link, {
                        //         replace: true,
                        //         // state: { from: location },
                        //       })
                        //     : null
                        // }
                      >
                        <CardContent style={{ padding: '10px' }}>
                          <Grid
                            container
                            justify="space-between"
                            // alignItems="center"
                            item
                            xs={12}
                          >
                            <Grid item>
                              <Grid item xs>
                                <Typography
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 12,
                                    color: '#B2BEC7',
                                  }}
                                >
                                  {val.name}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                alignItems="center"
                                direction="row"
                                justify="space-between"
                                item
                                xs={12}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 20,
                                      fontWeight: '400',
                                    }}
                                  >
                                    {loading ? (
                                      <CircularProgress
                                        style={{
                                          color: '#81B734',
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    ) : (
                                      val.count
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{
                                  background: '#A179F2',
                                  height: 50,
                                  width: 50,
                                  borderRadius: 10,
                                }}
                              >
                                {val.name === 'Total Contractors' ||
                                val.name === 'Worker Present' ? (
                                  <PeopleOutlineIcon
                                    style={{ color: '#fff' }}
                                    fontSize="large"
                                  />
                                ) : (
                                  <img
                                    src={val.img}
                                    alt="logo1"
                                    style={{ width: '30px' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ),
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              // className={classes.cardPaddingRight}
              alignItems="center"
              justify="space-between"
              style={{ margin: '15px 0 0 0' }}
            >
              {projectCardData.map(
                (val, index) =>
                  index > 1 && (
                    <Grid
                      container
                      item
                      md={6}
                      xs={12}
                      style={{
                        marginBottom: '1em',
                      }}
                      // className={index === 0 && classes.cardPaddingRight}
                      key={index + 1}
                      // justify='center'
                      alignItems="center"
                      // justifyContent="space-between"
                      className={`${index === 2 ? classes.padRight : null}`}
                    >
                      <Card
                        elevation={6}
                        style={{
                          borderRadius: 10,
                          width: '100%',
                          cursor: 'pointer',
                          height: '70px',
                        }}
                        // onClick={() =>
                        //   navigate(val.link, {
                        //     replace: true,
                        //     // state: { from: location },
                        //   })
                        // }
                      >
                        <CardContent style={{ padding: '10px' }}>
                          <Grid
                            container
                            justify="space-between"
                            // alignItems="center"
                            item
                            xs={12}
                          >
                            <Grid item>
                              <Grid item xs>
                                <Typography
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 12,
                                    color: '#B2BEC7',
                                  }}
                                >
                                  {val.name}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                alignItems="center"
                                direction="row"
                                justify="space-between"
                                item
                                xs={12}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 20,
                                      fontWeight: '400',
                                    }}
                                  >
                                    {loading ? (
                                      <CircularProgress
                                        style={{
                                          color: '#81B734',
                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    ) : (
                                      val.count
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{
                                  background: '#A179F2',
                                  height: 50,
                                  width: 50,
                                  borderRadius: 10,
                                }}
                              >
                                {val.name === 'Prduction Rate / Month' ? (
                                  <TrendingUpIcon
                                    style={{ color: '#fff' }}
                                    fontSize="large"
                                  />
                                ) : (
                                  <PeopleOutlineIcon
                                    style={{ color: '#fff' }}
                                    fontSize="large"
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
          {/* {userData?.user?.leadTypeId === "LabourContractor" && ( */}
          <Grid
            container
            justify="flex-start"
            xs={12}
            style={{
              padding: '0 1rem',
              marginTop: '10px',
            }}
          >
            <Grid
              item
              xs={12}
              md={9}
              container
              justify="space-between"
              alignItems="center"
              style={{
                border: '2px solid #ddd',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <Grid item xs={5}>
                <Autocomplete
                  autoSelect
                  fullWidth
                  size="small"
                  options={mappingProjectList}
                  getOptionLabel={(option) => option.name}
                  value={classifiedProject}
                  onChange={(event, newValue) => {
                    classifyProject(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Please type project name here to link "
                      className={classes.projectInput}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={3}>
                <Autocomplete
                  autoSelect
                  fullWidth
                  size="small"
                  options={
                    getLoggedInUserData()?.user?.userTypeId === 'SuperAdmin' ||
                    getLoggedInUserData()?.user?.leadTypeId ===
                      'LabourContractor'
                      ? ['Developer', 'General Contractor']
                      : getLoggedInUserData()?.user?.leadTypeId === 'Contractor'
                        ? ['Developer']
                        : getLoggedInUserData()?.user?.leadTypeId ===
                            'Developer'
                          ? ['General Contractor']
                          : []
                  }
                  // getOptionLabel={(option) => option.name}
                  value={selectedAccountType}
                  onChange={(event, newValue) => {
                    handleAccountType(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Account Type"
                      className={classes.projectInput}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  loading={GCLoading}
                  autoSelect
                  fullWidth
                  size="small"
                  options={GCList?.length > 0 ? GCList : []}
                  getOptionLabel={(option) => option.fullName}
                  onChange={(event, newValue) => {
                    handleGC(newValue);
                  }}
                  value={selectedGC}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={selectedAccountType ?? ''}
                      className={classes.projectInput}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="outlined"
                  style={{ textTransform: 'capitalize' }}
                  onClick={submitProject}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* )} */}
          {projectsList?.length === 0 ? (
            <EmptyListView style />
          ) : (
            <Grid container>
              <RenderProjectList
                projectList={projectsList}
                loading={loading}
                contractorsList={contractorsList}
                usersList={usersList}
                setSelectedProject={setSelectedProject}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

export default Projects;
