import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Divider, Grid } from '@material-ui/core';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  font: {
    fontFamily: 'Roboto',
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={`${classes.root} ${classes.font}`}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.font}>
          {row.name}
        </TableCell>
        <TableCell align="right" className={classes.font}>
          {row.calories}
        </TableCell>
        <TableCell align="right" className={classes.font}>
          {row.fat}
        </TableCell>
        <TableCell align="right" className={classes.font}>
          {row.carbs}
        </TableCell>
        <TableCell align="right" className={classes.font}>
          {row.protein}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="h1">
                Features
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead className={classes.font}>
                  <TableRow>
                    <TableCell className={classes.font}>Name</TableCell>
                    <TableCell className={classes.font}>Route</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.font}>
                  {row?.roleFeatureSets?.map((item) => (
                    <TableRow
                      key={item.roleFeatureSetId}
                      className={classes.font}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.font}
                      >
                        {item.featureSet.name}
                      </TableCell>
                      <TableCell className={classes.font}>
                        {item.featureSet.route}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// Row.propTypes = {
// 	row: PropTypes.shape({
// 		calories: PropTypes.number.isRequired,
// 		carbs: PropTypes.number.isRequired,
// 		fat: PropTypes.number.isRequired,
// 		history: PropTypes.arrayOf(
// 			PropTypes.shape({
// 				amount: PropTypes.number.isRequired,
// 				customerId: PropTypes.string.isRequired,
// 				date: PropTypes.string.isRequired,
// 			})
// 		).isRequired,
// 		name: PropTypes.string.isRequired,
// 		price: PropTypes.number.isRequired,
// 		protein: PropTypes.number.isRequired,
// 	}).isRequired,
// };

export default function CollapsibleTable({ rolesList }) {
  const classes = useRowStyles();

  return (
    <TableContainer
      component={Paper}
      style={{ padding: '1rem', maxHeight: '85vh' }}
    >
      <Grid item style={{}}>
        {' '}
        View existing roles & their features .
      </Grid>
      <Divider style={{ margin: '1rem 0' }} />

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.font}>
              #
            </TableCell>
            <TableCell component="th" scope="row" className={classes.font}>
              Role Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesList.map((row) => (
            <Row key={row.roleId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
