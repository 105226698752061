import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const Label = (props) => {
  const { title, subTitle } = props;
  return (
    <Grid item>
      <Typography style={{ fontSize: 18 }}>{title}</Typography>
      <Typography style={{ fontSize: 10, opacity: 0.6 }}>{subTitle}</Typography>
    </Grid>
  );
};
export default Label;
