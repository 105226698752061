import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import BOQAccordian from './BOQAccordian';

const SOWAccordian = (props) => {
  const { index, sowItem, scopeList, expandedSOW, handleSOWExpanded } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const accordionRef = useRef(null);

  useEffect(() => {
    if (index !== expandedSOW) {
      setIsExpanded(false);
    }
  }, [expandedSOW]); //eslint-disable-line

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        accordionRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [isExpanded]);

  const handleChange = () => {
    handleSOWExpanded(isExpanded ? -1 : index);
    setIsExpanded((prev) => !prev);
  };

  const getValueInLakhsString = (value) => {
    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const selectedScope = scopeList.find(
    (scope) => scope.scopeOfWorkId === sowItem.scopeOfWorkId,
  );

  const getTotalAmount = useCallback(
    (data) => {
      const totalAmountForAllBoqs = data?.reduce((totalBoqAmount, subItem) => {
        const totalAmountForSubItem = subItem.titles?.reduce(
          (totalTitleAmount, title) => {
            const totalAmountForTitle = title.descriptions?.reduce(
              (total, current) => total + current.amount,
              0,
            );
            return totalTitleAmount + totalAmountForTitle;
          },
          0,
        );
        return totalBoqAmount + totalAmountForSubItem;
      }, 0);
      return totalAmountForAllBoqs;
    },
    [sowItem],
  );

  const getTotalPercentage = useCallback(
    (data) => {
      return data.reduce((totalBoqPercentage, subItem) => {
        const totalPercentageForSubItem = subItem.titles?.reduce(
          (totalTitlePercentage, title) => {
            const totalPercentageForTitle = title.descriptions?.reduce(
              (total, current) => {
                if (current.amount > 0) {
                  return total + (current.actualAmount / current.amount) * 100;
                }
                return total;
              },
              0,
            );
            return totalTitlePercentage + totalPercentageForTitle;
          },
          0,
        );
        return totalBoqPercentage + totalPercentageForSubItem;
      }, 0);
    },
    [sowItem],
  );

  return (
    <Accordion
      style={{
        minWidth: '100%',
        overflowY: 'auto',
        width: '100%',
        margin: 0,
      }}
      onChange={handleChange}
      expanded={expandedSOW === index}
      ref={accordionRef}
    >
      <AccordionSummary>
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            container
            style={{ flexWrap: 'nowrap', minWidth: 27 }}
            alignItems="center"
          >
            <Grid
              item
              container
              style={{
                backgroundColor: 'rgba(1,1,1,0.2)',
                borderRadius: 20,
                minWidth: 30,
                maxWidth: 30,
                height: 30,
                color: '#000000',
                margin: '0px 10px',
              }}
              justify="center"
              alignItems="center"
            >
              {sowItem.scopeOfWorkId}
            </Grid>
            <Grid style={{ minWidth: 70, flexWrap: 'nowrap' }} item container>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  flexGrow: 1,
                  textWrap: 'nowrap',
                  display: 'flex',
                }}
              >
                <img
                  src={selectedScope.imageUrl}
                  alt={selectedScope.name}
                  style={{ width: 20, height: 20, margin: '0px 10px' }}
                />
                {sowItem?.scopeOfWork}
              </Typography>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          </Grid>
          <Grid item style={{ maxWidth: 240 }} container>
            <Grid item container xs={6}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                {getValueInLakhsString(getTotalAmount(sowItem.boQs))}
              </Typography>
              <Typography
                style={{
                  color: '#ADBAC3',
                  fontSize: 10,
                }}
              >
                Actual Amount
              </Typography>
            </Grid>
            <Grid item xs={6} container>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                {`${getTotalPercentage(sowItem.boQs).toFixed(2)}%`}
              </Typography>
              <Typography
                style={{
                  color: '#ADBAC3',
                  fontSize: 10,
                }}
              >
                % Percentage
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        style={{
          width: '100%',
          padding: '8px 0px 16px',
          margin: 0,
        }}
      >
        <Grid item container>
          {sowItem?.boQs.map((boqItem, boqIndex) => {
            return (
              <BOQAccordian boqIndex={boqIndex} boqItem={boqItem} {...props} />
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SOWAccordian;
