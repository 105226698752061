import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import SearchInput from 'src/components/SearchInput';
import SortSelection from 'src/components/SortSelection';
import EventNoteIcon from '@material-ui/icons/EventNote';
import general from '../../../assests/SOW-temp-icon.png';

import {
  ExpandLess as ExpandLessIcon,
  NavigateNext as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import useAccessPerView from 'src/common/Access';

const TABLE_COLUMNS = [
  { label: 'WORK ORDER NO', width: '5%' },
  { label: 'LABOUR CONTRACTOR', width: '5%' },
  { label: 'SCOPE OF WORK', width: '5%' },
  { label: 'MOBILIZATION ADVANCE', width: '5%' },
  { label: 'GROSS PAYABLE', width: '5%' },
  { label: 'RETENTION RECOVERY', width: '5%' },
  { label: 'ADVANCE RECOVERY', width: '5%' },
  { label: 'DEBIT NOTES', width: '5%' },
  { label: 'OTHER DETECTIONS', width: '5%' },
  { label: 'NET AMOUNT', width: '5%' },
  { label: 'COMPLETION %', width: '5%' },
  { label: 'ACTION', width: '5%' },
];

const SummaryPercentageBar = (props) => {
  const { completionPercent } = props;

  const animatePercentageValue = (div) => {
    if (div) {
      setTimeout(() => {
        div.style.maxWidth = `${completionPercent}%`;
      }, 300);
    }
  };

  return (
    <Grid container>
      <Typography style={{ fontSize: 10, flex: 'auto' }} align="right">
        {completionPercent}%
      </Typography>
      <Grid container item style={{ height: 7 }}>
        <Grid
          item
          ref={animatePercentageValue}
          style={{
            width: '100%',
            maxWidth: 0,
            background: '#81B734',
            borderRadius: '8px 0px 0px 8px',
            transition: 'max-width 1s ease-out',
            height: '100%',
          }}
        ></Grid>
        <Grid
          item
          style={{
            width: '100%',
            maxWidth: `${100 - completionPercent}%`,
            borderRadius: '0px 8px 8px 0px',
            background: '#c5d0fc',
            height: '100%',
          }}
        ></Grid>
      </Grid>
    </Grid>
  );
};

const SummaryRow = (props) => {
  const { hasAccess } = useAccessPerView('BILL_MANAGEMENT');

  const { index } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const renderIcon = () => {
    const toggleExpand = () => setIsExpanded((prev) => !prev);
    if (isExpanded) {
      return (
        <IconButton onClick={toggleExpand}>
          <ExpandLessIcon color="black" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleExpand}>
        <ExpandMoreIcon color="inherit" />
      </IconButton>
    );
  };

  const renderLabourContractorName = () => (
    <Grid container alignItems="center" style={{ flexWrap: 'noWrap' }}>
      <img
        height="25"
        width="25"
        src="https://s3-alpha-sig.figma.com/img/20c4/391d/5bc40c5bd238032a6720fb6882b96b65?Expires=1712534400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=J1nxiYeHoS7RwQaiRAZfufY-CzMcEFV7yDgk2pvUmYjaipFm2fyaGJGTxgjxBijcUTj0cEJLOSBDI3~JGzc9vXumYLvw8hx42UKcy3I3-tZlVxawEjbSiZT0WXkCcsWFwFQxr44rJqBzZedVcsMegDC~3vCo0zcr7hnIr70vRY6R3G4aHfegCkHO-tLe5f66YgKH0IHvY2bv0obhepJGUhrKE-TTfQ56OQ5MrrGX7cOoxb0ahyPoGKUsBWSIkDexoDEcMLZjcldtKdr1xtGTIruuy2UgrnLThLCwQFwCrvgLxiDHES-0BoCINwHHI1s4IV6yEfSHJcOXYEB9eJQt4g__"
        alt="some"
      />
      <Typography
        variant="p"
        style={{ fontSize: 11, textWrap: 'noWrap', marginLeft: 10 }}
      >
        Vinod Shukla
      </Typography>
    </Grid>
  );

  const renderScopeOfWork = () => (
    <Grid container style={{ flexWrap: 'nowrap' }} alignItems="flex-end">
      <Grid item container alignItems="flex-end" style={{ flexWrap: 'noWrap' }}>
        <img height="25" width="25" src={general} alt="some" />
        <Typography variant="p" style={{ fontSize: 11, marginLeft: 10 }}>
          General
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        style={{
          borderRadius: '10px',
          height: 20,
          width: 25,
          fontSize: 11,
          marginLeft: 2,
          background: '#E7EAEC',
          color: '#829096',
        }}
      >
        +3
      </Grid>
    </Grid>
  );

  const renderRABill = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <Typography
          style={{ textWrap: 'nowrap', color: 'inherit', fontSize: 11 }}
        >
          {`WO - ${index}`}
        </Typography>
        {renderIcon()}
      </Grid>
    );
  };

  const renderRow = () => {
    return (
      <TableRow>
        <TableCell style={{ padding: '0 10px' }}>{renderRABill()}</TableCell>
        <TableCell>{renderLabourContractorName()}</TableCell>
        <TableCell>{renderScopeOfWork()}</TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ₹ 6,67,352
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ₹ 3,67,000
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ₹ 1,67,352
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ---
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ₹ 1,67,352
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ---
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          ₹ 1,67,352
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          <SummaryPercentageBar completionPercent={85} />
        </TableCell>
        <TableCell style={{ textWrap: 'nowrap', fontSize: 11 }} align="center">
          <IconButton
            style={{ padding: 0 }}
            disabled={!hasAccess.LEVEL1 && !hasAccess.LEVEL2}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const renderSubRows = () => {
    const height = isExpanded ? 40 : 0;
    const subRow = (
      <TableRow
        style={{
          transition: 'height 0.3s ease',
          height,
          visibility: isExpanded ? 'visible' : 'collapse',
        }}
      >
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ₹ 6,67,352
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ₹ 3,67,000
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ₹ 1,67,352
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ---
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ₹ 1,67,352
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ---
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          ₹ 1,67,352
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          <SummaryPercentageBar completionPercent={80} />
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
            backgroundColor: '#DBE9F6',
          }}
        >
          <IconButton style={{ padding: 0 }}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
    return [0, 1, 2].map((item) => subRow);
  };

  return (
    <>
      {renderRow()}
      {renderSubRows()}
    </>
  );
};

const ContractorLedgerSummary = (props) => {
  const noWrapColumns = ['LABOUR CONTRACTOR', 'SCOPE OF WORK', 'COMPLETION %'];
  const renderTable = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_COLUMNS.map((column) => {
              return (
                <TableCell
                  style={{
                    color: '#B2BEC7',
                    fontSize: 10,
                    padding: '7px',
                    width: column.width,
                    textWrap: noWrapColumns.includes(column.label)
                      ? 'nowrap'
                      : 'balance',
                    lineHeight: '11px',
                  }}
                  align="center"
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody style={{ overflow: 'hidden' }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <SummaryRow index={item} />
          ))}
        </TableBody>
      </Table>
    );
  };
  const renderTitle = () => {
    return (
      <Grid item container style={{ padding: '20px 10px 5px 20px' }}>
        <Grid item>
          <Typography
            style={{ fontSize: 15, fontWeight: 600, marginBottom: 10 }}
          >
            Contractor Ledger Summary
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderFilters = () => {
    return (
      <Grid item container className="input-container-nav">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          spacing={2}
          style={{
            background: '#f0f3f5',
            border: '1px solid #ddd',
          }}
        >
          <Grid item container xs={12} md={6} justify="space-between">
            <Grid
              item
              md={5}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4} md={3}>
                <small className="font11">Sort By:</small>
              </Grid>
              <Grid item xs={8} md={9}>
                <SortSelection
                  handleFilters={() => {}}
                  value={''}
                  data={[{ value: 'some', label: 'Scope of Work' }]}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <small className="font11">Search Filters:</small>
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  dateFormat="MMMM d, yyyy"
                  selectsRange={true}
                  startDate={new Date()}
                  isClearable={true}
                  className="font12 date-picker "
                />
                <EventNoteIcon
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '7px',
                    color: '#ADBAC3',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={5}
            xs={12}
            item
            container
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={2}>
              <small className="font11">Search:</small>
            </Grid>
            <Grid item md={10} xs={8} style={{ position: 'relative' }}>
              <SearchInput search={''} handleSearch={() => {}} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper style={{ margin: 10, borderRadius: 10 }}>
      {renderTitle()}
      {renderFilters()}
      <Grid item container style={{ overflow: 'auto' }}>
        {renderTable()}
      </Grid>
    </Paper>
  );
};

export default ContractorLedgerSummary;
