import React from 'react';
import {
	Backdrop,
	Button,
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { createRole, getRoles, rolesReducer } from 'src/redux/roles';
import { useNavigate } from 'react-router';
import CollapsibleTable from './role-table';

const useStyles = makeStyles((theme) => ({
	addRoleButton: {
		textTransform: 'capitalize',
		borderRadius: '10px',
		color: '#986FEF',
		backgroundColor: 'rgba(161, 120, 242, 0.20)',
		// '&:hover': {
		// 	backgroundColor: 'rgba(161, 120, 242, 0.20)',
		// },
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: 'rgba(129, 183, 52, 1)',
	},

	[theme.breakpoints.down('sm')]: {},
}));

const Roles = () => {
	//! INSTACNE
	let dispatch = useDispatch();
	let classes = useStyles();
	let navigate = useNavigate();
	//! SELECTOES
	const { loading, rolesList, error } = useSelector(rolesReducer);

	//! LIFE-CYCLE
	useEffect(() => {
		dispatch(getRoles());
		// dispatch(
		// 	createRole({
		// 		roleId: 0,
		// 		name: 'Manager',
		// 		featureSetIds: [2, 4, 6],
		// 	})
		// );
	}, []);

	const handleRoles = () => {
		navigate('./add');
	};

	return (
		<Grid container style={{ padding: '1rem' }}>
			{/* <Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop> */}
			<Grid container item xs={12} justify='space-between' alignItems='center'>
				<Grid item xs={6}>
					Roles
				</Grid>
				<Grid item xs={2}>
					<Button
						fullWidth
						startIcon={<AccountCircleIcon />}
						className={classes.addRoleButton}
						onClick={handleRoles}>
						{' '}
						Add Role
					</Button>
				</Grid>
			</Grid>
			<Grid item container style={{ margin: '1rem 0' }}>
				<CollapsibleTable rolesList={rolesList} />
			</Grid>
		</Grid>
	);
};

export default Roles;
