import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import WorkOrderRow from './components/WorkorderRow';

const NestedBOQs = (props) => {
  const {
    classes,
    boq,
    grossProgress,
    onSave,
    scopeList = {},
    handleWorkOrderExpansion,
  } = props;

  const [expanded, setExpanded] = useState(true);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  const selectedScope = scopeList.find(
    (scope) => scope?.scopeOfWorkId === boq?.scopeOfWorkId,
  );

  const memoizedList = boq?.boQs?.map((workOrderData, index) => {
    return (
      <WorkOrderRow
        index={index}
        onSave={onSave}
        workOrder={workOrderData}
        classes={classes}
        grossProgress={grossProgress}
        onExpand={handleWorkOrderExpansion}
      />
    );
  });

  const scopeField = (
    <Grid
      item
      container
      style={{
        margin: '5px 10px',
        padding: '10px 0px',
      }}
    >
      <Grid
        item
        style={{
          backgroundColor: 'rgba(1,1,1,0.2)',
          borderRadius: 20,
          width: 30,
          height: 30,
          color: 'white',
          marginRight: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {boq?.scopeOfWorkId}
      </Grid>
      <Grid style={{ width: 100, flexWrap: 'nowrap' }} item container>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: 16,
            flexGrow: 1,
            textWrap: 'nowrap',
            display: 'flex',
          }}
        >
          <img
            src={selectedScope?.imageUrl}
            alt={selectedScope?.name || ''}
            style={{ width: 20, height: 20, margin: '0px 10px' }}
          />
          {boq?.scopeOfWork}
        </Typography>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Grid>
    </Grid>
  );

  return (
    <Accordion
      style={{
        border: '1px solid rgba(1,1,1,0.1)',
        borderRadius: 10,
        width: '100%',
        margin: '15px 0px',
        boxShadow: 'none',
      }}
      onChange={handleToggle}
      open={expanded}
    >
      <AccordionSummary
        classes={{
          expanded: classes.expanded,
        }}
        style={{ padding: 0, margin: 0 }}
      >
        <Grid item container>
          {scopeField}
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0, margin: 0 }}>
        <Grid item container style={{ padding: '10px' }}>
          {memoizedList}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const NestedBOQ = React.memo(NestedBOQs);
