import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useSelector, useDispatch } from 'react-redux';
import WorkIcon from '@material-ui/icons/Work';
import { useToasts } from 'react-toast-notifications';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import {
  jobReducer,
  getJobs,
  updateJob,
  getSkills,
  selectJob,
} from '../../redux/jobs';
import { getProjects, projectReducer } from 'src/redux/projects';
import * as yup from 'yup';
import { useFormik } from 'formik';
import VideocamIcon from '@material-ui/icons/Videocam';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MicNoneIcon from '@material-ui/icons/MicNone';
import Geocode from 'react-geocode';
import moment from 'moment/moment';
import { importFileandPreview } from '@rajesh896/video-thumbnails-generator';

import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
  },
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },

  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },

  inputFieldGridItem: {
    paddingRight: '2rem',
  },
  heading: {
    fontFamily: 'Roboto',
  },
  imagePlaceHolder: {
    width: '335px',
    height: '280px',
  },
  commonStyles: {
    fontFamily: 'Roboto',
  },
  audioVideoTitle: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 600,
  },
  audioVideoBoxes: {
    height: '45px',
    border: '1px dashed green',
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  audioVideoBoxesIcon: {
    color: 'rgba(129, 183, 52, 1)',
    backgroundColor: 'rgba(129, 183, 52, 0.2)',
    padding: '5px',
    borderRadius: ' 4px',
    marginLeft: '5px',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldGridItem: {
      paddingRight: '0px',
    },
  },
}));

const CreateJob = (props) => {
  const [media, setMedia] = useState({
    audio: '',
    video: '',
  });
  const [view, setView] = useState(false);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoThumb, setVideoThumb] = useState('');
  const [thumbnails, setThumbnails] = useState([]);
  const [subSkillFound, setSubSkillFound] = useState(false);

  const refs = useRef({
    video: null,
    loader: null,
    numberInput: null,
    thumbButton: null,
  });
  const [selectedMedia, setSelectedMedia] = useState({
    audio: null,
    video: null,
  });
  const [markerObj, setMarkerObj] = useState(null);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: formik?.values.latitude,
      lng: formik?.values.longitude,
    },
    zoom: 11,
  });

  const reff = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (reff.current && !map) {
      setMap(new window.google.maps.Map(reff.current, {}));
    }
  }, [reff, map]);

  //! INSTANCES
  const { addToast } = useToasts();
  let dispatch = useDispatch();
  const classes = useStyles();
  const formData = new FormData();
  let navigate = useNavigate();
  const { selectedJob } = useSelector(jobReducer);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  });

  //! LIFE CYCLE

  useEffect(() => {
    setSelectedMedia({
      ...selectedMedia,
      audio: selectedJob?.jobMedia.filter(
        (item) => item.mediaTypeId === 'Audio'
      )[0],
      video: selectedJob?.jobMedia.filter(
        (item) => item.mediaTypeId === 'Video'
      )[0],
    });
    if (video) {
      importFileandPreview(video).then((res) => {
        setVideoUrl(res);
      });
      setVideoThumb('');
      setThumbnails([]);
      if (refs.current.video) {
        refs.current.video.style.transform = 'scale(1)';
      }

      if (refs.current.numberInput) {
        refs.current.numberInput.style.display = 'block';
      }
      if (refs.current.thumbButton) {
        refs.current.thumbButton.style.display = 'block';
      }
    }
  }, [video]);

  useEffect(() => {
    if (selectedJob) {
      Geocode.fromLatLng(selectedJob?.latitude, selectedJob?.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // setLocation(address);
          return setValue(address);
        },
        (error) => {
          // console.error('error', error.message);
          return setValue(error.message);
        }
      );
    }
  }, []);

  //! SELECTORS
  const { loading, skillsList } = useSelector(jobReducer);
  const { projectsList } = useSelector(projectReducer);

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    startDate: yup.string().required('Start date is required'),
    // endDate: yup.string().required('End date is required'),
    // latitude: yup.string().required("Location is required"),
    // longitude: yup.string().required('Longitude is required'),
    skillId: yup.string().required('Skill is required'),
    projectId: selectedJob?.projectId
      ? yup.number().required('Project is required')
      : yup.object().required('Project is required'),
    skillLevel: yup.number().required('Skill level is required'),
    reportingTime: yup.string().required('Reporting time is required'),
    rating: yup.number().required('Rating is required'),
    requiredWorkers: yup.number().required('Required workers is required'),
    dailyWage: yup.number().min(1).required('Daily wage is required'),
    contactNumber: yup.number().required('Contact number is required'),
    manDays: yup.number().required('Man Days are required'),

    // image: yup.string().required('Image is required'),
    // audio: yup.string().required('Audio is required'),
    // video: yup.string().required('Video  is required'),
  });
  var formik = useFormik({
    initialValues: {
      contractorId: 1,
      startDate: selectedJob?.startDate
        ? moment(selectedJob?.startDate).format('YYYY-MM-DD')
        : '',
      endDate: selectedJob?.endDate
        ? moment(selectedJob?.endDate).format('YYYY-MM-DD')
        : '',
      latitude: selectedJob?.latitude ?? '',
      longitude: selectedJob?.longitude ?? '',
      skillId: selectedJob?.skillId
        ? skillsList?.filter((item) => item.skillId === selectedJob?.skillId)[0]
        : '',
      skillLevel: selectedJob?.skillLevel ?? 0,
      reportingTime: selectedJob?.reportingTime
        ? moment(selectedJob?.reportingTime).toDate()
        : '',
      rating: selectedJob?.rating ?? 0,
      requiredWorkers: selectedJob?.requiredWorkers ?? '',
      dailyWage: selectedJob?.dailyWage ?? '',
      audio: '',
      video: '',
      projectId:
        projectsList?.filter(
          (item) => item.projectId === selectedJob?.projectId
        )[0]?.projectId ?? 'Select Project',
      description: selectedJob?.description ?? '',
      contactNumber: selectedJob?.contactNumber ?? '',
      manDays: selectedJob?.manDays ?? '',
      isFood: selectedJob?.isFood ?? false,
      isAccomodation: selectedJob?.isAccomodation ?? false,
      skillTypeId: selectedJob?.skillTypeId ?? 'Supervisor',
      cityName: value,
      isPrivate: selectedJob?.isPrivate ?? false,
      supervisorName: selectedJob?.supervisorName ?? '',
      isNMR: selectedJob?.isNMR ?? true,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const { projectId, reportingTime, isPrivate, skillTypeId } = values;
      let newData = {
        ...values,
        projectId: projectId.projectId,
        reportingTime: moment(reportingTime).format('YYYY-MM-DD HH:mm:ss'),
        isPrivate: isPrivate,
        skillId: values?.skillId?.skillId,
        skillTypeId:
          values.skillId?.name === 'Machine Operator' ? 'Skilled' : skillTypeId,
      };
      const objectKeys = Object.keys(
        selectedJob
          ? {
              ...values,
              reportingTime: moment(reportingTime).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              skillId: values?.skillId?.skillId,
            }
          : newData
      );
      const objectValues = Object.values(
        selectedJob
          ? {
              ...values,
              reportingTime: moment(reportingTime).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              skillId: values?.skillId?.skillId,
            }
          : newData
      );
      // formData.append('cityName', value);
      if (selectedJob) {
        formData.append('jobId', selectedJob?.jobId);
      }

      objectKeys.forEach((key, index) => {
        if (key == 'audio') {
          return formData.append(key, media.audio);
        }
        if (key == 'video') {
          return formData.append(key, media.video);
        }
        formData.append(key, objectValues[index]);
      });

      let resp = await dispatch(updateJob(formData));
      if (resp.status === 200) {
        formik.resetForm();
        dispatch(getJobs());
        dispatch(selectJob(null));
        navigate('..');

        return addToast('Saved Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else if (resp.status === 400) {
        return addToast('There might be validation errors', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        return addToast(resp.data.error, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });

  console.log('formikForm', formik);
  useEffect(() => {
    if (!skillsList) {
      dispatch(getSkills());
    }
    if (!projectsList) {
      dispatch(getProjects());
    }
    // else {
    //   let item = projectsList?.filter(
    //     (item) => item.projectId === selectedJob?.projectId
    //   );
    //   formik.setFieldValue("projectId", item[0]);
    //   console.log("item", item);
    // }
  }, []);
  // useEffect(() => {
  //   // if (formik.values.skillId) {
  //   // 	let found = skillsList.filter(
  //   // 		(skill) => skill.skillId === formik.values.skillId
  //   // 	);
  //   // 	let isFound = subSkills.filter((sk) => sk === found[0].name);
  //   // 	if (isFound.length > 0) {
  //   // 		setSubSkillFound(true);
  //   // 	} else {
  //   // 		setSubSkillFound(false);
  //   // 	}
  //   // }
  // }, [formik.values.skillId]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (!formik.values.latitude && !formik.values.longitude) {
        setDefaultProps({
          ...defaultProps,
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      }
    });
  }, []);
  // 	const handleSelect =
  // 		({ description }) =>
  // 		() => {
  // 			// When user selects a place, we can replace the keyword without request data from API
  // 			// by setting the second parameter to "false"
  // 			setValue(description, false);
  // 			clearSuggestions();
  //
  // 			// console.log('description', description);
  // 			// Get latitude and longitude via utility functions
  // 			getGeocode({ address: description }).then((results) => {
  // 				const { lat, lng } = getLatLng(results[0]);
  // 				formik.setFieldValue('latitude', lat);
  // 				formik.setFieldValue('longitude', lng);
  // 				formik.setFieldValue('cityName', results[0].formatted_address);
  // 			});
  // 		};

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  // const handleInput = (e) => {
  // 	// Update the keyword of the input element
  // 	setValue(e.target.value);
  // 	// setView(false);
  // 	clearSuggestions();
  // };

  // 	const renderSuggestions = () => {
  // 		data.map((suggestion) => {
  // 			const {
  // 				place_id,
  // 				structured_formatting: { main_text, secondary_text },
  // 			} = suggestion;
  //
  // 			return (
  // 				<List className={classes.root} subheader={<li />}>
  // 					{data.map((sectionId) => (
  // 						<li
  // 							key={place_id}
  // 							onClick={handleSelect(suggestion)}
  // 							className={classes.listSection}>
  // 							<strong>{main_text}</strong> <small>{secondary_text}</small>
  // 						</li>
  // 					))}
  // 				</List>
  // 			);
  // 		});
  // 	};

  const handleAudio = (e) => {
    setMedia({
      ...media,
      audio: e.target.files[0],
    });
    setAudio(e.target.files[0]);
  };
  const handleVideo = (e) => {
    // formData.append('video', e.target.files[0]);
    setMedia({
      ...media,
      video: e.target.files[0],
    });
    setVideo(e.target.files[0]);
  };
  // 	const handleProjectImage = (e) => {
  // 		let selectedImage = e.target.files[0];
  // 		formik.setFieldValue('image', selectedImage);
  // 	};
  //
  // 	// console.log('video', video);
  //
  // 	const handleMapClick = (key, childProps) => {
  // 		console.log('key', key);
  // 		console.log('childProps', childProps);
  // 	};
  //
  // 	const AnyReactComponent = ({ text }) => (
  // 		<RoomIcon style={{ color: 'green', fontSize: '2.5rem' }} />
  // 	);

  // const fetchAddress = (project) => {
  //   Geocode.fromLatLng(project?.latitude, project?.longitude).then(
  //     async (response) => {
  //       const address = await response.results[0].formatted_address;
  //       console.log(address);
  //       formik.setFieldValue("cityName", address);
  //       setValue(address);
  //     },
  //     (error) => {
  //       // return setValue(error.message);
  //     }
  //   );
  // };
  // const handleProjectChange = async (project) => {
  //   if (project === "Select Project") {
  //     return formik.setFieldValue("projectId", "Select Project");
  //   }
  //   if (project?.latitude !== 0) {
  //     formik.setFieldValue("latitude", project?.latitude);
  //     formik.setFieldValue("longitude", project?.longitude);
  //     fetchAddress(project);
  //   } else {
  //     setValue("This project lat, lng has 0");
  //   }
  //   formik.setFieldValue("projectId", project);
  // };
  // console.log("formik.values.projectId", formik.values);

  const handleProjectChange = useCallback(
    async (project) => {
      if (project === 'Select Project') {
        return formik.setFieldValue('projectId', 'Select Project');
      }
      formik.setFieldValue('projectId', project);
      formik.setFieldValue('latitude', project.latitude);
      formik.setFieldValue('longitude', project.longitude);
      if (project?.latitude !== 0) {
        try {
          const response = await Geocode.fromLatLng(
            project.latitude,
            project.longitude
          );
          const address = response.results[0].formatted_address;
          formik.setFieldValue('cityName', address);
          // setValue(address);
        } catch (error) {
          formik.setFieldValue('cityName', null);

          // setValue(error.message);
        }
      } else {
        formik.setFieldValue('cityName', null);
      }
    },
    [formik.values.projectId?.projectId]
  );

  const handleSkillCheckbox = (name) => {
    formik.setFieldValue('skillTypeId', name);
  };

  return (
    <Grid container xs={12} justify="center">
      <Grid container xs={11}>
        <Typography
          variant="h5"
          className={classes.heading}
          style={{ paddingBottom: '1rem' }}
        >
          Create New Job
        </Typography>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid item container>
            <Grid container item xs={12} alignItems="center">
              <Grid item md={6} xs={12} className={classes.inputFieldGridItem}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: '#ADBAC3',
                    marginBottom: 5,
                    fontFamily: 'Roboto',
                  }}
                >
                  PROJECT NAME
                </Typography>
                <TextField
                  select
                  size="small"
                  style={{
                    marginBottom: '1rem',

                    fontFamily: 'Roboto',
                  }}
                  // placeholder='----'
                  variant="outlined"
                  fullWidth
                  disabled={selectedJob ? true : false}
                  // style={{ width: "300px" }}
                  id="projectId"
                  name="projectId"
                  value={formik.values.projectId}
                  onChange={(data) => handleProjectChange(data.target.value)}
                  error={
                    formik.touched.projectId && Boolean(formik.errors.projectId)
                  }
                  helperText={
                    formik.touched.projectId && formik.errors.projectId
                  }
                >
                  <MenuItem value="Select Project">Select Project</MenuItem>

                  {projectsList?.map((project) => (
                    <MenuItem
                      key={project.projectId}
                      value={selectedJob ? project?.projectId : project}
                      style={{ fontFamily: 'Roboto' }}
                      // onClick={() => handleProjectChange(project)}
                    >
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: '#ADBAC3',
                    marginBottom: 5,
                    fontFamily: 'Roboto',
                  }}
                >
                  REQUIRED WORKERS
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  style={{ marginBottom: '1rem' }}
                  id="requiredWorkers"
                  name="requiredWorkers"
                  value={formik.values.requiredWorkers}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.requiredWorkers &&
                    Boolean(formik.errors.requiredWorkers)
                  }
                  helperText={
                    formik.touched.requiredWorkers &&
                    formik.errors.requiredWorkers
                  }
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: '#ADBAC3',
                    marginBottom: 5,
                    fontFamily: 'Roboto',
                  }}
                >
                  SUPERVISOR NAME
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  // type="number"
                  style={{ marginBottom: '1rem' }}
                  id="supervisorName"
                  name="supervisorName"
                  value={formik.values.supervisorName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.supervisorName &&
                    Boolean(formik.errors.supervisorName)
                  }
                  helperText={
                    formik.touched.supervisorName &&
                    formik.errors.supervisorName
                  }
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                />
              </Grid>
              <Grid item container>
                <Grid
                  item
                  md={6}
                  xs={12}
                  className={classes.inputFieldGridItem}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    START DATE
                  </Typography>
                  <TextField
                    size="small"
                    id="startDate"
                    disabled={selectedJob ? true : false}
                    style={{ marginBottom: '1rem' }}
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    variant="outlined"
                    // type='datetime-local'
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { fontFamily: 'Roboto' },
                      min: !selectedJob && moment().format('YYYY-MM-DD'),
                    }}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    MAN DAYS
                  </Typography>
                  {/* <TextField
										variant='outlined'
										// type='datetime-local'
										type='date'
										className={classes.textField}
										InputLabelProps={{
											shrink: true,
										}}
										style={{ marginBottom: '1rem' }}
										id='endDate'
										name='endDate'
										value={formik.values.endDate}
										onChange={formik.handleChange}
										error={
											formik.touched.endDate && Boolean(formik.errors.endDate)
										}
										helperText={formik.touched.endDate && formik.errors.endDate}
										inputProps={{ style: { fontFamily: 'Roboto' } }}
									/> */}
                  <TextField
                    size="small"
                    variant="outlined"
                    // type='datetime-local'
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginBottom: '1rem' }}
                    id="manDays"
                    name="manDays"
                    value={formik.values.manDays}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.manDays && Boolean(formik.errors.manDays)
                    }
                    helperText={formik.touched.manDays && formik.errors.manDays}
                    inputProps={{ style: { fontFamily: 'Roboto' } }}
                  />
                </Grid>
              </Grid>

              <Grid item container>
                <Grid
                  item
                  md={6}
                  xs={12}
                  className={classes.inputFieldGridItem}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    SKILL SET
                  </Typography>
                  <TextField
                    select
                    size="small"
                    style={{ marginBottom: '1rem', fontFamily: 'Roboto' }}
                    // placeholder='----'
                    disabled={selectedJob ? true : false}
                    variant="outlined"
                    fullWidth
                    // style={{ width: "300px" }}
                    id="skillId"
                    name="skillId"
                    value={formik.values.skillId}
                    onChange={(e) => {
                      formik.setFieldValue('skillId', e.target.value);
                    }}
                    error={
                      formik.touched.skillId && Boolean(formik.errors.skillId)
                    }
                    helperText={formik.touched.skillId && formik.errors.skillId}
                  >
                    <MenuItem value="Select Skill">Select Skill</MenuItem>

                    {skillsList?.map((skill) => (
                      <MenuItem
                        key={skill.skillId}
                        value={skill}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {skill.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* {subSkillFound && ( */}
                {/* {formik.values.skillId?.name === "Machine Operator" ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    // className={classes.inputFieldGridItem}
                  >
                    <Typography
                      style={{
                        fontSize: 12,
                        color: "#ADBAC3",
                        marginBottom: 5,
                        fontFamily: "Roboto",
                      }}
                    >
                      SKILL SET
                    </Typography>
                    <TextField
                      select
                      size="small"
                      style={{ marginBottom: "1rem", fontFamily: "Roboto" }}
                      // placeholder='----'
                      disabled={selectedJob ? true : false}
                      variant="outlined"
                      fullWidth
                      // style={{ width: "300px" }}
                      id="skillTypeId"
                      name="skillTypeId"
                      value={formik.values.skillTypeId}
                      onChange={(e) => {
                        formik.setFieldValue("skillTypeId", e.target.value);
                      }}
                      error={
                        formik.touched.skillTypeId &&
                        Boolean(formik.errors.skillTypeId)
                      }
                      helperText={
                        formik.touched.skillTypeId && formik.errors.skillTypeId
                      }
                    >
                      <MenuItem value="Select Skill">
                        Select Skill Level
                      </MenuItem>

                      {[
                        "Bobcat",
                        "Bulldozer",
                        "Tower Crane",
                        "Tough Rider",
                        "Signalman",
                        "Concrete Mixer",
                        "Forklift",
                        "Tractor",
                        "JCB",
                        "RSP -Rope Suspended Platform",

                        "Driver",
                        "Hoist Operator",
                        "Sewage Treatment Operator",
                      ]?.map((subSkill) => (
                        <MenuItem
                          key={subSkill}
                          value={subSkill}
                          style={{ fontFamily: "Roboto" }}
                        >
                          {subSkill}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ) : ( */}
                <Grid
                  item
                  md={6}
                  xs={12}
                  // className={ classes.inputFieldGridItem }
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    SKILL LEVEL
                  </Typography>
                  {/* <TextField
											select
											size='small'
											style={{ marginBottom: '1rem', fontFamily: 'Roboto' }}
											// placeholder='----'
											variant='outlined'
											fullWidth
											// style={{ width: "300px" }}
											id='skillTypeId'
											name='skillTypeId'
											value={formik.values.skillTypeId}
											onChange={formik.handleChange}
											error={
												formik.touched.skillTypeId &&
												Boolean(formik.errors.skillTypeId)
											}
											helperText={
												formik.touched.skillTypeId && formik.errors.skillTypeId
											}>
											<MenuItem value='Select Skill'>
												Select Skill Level
											</MenuItem>
											{['Skilled', 'Helper']?.map((skill, index) => (
												<MenuItem
													key={index}
													value={skill}
													style={{ fontFamily: 'Roboto' }}>
													{skill}
												</MenuItem>
											))}
										</TextField> */}

                  <FormControl size="small">
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={formik.values.skillTypeId}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Supervisor"
                        control={
                          <Radio
                            disabled={selectedJob ? true : false}
                            onChange={(e) => handleSkillCheckbox('Supervisor')}
                          />
                        }
                        label="Supervisor"
                      />
                      <FormControlLabel
                        value="Skilled"
                        control={
                          <Radio
                            disabled={selectedJob ? true : false}
                            onChange={(e) => handleSkillCheckbox('Skilled')}
                          />
                        }
                        label="Skilled"
                      />
                      <FormControlLabel
                        value="Helper"
                        control={
                          <Radio
                            disabled={selectedJob ? true : false}
                            onChange={(e) => handleSkillCheckbox('Helper')}
                          />
                        }
                        label="Helper"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {/* // )} */}
                {/* )} */}
                <Grid
                  item
                  md={6}
                  xs={12}
                  className={classes.inputFieldGridItem}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    REPORTING TIME
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      margin="normal"
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      size="small"
                      style={{ marginBottom: '1rem', fontFamily: 'Roboto' }}
                      variant="outlined"
                      className={classes.textField}
                      id="reportingTime"
                      name="reportingTime"
                      value={formik.values.reportingTime}
                      onChange={(time) => {
                        formik.setFieldValue('reportingTime', time);
                      }}
                      error={
                        formik.touched.reportingTime &&
                        Boolean(formik.errors.reportingTime)
                      }
                      // disabled
                      helperText={
                        formik.touched.reportingTime &&
                        formik.errors.reportingTime
                      }
                    />
                  </MuiPickersUtilsProvider>
                  {/* <TextField
                    size="small"
                    style={{ marginBottom: "1rem", fontFamily: "Roboto" }}
                    variant="outlined"
                    // type='datetime-local'
                    type="time"
                    className={classes.textField}
                    inputProps={{
                      step: 300, // 5 minutes interval
                    }}
                    InputLabelProps={
                      {
                        // shrink: true,
                      }
                    }
                    id="reportingTime"
                    name="reportingTime"
                    value={formik.values.reportingTime}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.reportingTime &&
                      Boolean(formik.errors.reportingTime)
                    }
                    helperText={
                      formik.touched.reportingTime &&
                      formik.errors.reportingTime
                    }
                  /> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    style={{
                      fontSize: 12,
                      color: '#ADBAC3',
                      marginBottom: 5,
                      fontFamily: 'Roboto',
                    }}
                  >
                    JOB STATUS
                  </Typography>{' '}
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      defaultValue={formik.values.isPrivate}
                      value={formik.values.isPrivate}
                      onChange={(e) => {
                        formik.setFieldValue(
                          'isPrivate',
                          e.target.value == 'true' ? true : false
                        );
                        console.log('Check', e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                          // onChange={(e) =>
                          // formik.setFieldValue("isPrivate", false)
                          // }
                          />
                        }
                        label="Public"
                      />
                      <FormControlLabel
                        // value={false}
                        value={true}
                        control={
                          <Radio
                          // onChange={(e) =>
                          // formik.setFieldValue("isPrivate", true)
                          // }
                          />
                        }
                        label="Private"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            {/* <Grid
							item
							xs={4}
							className={classes.inputFieldGridItem}
							style={{ paddingRight: '2.6rem' }}
						>
							<Typography
								style={{
									fontSize: 12,
									color: '#ADBAC3',
									marginBottom: 5,
									fontFamily: 'Roboto',
								}}
							>
								REQUIRED SKILL LEVEL
							</Typography>
							<TextField
								variant='outlined'
								style={{ marginBottom: '1rem' }}
								type='number'
								className={classes.textField}
								id='skillLevel'
								name='skillLevel'
								InputProps={{
									inputProps: { min: 0, max: 100 },
									style: { fontFamily: 'Roboto' },
								}}
								value={formik.values.skillLevel}
								onChange={formik.handleChange}
								error={
									formik.touched.skillLevel && Boolean(formik.errors.skillLevel)
								}
								helperText={
									formik.touched.skillLevel && formik.errors.skillLevel
								}
							/>
						</Grid>
						<Grid item xs={4} className={classes.inputFieldGridItem}>
							<Typography
								style={{
									fontSize: 12,
									color: '#ADBAC3',
									marginBottom: 5,
									fontFamily: 'Roboto',
								}}
							>
								REQUIRED RATING LEVEL
							</Typography>
							<TextField
								style={{ marginBottom: '1rem' }}
								variant='outlined'
								fullWidth
								type='number'
								id='rating'
								name='rating'
								value={formik.values.rating}
								onChange={formik.handleChange}
								error={formik.touched.rating && Boolean(formik.errors.rating)}
								helperText={formik.touched.rating && formik.errors.rating}
								// style={{ width: "300px" }}
								inputProps={{ style: { fontFamily: 'Roboto' } }}
								InputProps={{
									inputProps: { min: 0, max: 5 },
									style: { fontFamily: 'Roboto' },
								}}
							/>
						</Grid> */}
            <Grid item md={6} xs={12} className={classes.inputFieldGridItem}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                DAILY WAGE ₹
              </Typography>
              <TextField
                disabled={selectedJob ? true : false}
                size="small"
                style={{ marginBottom: '1rem' }}
                id="dailyWage"
                name="dailyWage"
                value={formik.values.dailyWage}
                onChange={formik.handleChange}
                error={
                  formik.touched.dailyWage && Boolean(formik.errors.dailyWage)
                }
                helperText={formik.touched.dailyWage && formik.errors.dailyWage}
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{ style: { fontFamily: 'Roboto' } }}

                // style={{ width: "300px" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                SCOPE OF WORK
              </Typography>
              <TextField
                size="small"
                style={{ marginBottom: '1rem' }}
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                variant="outlined"
                fullWidth
                // type='number'
                inputProps={{ style: { fontFamily: 'Roboto' } }}

                // style={{ width: "300px" }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item md={6} xs={12} className={classes.inputFieldGridItem}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                LOCATION
              </Typography>
              <div ref={ref}>
                <TextField
                  size="small"
                  style={{ marginBottom: '1rem' }}
                  variant="outlined"
                  fullWidth
                  value={formik.values?.cityName ?? 'This has 0 lat, lng'}
                  // onChange={handleInput}
                  disabled
                  error={
                    formik.touched.latitude && Boolean(formik.errors.latitude)
                  }
                  helperText={formik.touched.latitude && formik.errors.latitude}
                  placeholder="Enter Job Location"
                  inputProps={{ style: { fontFamily: 'Roboto' } }}
                />
              </div>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                CONTACT NUMBER
              </Typography>

              <Grid item style={{ height: '40px' }}>
                <PhoneInput
                  style={{
                    width: '100%',
                    height: '100%',
                    // border: '1px solid #999',
                    border: `1px solid ${
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                        ? 'red'
                        : '#999'
                    }`,
                  }}
                  id="contactNumber"
                  name="contactNumber"
                  value={formik.values.contactNumber}
                  onChange={(value) =>
                    formik.setFieldValue('contactNumber', value)
                  }
                  country={'in'}
                  onlyCountries={['in']}
                  countryCodeEditable={false}

                  // value={this.state.phone}
                  // onChange={phone => this.setState({ phone })}
                />
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <small
                      style={{
                        fontSize: '12px',
                        margin: '0 14px',
                        color: 'red',
                      }}
                    >
                      Contact Number is required
                    </small>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              container
              md={6}
              xs={12}
              justify="flex-start"
              className={classes.inputFieldGridItem}
            >
              <Grid item>
                {' '}
                <FormControlLabel
                  control={
                    <Checkbox // checked={jason}
                      color="primary"
                      disabled={selectedJob ? true : false}
                      checked={formik.values.isFood}
                      id="isFood"
                      name="isFood"
                      value={formik.values.isFood}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Food"
                />
              </Grid>
              <Grid item>
                {' '}
                <FormControlLabel
                  control={
                    <Checkbox // checked={jason}
                      color="primary"
                      disabled={selectedJob ? true : false}
                      checked={formik.values.isAccomodation}
                      id="isAccomodation"
                      name="isAccomodation"
                      value={formik.values.isAccomodation}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Accomodation"
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                JOB TYPE
              </Typography>{' '}
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  // defaultValue={selectedJob?.isNMR}
                  value={formik.values?.isNMR}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'isNMR',
                      e.target.value == 'true' ? true : false
                    );
                    console.log('Check', e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                      // onChange={(e) =>
                      // formik.setFieldValue("isPrivate", false)
                      // }
                      />
                    }
                    label="NMR"
                  />
                  <FormControlLabel
                    // value={false}
                    value={false}
                    control={
                      <Radio
                      // onChange={(e) =>
                      // formik.setFieldValue("isPrivate", true)
                      // }
                      />
                    }
                    label="PRW"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="p"
                className={classes.commonStyles}
                style={{ marginBottom: '.5rem', marginTop: '.5rem' }}
              >
                {' '}
                Job Instructions
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.inputFieldGridItem}>
              <Typography
                variant="p"
                component="p"
                className={classes.audioVideoTitle}
                style={{ marginBottom: '5px' }}
              >
                Upload Video File
              </Typography>
              <div>
                <input
                  disabled={selectedJob ? true : false}
                  accept="video/*"
                  className={classes.input}
                  style={{ display: video && 'none' }}
                  // multiple
                  type="file"
                  id="video"
                  name="video"
                  value={formik.values.video}
                  // onChange={formik.handleChange}
                  onChange={handleVideo}
                  error={formik.touched.video && Boolean(formik.errors.video)}
                  helperText={formik.touched.video && formik.errors.video}
                />
                <label htmlFor="video">
                  {/* <Button
										fullWidth
										variant='contained'
										color='primary'
										component='span'
										startIcon={<AudiotrackIcon />}
									>
										Upload Audio
									</Button> */}
                  <div className={classes.audioVideoBoxes}>
                    <AttachmentIcon
                      fontSize="large"
                      className={classes.audioVideoBoxesIcon}
                    />
                    <p
                      style={{
                        color: video ? 'black' : 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className={video && 'dots'}
                    >
                      {video ? (
                        <>{video.name}</>
                      ) : !video && selectedMedia?.video?.fileName ? (
                        // <a
                        // 	href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_CLASSIFIED}${selectedMedia?.video?.url}`}
                        // 	target='_blank'
                        // 	style={{ fontFamily: 'Roboto' }}
                        // >
                        <p
                          style={{
                            color: 'rgba(173, 186, 195, 1)',
                            fontFamily: 'Roboto',
                          }}
                          className="dots"
                        >
                          {selectedMedia?.video?.fileName}
                        </p>
                      ) : (
                        // </a>
                        'Browse from gallery to attach video file'
                      )}
                    </p>
                    {video && (
                      <CloseIcon
                        style={{
                          color: video ? 'black' : 'rgba(173, 186, 195, 1)',
                        }}
                        onClick={() => setVideo(null)}
                      />
                    )}
                    <VideocamIcon
                      fontSize="large"
                      style={{
                        color: 'rgba(129, 183, 52, 1)',
                        marginRight: '5px',
                      }}
                    />
                  </div>
                  <p style={{ color: 'red' }}>
                    {formik.touched.audio && formik.errors.audio}
                  </p>
                </label>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="p"
                component="p"
                className={classes.audioVideoTitle}
                style={{ marginBottom: '5px' }}
              >
                Upload Audio File{' '}
              </Typography>
              <div>
                <input
                  disabled={selectedJob ? true : false}
                  accept="audio/*"
                  className={classes.input}
                  // multiple

                  type="file"
                  id="audio"
                  name="audio"
                  value={formik.values.audio}
                  onChange={handleAudio}
                  error={formik.touched.audio && Boolean(formik.errors.audio)}
                  helperText={formik.touched.audio && formik.errors.audio}
                />
                <label htmlFor="audio">
                  {/* <Button
										fullWidth
										variant='contained'
										color='primary'
										component='span'
										startIcon={<VideoCallIcon />}
									>
										Upload Video
									</Button> */}
                  <div className={classes.audioVideoBoxes}>
                    <AttachmentIcon
                      fontSize="large"
                      className={classes.audioVideoBoxesIcon}
                    />
                    <p
                      style={{
                        color: audio ? 'black' : 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className="dots"
                    >
                      {audio ? (
                        audio.name
                      ) : !audio && selectedMedia?.audio?.fileName ? (
                        // <a
                        // 	href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_CLASSIFIED}${selectedMedia?.audio?.url}`}
                        // 	target='_blank'
                        // 	style={{ fontFamily: 'Roboto' }}
                        // >
                        <p
                          style={{
                            color: 'rgba(173, 186, 195, 1)',
                            fontFamily: 'Roboto',
                          }}
                          className="dots"
                        >
                          {selectedMedia?.audio?.fileName}
                        </p>
                      ) : (
                        // </a>
                        'Browse from gallery to attach audio file'
                      )}
                    </p>
                    {audio && (
                      <CloseIcon
                        style={{
                          color: video ? 'black' : 'rgba(173, 186, 195, 1)',
                        }}
                        onClick={() => setAudio(null)}
                      />
                    )}
                    <MicNoneIcon
                      fontSize="large"
                      style={{
                        color: 'rgba(129, 183, 52, 1)',
                        marginRight: '5px',
                      }}
                    />
                  </div>
                  <p style={{ color: 'red' }}>
                    {formik.touched.video && formik.errors.video}
                  </p>
                </label>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            style={{ marginTop: '1.5rem', marginBottom: 13 }}
          >
            {/* <Grid container justify="space-between" item xs={4}> */}
            <Grid item style={{ marginRight: '1em' }}>
              <Button
                // onClick={() => {
                // 	setShowCard(true);
                // 	setCreateNewProject(false);
                // }}
                disabled={loading}
                fullWidth
                className={classes.buttonGreen}
                style={{ marginRight: 15 }}
                type="submit"
                startIcon={<WorkIcon />}
              >
                {selectedJob ? 'Update' : 'Create'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonPurple}
                fullWidth
                onClick={() => {
                  if (selectedJob) {
                    return navigate('../detail');
                  }
                  navigate('..');
                  dispatch(selectJob(null));
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
export default CreateJob;
