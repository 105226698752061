import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EmploymentCard from 'src/components/Reports/EmploymentCard';
import FineCard from 'src/components/Reports/FIneCard';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getDARReport, getDLRReport, reportsReducer } from 'src/redux/reports';
import { downloadExcel } from 'react-export-table-to-excel';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import moment from 'moment';
import defaultProjectImage from '../../assests/Projects Icon.png';
import MusterRoll from 'src/components/Reports/MusterRool';
import DatePicker from 'react-datepicker';
import { breakString, getLoggedInUserData } from 'src/App';
import toast from 'react-hot-toast';
import EventNoteIcon from '@material-ui/icons/EventNote';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    position: 'relative',
  },
  overflow: { overflowX: 'hidden' },

  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  DARAndDLRColums: {
    color: '#80b634',
    fontSize: '11px',
    fontWeight: 'bold',
  },

  text: {
    color: '#888',
    fontWeight: 500,
  },
  container: {
    backgroundColor: '#fff',
    padding: '1.3rem 10px',
    boxShadow: '3px 3px 5px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    margin: '10px',
    cursor: 'pointer',
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    // textTransform: "capitalize",
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  textArea: {
    height: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    overflow: { overflowX: 'scroll' },
  },
}));
const Card = ({ classes, text, onClick, size = 3, styles }) => {
  return (
    <Grid
      xs={size}
      item
      container
      className={classes.container}
      onClick={onClick}
      justify="space-between"
      alignItems="center"
      style={styles}
    >
      <Typography className={classes.text}>{text}</Typography>
      <ArrowForwardIosIcon
        className={classes.text}
        style={{ fontSize: '15px' }}
      />
    </Grid>
  );
};

function Reports() {
  const [employmentCard, setEmploymentCard] = useState(false);
  const [fineCard, setFineCard] = useState(false);
  const [reportName, setReportName] = useState('Project Reports');
  const [DLR, setDLR] = useState(false);
  const [DAR, setDAR] = useState(false);
  const [currentProject, setCurrentProject] = useState('');
  const [classifiedProject, setClassifiedProject] = useState('');
  const [musterRoll, setMusterRoll] = useState(false);
  const [DLRDate, setDLRDate] = useState('');
  const [DARDate, setDARDate] = useState('');
  const [constructionBilling, setConstructionBilling] = useState(false);
  const [isProjectProgressReport, setIsProjectProgressReport] = useState(false);
  const [labourExpenseReport, setLabourExpenseReport] = useState(false);
  const [issueLog, setIssueLog] = useState(false);
  const [formT, setFormT] = useState(false);
  const [dateRangeDLR, setDateRangeDLR] = useState([null, null]);
  const [startDateDLR, endDateDLR] = dateRangeDLR;

  const [dateRangeDAR, setDateRangeDAR] = useState([null, null]);
  const [progressReport, setProgressReport] = useState(false);
  const [historicalReport, setHistoricalReport] = useState(false);

  const [startDateDAR, endDateDAR] = dateRangeDAR;

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  //! SELECTORS
  const { dlrList, darList, loading } = useSelector(reportsReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  //! LIFECYCLE
  useEffect(() => {
    if (projectClassificationList.length > 0) {
      setCurrentProject(projectClassificationList[0]);
    } else {
      dispatch(getProjectsClassificationList());
    }
  }, [projectClassificationList.length]);

  const classifyProject = (project) => {
    if (project) {
      setCurrentProject(project);
    } else {
      setCurrentProject(projectClassificationList[0]);
    }
  };
  //
  const handleDownloadExcelDAR = async () => {
    let resp = await dispatch(
      getDARReport(
        currentProject.projectId,
        0,
        true,
        moment(dateRangeDAR[0])
          .utcOffset(0, true)
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        moment(dateRangeDAR[1])
          .utcOffset(0, true)
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
      )
    );

    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (
        contentType !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return toast.error('Invalid file format. Expected Excel file.');
      }

      const fileData = new Blob([resp.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const fileURL = window.URL.createObjectURL(fileData);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `DAR.xlsx`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };

  const handleDownloadExcelDLR = async () => {
    let resp = await dispatch(
      getDLRReport(
        currentProject.projectId,
        0,
        true,
        moment(dateRangeDLR[0])
          .utcOffset(0, true)
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        moment(dateRangeDLR[1])
          .utcOffset(0, true)
          .format('YYYY-MM-DDTHH:mm:ss[Z]')
      )
    );

    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute('download', `DLR.xlsx`);

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };
  const handleDateRangeDLR = (update) => {
    setDateRangeDLR(update);

    const [start, end] = update;

    // setDLRDate(update);
    if (!start && !end) {
      return dispatch(
        getDLRReport(
          currentProject.projectId,
          0,
          false,
          moment().utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          moment().utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')
        )
      );
    }
    if (start && end) {
      dispatch(
        getDLRReport(
          currentProject.projectId,
          0,
          false,

          moment(start).utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          moment(end).utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')
        )
      );
    }
  };

  const handleDateRangeDAR = (update) => {
    setDateRangeDAR(update);
    const [start, end] = update;

    if (!start && !end) {
      return dispatch(
        getDARReport(
          currentProject.projectId,
          // LabourContractor?.userId,
          0,
          false,
          moment().utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          moment().utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')
        )
      );
    }
    if (start && end) {
      dispatch(
        getDARReport(
          currentProject.projectId,
          // LabourContractor?.userId,
          0,
          false,
          moment(start).utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
          moment(end).utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss[Z]')
        )
      );
    }
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Reports">
      {employmentCard ? (
        <EmploymentCard setEmploymentCard={setEmploymentCard} />
      ) : fineCard ? (
        <FineCard setFineCard={setFineCard} />
      ) : musterRoll ? (
        <MusterRoll
          historicalReport={historicalReport}
          setMusterRoll={setMusterRoll}
          isMonthPicker={true}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setMusterRoll(false);
            setHistoricalReport(false);
          }}
          isProjectProgressReport={isProjectProgressReport}
          labourExpenseReport={labourExpenseReport}
        />
      ) : constructionBilling ? (
        <MusterRoll
          // setMusterRoll={setConstructionBilling}
          constructionBilling={true}
          isMonthPicker={true}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setMusterRoll(false);
            setConstructionBilling(false);
          }}
        />
      ) : formT ? (
        <MusterRoll
          isFormT={formT}
          isMonthPicker={true}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setFormT(false);
            setConstructionBilling(false);
          }}
        />
      ) : issueLog ? (
        <MusterRoll
          isIssueLog={issueLog}
          isMonthPicker={true}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setIssueLog(false);
            setConstructionBilling(false);
          }}
        />
      ) : DLR ? (
        <MusterRoll
          DLR={DLR}
          isMonthPicker={false}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setIssueLog(false);
            setConstructionBilling(false);
            setDLR(false);
          }}
        />
      ) : DAR ? (
        <MusterRoll
          DAR={DAR}
          isMonthPicker={false}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setIssueLog(false);
            setConstructionBilling(false);
            setDAR(false);
          }}
        />
      ) : progressReport ? (
        <MusterRoll
          DAR={DAR}
          isMonthPicker={false}
          handleBack={() => {
            setLabourExpenseReport(false);
            setIsProjectProgressReport(false);
            setIssueLog(false);
            setConstructionBilling(false);
            setDAR(false);
            setProgressReport(false);
          }}
          isProgressReport={progressReport}
        />
      ) : (
        <Grid
          container
          xs={12}
          style={{ padding: '1rem' }}
          // justify='space-between'
        >
          <Grid
            container
            style={{
              padding: '0 1rem 2rem 1rem',
            }}
          >
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => setReportName('Project Reports')}
                fullWidth
                className={
                  reportName === 'Project Reports'
                    ? classes.buttonGreen
                    : classes.buttonPurpleOpacity
                }
              >
                Project Reports
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
              <Button
                variant="outlined"
                onClick={() => setReportName('Compliance Reports')}
                fullWidth
                className={
                  reportName === 'Compliance Reports'
                    ? classes.buttonGreen
                    : classes.buttonPurpleOpacity
                }
              >
                Compliance Reports
              </Button>
            </Grid>
          </Grid>
          {reportName === 'Project Reports' ? (
            <>
              <Grid container>
                <FilterProjectComponent
                  projectClassificationList={projectClassificationList}
                  src={
                    !classifiedProject[0]?.url
                      ? defaultProjectImage
                      : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                  }
                  value={currentProject}
                  onChange={classifyProject}
                />
              </Grid>

              <Card
                text="Construction Billing Forecast"
                classes={classes}
                onClick={() => setConstructionBilling(true)}
              />
              <Card
                text="Labour Expense Report"
                classes={classes}
                onClick={() => {
                  setLabourExpenseReport(true);
                  setMusterRoll(true);
                }}
              />
              {/* <Card text="Projected Labour Expenses" classes={classes} /> */}
              <Card
                text="Project Progress Report"
                classes={classes}
                onClick={() => {
                  setIsProjectProgressReport(true);
                  setMusterRoll(true);
                }}
              />
              <Card
                text="Quality Report"
                classes={classes}
                onClick={() => setMusterRoll(true)}
              />
              <Card
                text="Variation Report"
                classes={classes}
                onClick={() => setMusterRoll(true)}
              />
              {/* <Card text="Goals Achieved" classes={classes} /> */}
              <Card
                text="Issue Log"
                classes={classes}
                onClick={() => {
                  setIssueLog(true);
                  // setMusterRoll(true);
                }}
              />
              <Card
                text="Daily Labour Report"
                classes={classes}
                onClick={() => {
                  handleDateRangeDLR([null, null]);
                  setDLR(true);
                }}
              />
              <Card
                text="Daily Attendance Report"
                onClick={() => {
                  handleDateRangeDAR([null, null]);
                  setDAR(true);
                }}
                classes={classes}
              />
              <Card
                classes={classes}
                text="Historical Attendance Report"
                onClick={() => {
                  setMusterRoll(true);
                  setHistoricalReport(true);
                }}
              />
              <Card
                classes={classes}
                text="Daily Progress Report"
                onClick={() => setProgressReport(true)}
              />
              {/* <Card text="Muster Roll" classes={classes} /> */}
              {/* <Card text="Manpower Needs Forecast" classes={classes} /> */}
            </>
          ) : (
            reportName === 'Compliance Reports' && (
              <>
                <Card
                  classes={classes}
                  text="Form XIII Register of Workmen"
                  onClick={() => setEmploymentCard(true)}
                />
                <Card
                  classes={classes}
                  text="Form XXI Register of Fines"
                  onClick={() => setFineCard(true)}
                />
                <Card classes={classes} text="Form XIV Employment Card" />
                <Card classes={classes} text="CLRA Form V" />
                <Card classes={classes} text="CLRA Half Yearly Return" />
                <Card
                  classes={classes}
                  text="Form XII Register of Contractors"
                />
                <Card classes={classes} text="Form XIX Wages Slip" />
                <Card classes={classes} text="Form XV Service Certificate" />
                <Card classes={classes} text="Form XVI Muster Roll" />
                <Card classes={classes} text="Form XVII Register of Wages" />
                <Card classes={classes} text="Form XX Register of Deductions" />
                <Card classes={classes} text="Form XXII Register of Advances" />
                <Card
                  classes={classes}
                  text="Form T Combined Muster Roll"
                  onClick={() => setFormT(true)}
                />
              </>
            )
          )}
        </Grid>
      )}
    </Page>
  );
}

export default Reports;
