import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  OutlinedInput,
  Box,
  Chip,
  Select,
  Table,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { craeteUser, getUsers, pickAUser, usersReducer } from 'src/redux/users';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { getRoles, rolesReducer } from 'src/redux/roles';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddRoles from '../roles/add-roles';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { default as ReactSelect, components } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import AddRolesv2 from '../roles/add-rolesv2';

const useStyles = makeStyles((theme) => ({
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    FontFamily: 'Roboto',
    padding: '.6rem 1.4rem',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: '#81B734',
    },
  },

  buttonPurpleOpacity: {
    color: '#986FEF',
    padding: '.6rem 1.4rem',
    textTransform: 'capitalize',
    FontFamily: 'Roboto',

    borderRadius: '10px',
    border: 'none',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  textField: {
    paddingRight: '1rem',
  },
  commonStyles: {
    color: 'rgba(173, 186, 195, 1)',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '12px',

    marginBottom: '5px',
  },
  font: {
    fontFamily: 'Roboto',
  },
  table: {
    minWidth: 650,
  },

  [theme.breakpoints.down('sm')]: {
    textField: {
      paddingRight: '0',
    },
  },
}));
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const CreateUser = (props) => {
  const [currentRole, setCurrentRole] = useState(0);
  const [functionRef, setFunctionRef] = useState(null);
  const [optionSelected, setOptionSelected] = useState(null);
  const [randomPass, setRandomPass] = useState('');

  //! PROPS

  // 	//! ROLES for loggedin user

  // 	//! LIFE CYCLES
  function generateRandomPassword() {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$%?';
    let password = '';

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  }

  let selectedProjectsForEdit = [];
  useEffect(() => {
    setRandomPass(generateRandomPassword());
    projectClassificationList.filter((item) => {
      user?.userProjects.map((pro) => {
        if (pro.projectId === item.projectId) {
          selectedProjectsForEdit.push({
            value: item.projectId,
            label: item.name,
          });
        }
      });
    });
    setOptionSelected(selectedProjectsForEdit);
  }, []);

  //! INSTANCES
  const { addToast } = useToasts();
  let dispatch = useDispatch();
  const classes = useStyles();
  // const location = useLocation();
  let navigate = useNavigate();

  //! SELECTORS
  const { selectedUser: user } = useSelector(usersReducer);
  const { loading, rolesList, res } = useSelector(rolesReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#81B734' : '#F4F6F8',
      color: '#222',
      // zIndex: 9000,
      zIndex: 9999,
      ':hover': {
        // backgroundColor: "rgba(161, 120, 242, 0.20)",
        backgroundColor: '#81B734',
      },
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#986FEF',
      zIndex: 9999,
    }),
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: "r",
      height: '56px',
      // zIndex: 9000
    }),
    // menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  const getCalls = () => {
    dispatch(getRoles());

    if (res?.status === 200) {
      formik.submitForm();
    }
  };
  useEffect(() => {
    getCalls();
    if (user) {
      const index = rolesList.findIndex((item) => item.roleId === user.roleId);
      setCurrentRole(index);
    }
  }, [currentRole, res]);

  //! METHODS

  //! CREATE USER FORM
  const validationSchema = yup.object({
    fullName: yup.string('Enter Full Name').required('Full Name is required'),
    emailAddress: yup
      .string('Enter email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    userId: yup.string('Enter userId').required('UserId is required'),
    // username: yup.string("Enter username").required("Username is required"),
    // roleId: yup.number('Enter role').required('Role is required'),
  });
  const formik = useFormik({
    initialValues: {
      fullName: user?.fullName ?? '',
      emailAddress: user?.emailAddress ?? '',
      password: user?.password ?? randomPass,
      userId: user?.userId ?? 0,
      // username: user?.username ?? "",
      // userTypeId: user?.userTypeId ?? '',
      roleId: user?.roleId ?? 0,
      projectIds: user?.userProjects?.length ? selectedProjectsForEdit : [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { password, ...rest } = values;
      let actualObject = user ? rest : values;

      if (values.roleId === 0) {
        let projectArray = [];

        values.projectIds.map((project) => {
          projectArray.push(project.value);
        });
        let resp = await dispatch(
          craeteUser({
            ...actualObject,
            projectIds: projectArray,
            roleId: rolesList[rolesList.length - 1].roleId + 1,
            username: values.emailAddress,
            // projectIds: user?.projectIds?.length ? user?.projectIds : [],
          }),
        );

        if (resp.status === 400) {
          return addToast(resp.data.error, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        if (resp.message) {
          return addToast(resp.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }

        if (resp?.status === 200) {
          if (user) {
            dispatch(pickAUser(null));
          }
          formik.resetForm();
          dispatch(getUsers());
          navigate('..');
          return addToast('User Added Successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        if (resp?.response?.data) {
          return addToast(resp.response.data, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else {
        let projectArray = [];
        values.projectIds.map((project) => {
          projectArray.push(project.value);
        });

        let resp = await dispatch(
          craeteUser({
            ...actualObject,
            projectIds: projectArray,
            username: values.emailAddress,
          }),
        );
        if (resp.status === 400) {
          return addToast(resp.data.error, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        if (resp.status === 200) {
          if (user) {
            dispatch(pickAUser(null));
          }
          formik.resetForm();
          dispatch(getUsers());
          navigate('..');
          return addToast('Saved Successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        if (resp.message) {
          return addToast(resp.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        if (resp?.response?.data) {
          return addToast(resp.response.data, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
      // if (selectedUser) {
      // 	let resp = await dispatch(craeteUser(values));
      // 	if (resp.status === 200) {
      // 		setModals({ ...modals, addUserModal: false });
      // 		formik.resetForm();
      // 		dispatch(getUsers());
      // 		return addToast('Updated Successfully', {
      // 			appearance: 'success',
      // 			autoDismiss: true,
      // 		});
      // 	}
      // 	if (resp?.response?.data) {
      // 		return addToast(resp.response.data, {
      // 			appearance: 'error',
      // 			autoDismiss: true,
      // 		});
      // 	}
      // }
    },
  });

  const handleChange = (selected) => {
    setOptionSelected(selected);
    formik.setFieldValue('projectIds', selected);
  };

  const fetchFunctions = (ref) => {
    setFunctionRef(ref);
  };

  const handleSubmitUser = () => {
    if (formik.values.roleId === 0) {
      functionRef.handleSubmit();
    } else {
      formik.submitForm();
    }
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  let data = [];
  if (user) {
    user?.userProjects?.map((item) =>
      projectClassificationList.filter((pro) => {
        if (item.projectId === pro.projectId) {
          data.push(pro);
          // return pro;
        }
      }),
    );
  }

  return (
    <Container style={{ position: 'relative' }}>
      <>
        {loading && (
          <div
            style={{
              textAlign: 'center',
              position: 'absolute',
              top: '50%',
              left: '45%',
            }}
          >
            <CircularProgress style={{ color: '#B2FF59' }} />
          </div>
        )}
        <Grid container>
          <Grid item container xs={12}>
            <Typography
              component="h3"
              variant="h4"
              // className={classes.commonStyles}
            >
              {user?.fullName ? 'Update User' : 'Create New User'}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container xs={12} style={{ marginTop: '3rem' }}>
            <Grid item md={6} xs={12} className={classes.textField}>
              <Typography className={classes.commonStyles}>
                Full Name
              </Typography>
              <TextField
                fullWidth
                id="fullName"
                name="fullName"
                type="text"
                variant="outlined"
                placeholder="Enter Name"
                // defaultValue={selectedUser?.emailAddress}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
                style={{ marginBottom: '1rem' }}
                inputProps={{ style: { fontFamily: 'Roboto' } }}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <Typography className={classes.commonStyles}>Username</Typography>
              <TextField
                fullWidth
                id="username"
                name="username"
                variant="outlined"
                placeholder="Enter Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
                style={{ marginBottom: "1rem" }}
                inputProps={{ style: { fontFamily: "Roboto" } }}
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <Typography className={classes.commonStyles}>Email </Typography>
              <TextField
                fullWidth
                id="emailAddress"
                name="emailAddress"
                type="email"
                variant="outlined"
                placeholder="Enter Email"
                // defaultValue={selectedUser?.emailAddress}
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                error={
                  formik.touched.emailAddress &&
                  Boolean(formik.errors.emailAddress)
                }
                helperText={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
                style={{ marginBottom: '1rem' }}
                inputProps={{ style: { fontFamily: 'Roboto' } }}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ marginBottom: '10px' }}>
              <Typography className={classes.commonStyles}>
                Phone Number{' '}
              </Typography>

              <Grid item style={{ height: '40px' }}>
                <PhoneInput
                  style={{
                    width: '100%',
                    height: '100%',
                    // border: '1px solid #999',
                    backgroundColor: 'none',
                    border: `1px solid ${
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? 'red'
                        : '#999'
                    }`,
                  }}
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(value) =>
                    formik.setFieldValue('phoneNumber', value)
                  }
                  country={'in'}
                  onlyCountries={['in']}
                  countryCodeEditable={false}

                  // value={this.state.phone}
                  // onChange={phone => this.setState({ phone })}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <small
                    style={{
                      fontSize: '12px',
                      margin: '0 14px',
                      color: 'red',
                    }}
                  >
                    Contact Number is required
                  </small>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '16px' }}>
              <Typography className={classes.commonStyles}>Project</Typography>

              <span
                class="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please select account(s)"
              >
                <ReactSelect
                  options={projectClassificationList?.map((item) => ({
                    value: item.projectId,
                    label: item.name,
                  }))}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={handleChange}
                  allowSelectAll={true}
                  value={optionSelected}
                  styles={customStyles}
                />
              </span>
            </Grid>

            <Grid item container xs={12}>
              <Grid
                container
                xs={12}
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: '10px' }}
              >
                <Typography className={classes.commonStyles}>
                  User Role
                </Typography>
              </Grid>

              <FormControl fullWidth>
                <Autocomplete
                  // autoSelect

                  onInputChange={(option, value) => {}}
                  freeSolo
                  id="roleId"
                  name="roleId"
                  type="roleId"
                  defaultValue={
                    user
                      ? rolesList[
                          rolesList.findIndex(
                            (item) => item.roleId === user?.roleId,
                          )
                        ]
                      : ''
                  }
                  options={rolesList.filter((item) => item.roleId !== 3)}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('roleId', newValue?.roleId ?? 0);
                    // 											if (newValue?.name) {
                    // 												scopes.forEach((scope) => {
                    // 													for (let feature of newValue.roleFeatureSets) {
                    // 														if (
                    // 															feature.featureSet.name
                    // 																.toLowerCase()
                    // 																.includes(scope)
                    // 														) {
                    // 															if (routesMap.has(scope))
                    // 																routesMap.get(scope).push(feature.featureSet);
                    // 															else routesMap.set(scope, [feature.featureSet]);
                    // 														}
                    // 													}
                    // 												});
                    //
                    // 												// if (routesMap.size) {
                    // 												routesMap.forEach((value, key) => {
                    // 													temp.push({ children: value, scope: key });
                    // 												});
                    // 												// }
                    // 												setListOfFeatures(temp);
                    // 											} else {
                    // 												setListOfFeatures([]);
                    // 											}
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Existing Role"
                      variant="outlined"
                      style={{ fontFamily: 'Roboto' }}
                      className={classes.projectInput}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {formik.values.roleId === 0 ? (
              <>
                <h3 style={{ margin: '15px 0' }}>OR</h3>
                <AddRolesv2 fetchFunction={fetchFunctions} />
              </>
            ) : (
              <>
                <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Route Name</TableCell>
                        <TableCell align="right">Route</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rolesList
                        ?.filter(
                          (item) => item.roleId === formik.values.roleId,
                        )[0]
                        ?.roleFeatureSets?.map((row) => (
                          <TableRow key={row.featureSetId}>
                            <TableCell component="th" scope="row">
                              {row.featureSet.name}
                            </TableCell>
                            <TableCell align="right">
                              {row.featureSet.route}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <Grid
              item
              container
              xs={12}
              justify="flex-end"
              style={{ margin: '3rem 0' }}
            >
              <Button
                onClick={handleSubmitUser}
                className={classes.buttonGreen}
                style={{ marginRight: '1rem' }}
              >
                {!user ? 'Submit' : 'Update'}
              </Button>
              <Button
                className={classes.buttonPurpleOpacity}
                onClick={() => {
                  if (user) {
                    return navigate('../detail');
                  }
                  navigate('..');
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </Container>
  );
};

export default CreateUser;
