import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import ProjectViewListItem from './projectListViewItem';
import { projectListView } from './theme.project';
import SearchInput from 'src/components/SearchInput';
import { useEffect } from 'react';
// import FilterProjectComponent from "src/components/FilterProjectComponent";
import { useDispatch, useSelector } from 'react-redux';
// import defaultProjectImage from "../../assests/Projects Icon.png";

import {
  getProjects,
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
// import { Autocomplete } from "@material-ui/lab";
import { Link } from 'react-router-dom';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import AddIcon from '@material-ui/icons/Add';
import useAccessPerView from 'src/common/Access';

const RenderProjectList = ({
  projectList,
  loading,
  usersList,
  setSelectedProject,
}) => {
  const access = useAccessPerView('PROJECT_INFORMATION');
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
  });
  const [filteredArray, setFilteredArray] = useState(projectList);
  const [LabourContractor, setLabourContractor] = useState('');

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem('userDetails')),
  );

  //! INSTANCES
  const classes = projectListView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectList?.length) {
      setFilteredArray([...projectList]);
    }
  }, [projectList]);

  //! SELCTORS
  const { projectClassificationList } = useSelector(projectReducer);

  //! METHODS

  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      let newFilter = projectList.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });

      setFilteredArray(newFilter);
    }

    if (!value) {
      // if (filters.sortBy) {
      // 	handleFilters({ target: { value: filters.sortBy } });
      // }
      // if (dateRange[1] !== null) {
      // 	return handleDateRange(dateRange);
      // }
      return setFilteredArray([...projectList]);
    }
  };

  const handleLabourContractor = (newValue) => {
    setLabourContractor(newValue);

    if (!newValue) {
      return dispatch(getProjects());
    }
    dispatch(getProjects(newValue?.userId));
  };

  const ProjectNavbar = newFunction(
    userData,
    LabourContractor,
    handleLabourContractor,
    setLabourContractor,
    access,
    classes,
  );

  return (
    <>
      <Grid container xs={12} justify="space-between">
        <ProjectNavbar />

        <Grid
          item
          md={12}
          xs={12}
          className={classes.marginTop}
          style={{ padding: '0 1rem' }}
        >
          {userData?.user?.leadTypeId !== 'LabourContractor' && (
            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              alignItems="center"
              // style={ {  } }
            >
              <Grid item style={{ paddingRight: '10px' }}>
                <small>Search:</small>
              </Grid>
              <Grid item md={4} xs={9} style={{ position: 'relative' }}>
                <SearchInput
                  placeholder="Please type project name here to link "
                  search={filters.search}
                  handleSearch={handleSearch}
                  // className='font12'
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        // xs={12}
        // justify='space-between'
        // align='center'
        style={{ minHeight: '79vh' }}
      >
        {loading ? (
          <Grid container justify="center">
            <CircularProgress style={{ color: '#81B734' }} />
          </Grid>
        ) : filteredArray?.length === 0 ? (
          <Grid container justify="center">
            No Project Found!
          </Grid>
        ) : (
          filteredArray?.map((project) => (
            <Grid
              key={project?.projectId}
              item
              md={4}
              xs={12}
              sm={12}
              lg={4}
              style={{ padding: '1rem' }}
            >
              <ProjectViewListItem
                classes={classes}
                project={project}
                usersList={usersList}
                key={project?.projectId}
                setSelectedProject={setSelectedProject}
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};
export default RenderProjectList;
function newFunction(
  userData,
  LabourContractor,
  handleLabourContractor,
  setLabourContractor,
  access,
  classes,
) {
  const { isEditMode } = access;
  return () => {
    return (
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        style={{
          padding: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Grid
          container
          item
          md={5}
          xs={12}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={3}>
            <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
              Projects
            </Typography>
          </Grid>
          {userData?.user.leadTypeId !== 'LabourContractor' && (
            <Grid item md={9} xs={7}>
              <FilterByLabourContractorComponent
                value={LabourContractor}
                onChange={handleLabourContractor}
                setInitialUser={(user) => setLabourContractor(user)}
              />
            </Grid>
          )}
        </Grid>
        {/* <Grid item xs={6} /> */}
        <Grid item md={3} xs={12} className={classes.marginTop}>
          {isEditMode && (
            <Link to="create">
              <Button
                fullWidth
                startIcon={<AddIcon />}
                // style={{ margin: '0 2rem' }}
                className={classes.buttonPurpleOpacity}
              >
                Create New Project
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    );
  };
}
