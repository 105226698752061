import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import useAccessPerView from 'src/common/Access';

const TABLE_COLUMNS = [
  { label: 'COST CODE', width: '5%' },
  { label: 'TITLE', width: '5%' },
  { label: 'DESCRIPTION', width: '5%' },
  { label: 'UOW', width: '5%' },
  { label: 'QUANTITY', width: '5%' },
  { label: 'RATE', width: '5%' },
  { label: 'AMOUNT', width: '5%' },
  { label: 'ACTUAL QUANTITY', width: '5%' },
  { label: 'ACTUAL AMOUNT', width: '5%' },
  { label: '% PERCENTAGE', width: '5%' },
  { label: 'ACTIONS', width: '5%' },
];

export const DynamicCell = (props) => {
  const { value, fontSize } = props;
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);

  const renderActionLabel = () => {
    if (showMoreEnabled) {
      return 'show less';
    }
    return 'show more';
  };

  const handleToggle = (event) => {
    event.stopPropagation();
    setShowMoreEnabled((prev) => !prev);
  };

  const renderValue = () => {
    if (showMoreEnabled) {
      return value;
    }
    return value?.split(' ')?.slice(0, 3).join(' ');
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      justify={showMoreEnabled ? 'space-between' : 'left'}
      style={{ gap: 5, flexWrap: 'nowrap' }}
    >
      <Typography
        style={{
          fontSize: 13,
          overflow: 'auto',
          maxHeight: 50,
          color: '#000000',
          fontWeight: 500,
        }}
        align="left"
      >
        {renderValue()}
      </Typography>
      <small
        style={{
          color: 'blue',
          cursor: 'pointer',
          fontSize: fontSize ?? 'auto',
          textWrap: 'nowrap',
        }}
        onClick={handleToggle}
      >
        {value.split(' ').length > 4 && renderActionLabel()}
      </small>
    </Grid>
  );
};

const Row = (props) => {
  const { classes, index, item, handleEdit } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { isViewMode } = useAccessPerView('CHANGE_MANAGEMENT');

  const renderIcon = () => {
    if (item.descriptions.length <= 1) {
      return <></>;
    }
    const toggleExpand = () => setIsExpanded((prev) => !prev);
    if (isExpanded) {
      return (
        <IconButton onClick={toggleExpand}>
          <ExpandLessIcon color="black" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleExpand}>
        <ExpandMoreIcon color="inherit" />
      </IconButton>
    );
  };

  const renderACONumber = () => {
    //console.log(item);
    //console.log(item?.descriptions[0]);
    //console.log(item?.descriptions[0]?.boqCode);
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <Typography
          style={{
            textWrap: 'nowrap',
            color: '#000000',
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          {item?.descriptions[0]?.boqCode}
        </Typography>
        {renderIcon()}
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <DynamicCell value={item.title} />
        {renderIcon()}
      </Grid>
    );
  };

  const getValueInLakhsString = (value) => {
    if (value < 100000) {
      return `₹ ${value}`;
    }

    const totalCostInLakhs = value / 100000;
    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const renderRow = () => {
    return (
      <TableRow>
        <TableCell align="center">{renderACONumber()}</TableCell>
        <TableCell style={{ fontSize: 11 }}>{renderTitle()}</TableCell>
        <TableCell align="center" style={{ fontSize: 11 }}>
          <Grid
            item
            container
            alignItems="center"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={item.descriptions[0].description} />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].unitCode}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`₹ ${item.descriptions[0].rate}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(item.descriptions[0].amount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].actualQuantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(item.descriptions[0].actualAmount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${item.descriptions[0].percentage.toFixed(1)}%`}
        </TableCell>
        <TableCell style={{ textWrap: 'nowrap', fontSize: 11 }} align="center">
          {!isViewMode && (
            <Button
              onClick={() => handleEdit(item)}
              className={classes.buttonPurpleOpacity}
            >
              Change Request
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderSubRows = (description) => {
    const height = isExpanded ? 40 : 0;
    return (
      <TableRow
        style={{
          transition: 'height 0.3s ease',
          height,
          visibility: isExpanded ? 'visible' : 'collapse',
        }}
      >
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          {description.boqCode}
        </TableCell>
        <TableCell />
        <TableCell
          align="center"
          style={{
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            style={{
              flexWrap: 'nowrap',
              fontSize: 13,
              color: '#000000',
              fontWeight: 500,
            }}
          >
            <DynamicCell value={description.description} />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.unitCode}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`₹ ${description.rate}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(description.amount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {description.actualQuantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${getValueInLakhsString(description.actualAmount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {`${description.percentage.toFixed(1)}%`}
        </TableCell>
        <TableCell style={{ textWrap: 'nowrap', fontSize: 11 }} align="center">
          {!isViewMode && (
            <Button className={classes.buttonPurpleOpacity}>
              Change Request
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {renderRow()}
      {item.descriptions
        .slice(1)
        .map((description) => renderSubRows(description))}
    </>
  );
};

const SOWTable = (props) => {
  const { classes, handleEdit } = props;
  const noWrapColumns = ['WORK ORDER NO', 'COMPLETION %'];
  return (
    <Table>
      <TableHead style={{ padding: '0px 8px' }}>
        <TableRow style={{ borderTop: '1px solid  rgba(1,1,1,0.05)' }}>
          {TABLE_COLUMNS.map((column) => {
            return (
              <TableCell
                style={{
                  color: '#B2BEC7',
                  fontSize: 10,
                  padding: '7px',
                  width: column.width,
                  textWrap: noWrapColumns.includes(column.label)
                    ? 'nowrap'
                    : 'balance',
                  lineHeight: '11px',
                }}
                align="center"
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'hidden' }}>
        {props.boqItem?.titles?.map((item, index) => (
          <Row
            index={index}
            item={item}
            classes={classes}
            handleEdit={handleEdit}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SOWTable;
