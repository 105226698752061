import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';
import moment from 'moment';

const initialState = {
  loading: false,
  error: null,
  darList: null,
  dlrList: null,
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    gettingdrl(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingdrlSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.dlrList = action.payload;
    },
    gettingdrlFailure(state, action) {
      state.loading = false;
      state.dlrList = null;
      state.error = action.payload;
    },
    gettingMusterRollData(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingMusterRollDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    gettingMusterRollDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingdar(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingdarSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.darList = action.payload;
    },
    gettingdarFailure(state, action) {
      state.loading = false;
      state.darList = null;
      state.error = action.payload;
    },
  },
});
const {
  gettingdrl,
  gettingdrlSuccess,
  gettingdrlFailure,
  gettingdar,
  gettingdarSuccess,
  gettingdarFailure,
  gettingMusterRollData,
  gettingMusterRollDataSuccess,
  gettingMusterRollDataFailure,
} = slice.actions;

export const reportsReducer = (state) => state.reports;

export const getDLRReport =
  (
    projectId = 0,
    createdBy = 0,
    isDownload = false,
    startDate = moment.utc().format(),
    endDate = moment.utc().format()
  ) =>
  async (dispatch) => {
    try {
      const options = {
        headers: {
          // Add any other headers you need
        },
      };

      if (isDownload) {
        options.responseType = 'blob'; // Treat response as binary data
        options.headers['Content-Type'] =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else {
        dispatch(gettingdrl());
      }

      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/dailylabour?projectId=${projectId}&createdBy=${createdBy}&startDate=${startDate}&endDate=${endDate}&isDownload=${isDownload}`,
        options
      );
      if (isDownload) return response;

      if (response.status === 200) {
        dispatch(gettingdrlSuccess(response?.data));
      } else {
        dispatch(gettingdrlFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingdrlFailure('Something went wrong while getting DLR report!')
      );
    }
  };
export const getDARReport =
  (
    projectId = 0,
    createdBy = 0,
    isDownload = false,
    startDate = moment.utc().format(),
    endDate = moment.utc().format()
  ) =>
  async (dispatch) => {
    try {
      const options = {
        headers: {
          // Add any other headers you need
        },
      };

      if (isDownload) {
        options.responseType = 'blob'; // Treat response as binary data
        options.headers['Content-Type'] =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else {
        dispatch(gettingdar());
      }

      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/dailyattendance?projectId=${projectId}&createdBy=${createdBy}&startDate=${startDate}&endDate=${endDate}&isDownload=${isDownload}`,
        options
      );
      if (isDownload) return response;

      if (response.status === 200) {
        dispatch(gettingdarSuccess(response?.data));
      } else {
        dispatch(gettingdarFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingdarFailure('Something went wrong while getting DLR report!')
      );
    }
  };

export const getMusterRollData =
  (projectId = 0, startDate, endDate) =>
  async (dispatch) => {
    dispatch(gettingMusterRollData());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/formt?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}`,
        {
          responseType: 'blob', // Treat response as binary data
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // Add any other headers you need
          },
        }
      );
      if (response.status === 200) {
        dispatch(gettingMusterRollDataSuccess(response?.data));
      } else {
        dispatch(gettingMusterRollDataFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingMusterRollDataFailure(
          'Something went wrong while getting Muster roll report!'
        )
      );
    }
  };

export const getConstructionBilling =
  (projectId = 0, month) =>
  async (dispatch) => {
    dispatch(gettingMusterRollData());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/billingforecast?projectId=${projectId}&reportingPeriod=${month}`,
        {
          responseType: 'blob', // Specify the response type as a blob
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        }
      );
      if (response.status === 200) {
        dispatch(gettingMusterRollDataSuccess(response?.data));
      } else {
        dispatch(gettingMusterRollDataFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingMusterRollDataFailure(
          'Something went wrong while getting Muster roll report!'
        )
      );
    }
  };

export const getProjectProgressReport =
  (projectId = 0, formData, toDate) =>
  async (dispatch) => {
    dispatch(gettingMusterRollData());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/projectprogress?projectId=${projectId}&fromDate=${formData}&toDate=${toDate}`,
        {
          responseType: 'blob', // Treat response as binary data
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // Add any other headers you need
          },
        }
      );
      if (response.status === 200) {
        dispatch(gettingMusterRollDataSuccess(response?.data));
      } else {
        dispatch(gettingMusterRollDataFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingMusterRollDataFailure(
          'Something went wrong while getting Muster roll report!'
        )
      );
    }
  };
export const getLabourExpense =
  (projectId = 0, month) =>
  async (dispatch) => {
    dispatch(gettingMusterRollData());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/labourexpense?projectId=${projectId}&reportingPeriod=${month}`,
        {
          responseType: 'blob', // Treat response as binary data
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // Add any other headers you need
          },
        }
      );
      if (response.status === 200) {
        // dispatch(getConstructionBilling(response?.data));
        dispatch(gettingMusterRollDataSuccess());
      } else {
        dispatch(gettingMusterRollDataFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingMusterRollDataFailure(
          'Something went wrong while getting Muster roll report!'
        )
      );
    }
  };

export const getProgressReport =
  (projectId = 0, reportDate) =>
  async (dispatch) => {
    dispatch(gettingMusterRollData());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Reports/dailyprogress?projectId=${projectId}&reportDate=
${reportDate}`,
        {
          responseType: 'blob', // Treat response as binary data
          headers: {
            'Content-Type':
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // Add any other headers you need
          },
        }
      );
      if (response.status === 200) {
        dispatch(gettingMusterRollDataSuccess(response?.data));
      } else {
        dispatch(gettingMusterRollDataFailure(response?.error));
      }

      return response;
    } catch (e) {
      dispatch(
        gettingMusterRollDataFailure(
          'Something went wrong while getting Muster roll report!'
        )
      );
    }
  };

export default slice.reducer;
