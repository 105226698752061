import {
  Avatar,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import defaultProjectImage from '../../../assests/Union.png';
import FilterProjectComponent from 'src/components/FilterProjectComponent';

const ContractorLedgerHead = (props) => {
  const { classes } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const contractorOptions = [{ label: 'K2K Hill Apartments', value: 1 }];

  return (
    <Grid
      container
      style={{
        borderRadius: '10px 10px 0px 0px',
        margin: '0px 20px',
        marginTop: 5,
        width: 'auto',
        height: 70,
        backgroundColor: 'white',
        padding: '0px 20px',
      }}
      justify="space-between"
      alignItems="center"
    >
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        style={{ flexWrap: 'nowrap' }}
      >
        <Grid item style={{ marginRight: 10 }}>
          <Typography style={{ fontSize: 16, textWrap: 'nowrap' }}>
            Select Project
          </Typography>
        </Grid>
        <Grid container item alignItems="center">
          <Grid item style={{ maxWidth: '12%' }}>
            <img
              alt="Project"
              src={defaultProjectImage}
              className={classes.projectAvatar}
            />
          </Grid>
          <Grid item xs={9} sm={9}>
            <TextField
              select
              placeholder="Project"
              style={{ width: 170, marginLeft: 10 }}
            >
              {contractorOptions.map((option) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={4} justify="flex-end" gap={5}>
        <Button
          className={classes.buttonPurpleOpacity}
          style={{ textTransform: 'capitalize' }}
        >
          + Add New Contractor
        </Button>
        <Button
          className={classes.buttonPurpleOpacity}
          style={{ textTransform: 'capitalize', marginLeft: 10 }}
        >
          + Add New BOQ
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractorLedgerHead;
