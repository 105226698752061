import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  ListItem,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justify: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    color: '#687378',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(128, 183, 52, 0.18)',
      borderRadius: 10,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: '#3a3a3a',
    background: '#FFFFFF',
    borderRadius: 10,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: '#80b836',
    },
  },
}));

const NavItem = ({ className, href, icon: Icon, title, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      {title === 'Communication' || title === 'Rewards' ? (
        <Button
          title="coming soon.."
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
        </Button>
      ) : (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
          disabled={disabled}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
