import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const TABLE_COLUMNS = [
  { label: 'COST CODE	', width: '5%' },
  { label: 'TITLE', width: '5%' },
  // { label: 'COST CODE	', width: '5%' },
  { label: 'DESCRIPTION', width: '5%' },
  { label: 'UNIT (UOM)', width: '5%' },
  { label: 'QUANTITY', width: '5%' },
  { label: 'PROGRESS', width: '5%' },
  { label: 'twoColumn', width: '5%' },

  { label: 'FIELD NOTES', width: '5%' },
];

const DynamicCell = (props) => {
  const { value } = props;
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);

  const renderActionLabel = () => {
    if (showMoreEnabled) {
      return 'show less';
    }
    return 'show more';
  };

  const handleToggle = () => {
    setShowMoreEnabled((prev) => !prev);
  };

  const renderValue = () => {
    if (showMoreEnabled) {
      return value;
    }
    return value?.split(' ')?.slice(0, 3).join(' ');
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      justify={showMoreEnabled ? 'space-between' : 'left'}
      style={{ gap: 5, flexWrap: 'nowrap' }}
    >
      <Typography
        style={{
          fontSize: 13,
          overflow: 'auto',
          maxHeight: 50,
          color: '#000000',
          fontWeight: 500,
        }}
        align="left"
      >
        {renderValue()}
      </Typography>
      <small
        style={{ color: 'blue', cursor: 'pointer', textWrap: 'nowrap' }}
        onClick={handleToggle}
      >
        {value.split(' ').length > 4 && renderActionLabel()}
      </small>
    </Grid>
  );
};

const Row = (props) => {
  const { classes, index, item, hideActions, openActionModal, selectedList } =
    props;

  const [isExpanded, setIsExpanded] = useState(false);

  const renderIcon = () => {
    if (item?.descriptions?.length <= 1) {
      return <></>;
    }
    const toggleExpand = () => setIsExpanded((prev) => !prev);
    if (isExpanded) {
      return (
        <IconButton onClick={toggleExpand}>
          <ExpandLessIcon color="black" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleExpand}>
        <ExpandMoreIcon color="inherit" />
      </IconButton>
    );
  };

  const renderACONumber = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{
          flexWrap: 'nowrap',
          fontSize: 13,
          fontWeight: 500,
          color: '#000000',
        }}
      >
        <Typography
          style={{
            textWrap: 'nowrap',
            color: '#000000',
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          {item?.descriptions[0]?.boqCode}
        </Typography>
        {renderIcon()}
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <DynamicCell value={item.title} />
        {renderIcon()}
      </Grid>
    );
  };

  const getValueInLakhsString = (value) => {
    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };
  const SummaryPercentageBar = (props) => {
    const { percentage, billingCost, measurementLeft } = props;

    const animatePercentageValue = (div) => {
      if (div) {
        setTimeout(() => {
          div.style.maxWidth = `${100}%`;
        }, 300);
      }
    };

    function LinearProgressWithLabel(props) {
      return (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              {...props}
              className={classes.progressBar}
              sx={{
                borderRadius: 10,
                '& .MuiLinearProgress-bar': {
                  bgcolor: 'red', // Change to any color you want
                },
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Grid container>
        <Tooltip
          title={
            <Grid container direction="column">
              <Grid item>
                <span style={{ fontWeight: 900 }}>{measurementLeft} </span>
                qty left
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 900 }}>₹{billingCost} Lakhs </span>
                Cost
              </Grid>
            </Grid>
          }
          placement="top"
        >
          <div style={{ width: '100%' }}>
            <LinearProgressWithLabel value={percentage} />
          </div>
        </Tooltip>
      </Grid>
    );
  };
  const renderRow = ({ selectedList }) => {
    return (
      <TableRow>
        <TableCell align="left">{renderACONumber()}</TableCell>
        <TableCell style={{ fontSize: 11 }}>{renderTitle()}</TableCell>
        {/* <TableCell
          align="left"
          style={{ fontSize: 13, fontWeight: 500, color: '#000000' }}
        >
          {item.descriptions[0]?.boqCode ?? 'N/A'}
        </TableCell> */}
        <TableCell
          align="left"
          style={{ fontSize: 13, color: '#000000', fontWeight: 500 }}
        >
          <Grid
            item
            container
            alignItems="left"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={item.descriptions[0].description} />
          </Grid>
        </TableCell>
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].unitName}
        </TableCell>
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          {item.descriptions[0].quantity}
        </TableCell>

        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
          }}
        >
          <SummaryPercentageBar
            percentage={item.descriptions[0].percentage ?? 'N/A'}
            billingCost={item.descriptions[0]?.billingCost ?? 'N/A'}
            measurementLeft={item.descriptions[0]?.measurementLeft ?? 'N/A'}
          />
        </TableCell>

        {!hideActions && (
          <TableCell
            align="left"
            style={{
              fontSize: '12px',
            }}
          >
            {item.descriptions[0]?.isQualityApproved ||
            item.descriptions[0].boqQualityRejectStatus ? (
              <Grid container justify="space-around" alignItems="center">
                {item.descriptions[0]?.isQualityApproved ? (
                  <DoneIcon
                    style={{
                      color: '#81B734',
                    }}
                  />
                ) : (
                  item.descriptions[0].boqQualityRejectStatus && (
                    <CloseIcon
                      style={{
                        color: 'tomato',
                      }}
                    />
                  )
                )}
                {item.descriptions[0]?.isMeasurementApproved ? (
                  <DoneIcon
                    style={{
                      color: '#81B734',
                    }}
                  />
                ) : (
                  item.descriptions[0].boqMeasurementRejectStatus && (
                    <CloseIcon
                      style={{
                        color: 'tomato',
                      }}
                    />
                  )
                )}
              </Grid>
            ) : (
              selectedList === 0 && (
                <Button
                  className={classes.buttonGreen}
                  style={{
                    color: '#81B734',
                    backgroundColor: 'hsl(87.5,46.15%,89.8%)',
                  }}
                  size="small"
                  fullWidth
                  onClick={() => openActionModal(item)}
                >
                  Verify
                </Button>
              )
            )}
          </TableCell>
        )}
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            color: '#000000',
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          {item.descriptions[0]?.boqProgressImage ? (
            <img
              src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.descriptions[0].boqProgressImage}`}
              alt="project url"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
              }}
            />
          ) : (
            'N/A'
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderSubRows = (description) => {
    const height = isExpanded ? 40 : 0;
    return (
      <TableRow
        style={{
          transition: 'height 0.3s ease',
          height,
          visibility: isExpanded ? 'visible' : 'collapse',
        }}
      >
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            color: '#000000',
            fontWeight: 500,
            textAlign: 'left',
          }}
        >
          {description.boqCode}
        </TableCell>
        <TableCell />
        {/* <TableCell
          align="left"
          style={{ fontSize: 13, fontWeight: 500, color: '#000000' }}
        >
          {item.descriptions[0]?.boqCode ?? 'N/A'}
        </TableCell> */}
        <TableCell
          align="left"
          style={{
            fontSize: 11,
          }}
        >
          <Grid
            item
            container
            alignItems="left"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={description.description} />
          </Grid>
        </TableCell>
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            fontWeight: 500,
            color: '#000000',
          }}
        >
          {description.unitName}
        </TableCell>
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            fontWeight: 500,
            color: '#000000',
          }}
        >
          {description.quantity}
        </TableCell>
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            fontWeight: 500,
            color: '#000000',
          }}
        >
          <SummaryPercentageBar
            percentage={description.percentage ?? 'N/A'}
            billingCost={description?.billingCost ?? 'N/A'}
            measurementLeft={description?.measurementLeft ?? 'N/A'}
          />
        </TableCell>
        {!hideActions && (
          <TableCell
            align="left"
            style={{
              fontSize: '12px',
              color: '#B2BEC7',
            }}
          >
            {item.descriptions[0]?.isQualityApproved ||
            item.descriptions[0].boqQualityRejectStatus ? (
              <Grid container justify="space-around" alignItems="center">
                {item.descriptions[0]?.isQualityApproved ? (
                  <DoneIcon
                    style={{
                      color: '#81B734',
                    }}
                  />
                ) : (
                  item.descriptions[0].boqQualityRejectStatus && (
                    <CloseIcon
                      style={{
                        color: 'tomato',
                      }}
                    />
                  )
                )}
                {item.descriptions[0]?.isMeasurementApproved ? (
                  <DoneIcon
                    style={{
                      color: '#81B734',
                    }}
                  />
                ) : (
                  item.descriptions[0].boqMeasurementRejectStatus && (
                    <CloseIcon
                      style={{
                        color: 'tomato',
                      }}
                    />
                  )
                )}
              </Grid>
            ) : (
              selectedList === 0 && (
                <Button
                  className={classes.buttonGreen}
                  style={{
                    color: '#81B734',
                    backgroundColor: 'hsl(87.5,46.15%,89.8%)',
                  }}
                  size="small"
                  fullWidth
                  onClick={() => openActionModal(item)}
                >
                  Verify
                </Button>
              )
            )}
          </TableCell>
        )}
        <TableCell
          align="left"
          style={{
            textWrap: 'nowrap',
            fontSize: 13,
            fontWeight: 500,
            color: '#000000',
          }}
        >
          {item.descriptions[0]?.boqProgressImage ? (
            <img
              src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${item.descriptions[0].boqProgressImage}`}
              alt="project url"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
              }}
            />
          ) : (
            'N/A'
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {renderRow(props)}
      {item.descriptions
        .slice(1)
        .map((description) => renderSubRows(description))}
    </>
  );
};

const SOWTable1 = (props) => {
  const { classes, handleEdit, hideActions } = props;
  const noWrapColumns = ['WORK ORDER NO', 'COMPLETION %'];
  return (
    <Table>
      <TableHead style={{ padding: '0px 8px' }}>
        <TableRow style={{ borderTop: '1px solid  rgba(1,1,1,0.05)' }}>
          {TABLE_COLUMNS.map((column) => {
            if (column.label === 'twoColumn' && !hideActions) {
              return (
                <TableCell
                  align="left"
                  width="8%"
                  style={{
                    color: '#B2BEC7',
                    fontSize: 10,
                    padding: '7px',
                    width: column.width,
                    textWrap: noWrapColumns.includes(column.label)
                      ? 'nowrap'
                      : 'balance',
                    lineHeight: '11px',
                  }}
                >
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>QUALITY</Grid> <Grid item>MEASUREMENT</Grid>
                  </Grid>
                </TableCell>
              );
            }
            return (
              <TableCell
                style={{
                  color: '#B2BEC7',
                  fontSize: 10,
                  padding: '7px',
                  width: column.width,
                  textWrap: noWrapColumns.includes(column.label)
                    ? 'nowrap'
                    : 'balance',
                  lineHeight: '11px',
                }}
                align="left"
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody style={{ overflow: 'hidden' }}>
        {props.boqItem?.titles.map((item, index) => (
          <Row
            index={index}
            item={item}
            classes={classes}
            handleEdit={handleEdit}
            {...props}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SOWTable1;
