import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import SOWTable from './SOWTable';

const BOQAccordian = (props) => {
  const { classes, boqItem } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const getValueInLakhsString = (value) => {
    if (value < 100000) {
      return `₹ ${value}`;
    }

    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const handleChange = () => {
    setIsExpanded((prev) => !prev);
  };

  const totalAmount = boqItem?.titles
    ?.map((ii) =>
      ii.descriptions?.reduce((total, current) => total + current.amount, 0),
    )
    ?.reduce((total, current) => total + current, 0);

  const totalPercentage = boqItem?.titles.reduce(
    (total, current) =>
      total + (current.totalAmount / current.totalAcutalAmount) * 100 ===
      Infinity
        ? 0
        : (current.totalAmount / current.totalAcutalAmount) * 100,
    0,
  );

  return (
    <Accordion
      style={{
        overflowY: 'auto',
        width: '100%',
        borderBottom: '1px solid #A1C3E5',
        borderTop: '1px solid #A1C3E5',
        margin: 0,
        minWidth: '100%',
      }}
      onChange={handleChange}
    >
      <AccordionSummary>
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            container
            style={{ flexWrap: 'nowrap', minWidth: 27 }}
            alignItems="center"
          >
            <Grid
              item
              container
              style={{
                backgroundColor: '#ffffff',
                borderRadius: 20,
                minWidth: 30,
                maxWidth: 30,
                height: 30,
                color: 'white',
                margin: '0px 10px',
              }}
              justify="center"
              alignItems="center"
            >
              {/* {boqIndex + 1} */}
            </Grid>
            <Grid style={{ minWidth: 70, flexWrap: 'nowrap' }} container item>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 13,
                  textWrap: 'nowrap',
                  flexGrow: 1,
                }}
              >
                {`WORKORDER # ${
                  boqItem?.workOrder === null ? 'N/A' : boqItem?.workOrder
                }`}
              </Typography>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          </Grid>
          <Grid item style={{ maxWidth: 240 }} container>
            <Grid item container xs={6}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                {getValueInLakhsString(totalAmount)}
              </Typography>
              <Typography
                style={{
                  color: '#ADBAC3',
                  fontSize: 10,
                  width: '100%',
                }}
              >
                ACTUAL AMOUNT
              </Typography>
            </Grid>
            <Grid item xs={6} container>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  width: '100%',
                }}
              >
                {`${totalPercentage.toFixed(2)}%`}
              </Typography>
              <Typography
                style={{
                  color: '#ADBAC3',
                  fontSize: 10,
                  width: '100%',
                }}
              >
                % PERCENTAGE
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ width: '100%', padding: '8px 0px 16px' }}>
        <SOWTable classes={classes} {...props} />
      </AccordionDetails>
    </Accordion>
  );
};

export default BOQAccordian;
