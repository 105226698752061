import users from "./users";
import workers from "./workers";
import contractors from "./contractors";
import projects from "./projects";
import jobs from "./jobs";
import attendance from "./attendance";
import lead from "./lead";
import count from "./count";
import payment from "./payment";
import features from "./features";
import roles from "./roles";
import reports from "./reports";
import fieldNote from "./fieldNote";
import productivity from "./productivity";

const rootReducer = {
  users,
  workers,
  contractors,
  projects,
  jobs,
  attendance,
  lead,
  count,
  payment,
  features,
  roles,
  reports,
  fieldNote,
  productivity,
};
export default rootReducer;
