import React from 'react';
import { TextField, useMediaQuery, useTheme } from '@material-ui/core';

const TextInput = (props) => {
  const { label, handleChange, startAdornment, endAdornment, variant } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const defaultInputProps = {
    disableUnderline: true,
    style: {
      borderRadius: '7px',
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  };
  const defaultLabelProps = {
    style: {
      color: '#8E99A8',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: isSmallScreen ? '170px' : 'auto',
    },
  };

  if (endAdornment) {
    defaultInputProps.endAdornment = endAdornment;
  }
  if (startAdornment) {
    defaultInputProps.startAdornment = endAdornment;
  }

  return (
    <TextField
      id="filled-basic"
      label={label}
      size="small"
      variant={variant}
      type="tel"
      InputProps={defaultInputProps}
      InputLabelProps={defaultLabelProps}
      onChange={handleChange}
      style={{ width: '100%' }}
    />
  );
};

export default TextInput;
