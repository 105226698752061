import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  featuresReducer,
  getFeatures,
  getFeaturesV2,
} from 'src/redux/features';
import { createRole } from 'src/redux/roles';

const AddRolesv2 = (props) => {
  const [activeRolesAndRights, setActiveRolesAndRights] = useState({});
  const [roleName, setRoleName] = useState('');
  const [errors, setErrors] = useState(false);

  const dispatch = useDispatch();
  const {
    loading,
    featuresList,
    featuresListV2: userRights,
  } = useSelector(featuresReducer);

  useEffect(() => {
    dispatch(getFeatures());
    dispatch(getFeaturesV2());
  }, []);

  useEffect(() => {
    props.fetchFunction({ handleSubmit });
  }, [activeRolesAndRights, roleName]);

  const handleSubmit = async () => {
    if (!roleName) {
      setErrors(true);
      return;
    }

    const featureListArray = [];
    featuresList.forEach((feature) => {
      const featureExtensible = JSON.parse(JSON.stringify(feature));
      if (activeRolesAndRights[featureExtensible.featureSetId]) {
        featureExtensible.accessRightList = [];
        featureExtensible.accessRightList =
          activeRolesAndRights[featureExtensible.featureSetId];
        delete featureExtensible.name;
        delete featureExtensible.route;
        featureListArray.push(featureExtensible);
      } else {
        featureExtensible.accessRightList = [1];
        delete featureExtensible.name;
        delete featureExtensible.route;
        featureListArray.push(featureExtensible);
      }
    });

    const role = {
      name: roleName,
      roleId: 0,
      featureSets: featureListArray,
    };
    let resp = await dispatch(createRole(role));
  };

  const groupFeaturesByParent = (features) => {
    const parentMap = {
      Project: [
        'Project Information',
        'Project Budget',
        'Project Boundary',
        'Project Linking',
      ],
      User: ['User Rights Management'],
      Billing: [
        'Contact and Contractor Management',
        'Bill Management',
        'Payout Management',
      ],
      Productivity: [
        'Measurement Management',
        'Change Management',
        'Quality Management',
        'Variation Management',
      ],
      Inventory: [
        'Material Masters',
        'Material Indenting',
        'Material Issuance',
        'Material Receiving',
      ],
    };

    const groupedFeatures = new Map();

    features.forEach((feature) => {
      Object.keys(parentMap).forEach((parent) => {
        if (parentMap[parent].includes(feature.name)) {
          if (!groupedFeatures.has(parent)) {
            groupedFeatures.set(parent, [feature]);
          } else {
            groupedFeatures.get(parent).push(feature);
          }
        }
      });
    });

    return groupedFeatures;
  };
  const groupedFeatures = groupFeaturesByParent(featuresList);

  const handleCheckbox = (featureSetId, accessRightId, value) => {
    const action = value ? 'ADD' : 'REMOVE';
    setActiveRolesAndRights((prev) => {
      const deepCopy = JSON.parse(JSON.stringify(prev));
      if (!deepCopy[featureSetId]) {
        deepCopy[featureSetId] = [];
      }
      if (action === 'ADD') {
        deepCopy[featureSetId] = [accessRightId];
      } // No need to add a remove action since a checkbox cannot be uncheckes, atleast on option is required
      return deepCopy;
    });
  };

  const renderUserRights = () => {
    return (
      <TableRow>
        <TableCell></TableCell>
        <TableCell>Module</TableCell>
        {userRights.map((right) => (
          <TableCell align="center">{right.name}</TableCell>
        ))}
      </TableRow>
    );
  };

  const rendeCheckbox = (featureSetId, accessRightId) => {
    const feature = JSON.parse(JSON.stringify(activeRolesAndRights));
    const featureSetAccess = feature[featureSetId];
    if (!featureSetAccess) {
      feature[featureSetId] = [1];
    }
    return (
      <Checkbox
        onChange={(e) =>
          handleCheckbox(featureSetId, accessRightId, e.target.checked)
        }
        checked={feature[featureSetId][0] === accessRightId}
        disabled={[4, 5].includes(accessRightId)}
        defaultChecked={accessRightId === 1}
      />
    );
  };

  const renderRightsOptions = (featureSetId) => {
    return (
      <>
        {userRights.map((right) => {
          return (
            <TableCell align="center">
              {rendeCheckbox(featureSetId, right.accessRightId)}
            </TableCell>
          );
        })}
      </>
    );
  };

  const renderBody = () => {
    if (groupedFeatures.entries().length === 0) {
      return <></>;
    }
    return (
      <TableBody>
        {Array.from(groupedFeatures.entries()).map(
          ([parent, childFeatures]) => (
            <React.Fragment key={parent}>
              {childFeatures.map((feature, index) => (
                <TableRow key={feature.featureSetId}>
                  {index === 0 && (
                    <TableCell rowSpan={childFeatures.length}>
                      {parent}
                    </TableCell>
                  )}
                  <TableCell
                    style={{ borderLeft: '1px solid rgba(1,1,1,0.1)' }}
                  >
                    {feature.name}
                  </TableCell>
                  {renderRightsOptions(feature.featureSetId)}
                </TableRow>
              ))}
            </React.Fragment>
          ),
        )}
      </TableBody>
    );
  };

  const renderLoading = () => loading && <>Loading ...</>;

  const renderRoleNameField = () => {
    const renderError = () => {
      return (
        <p
          style={{
            margin: '4px 14px 0px',
            color: 'red',
            fontSize: '12px',
            fontWeight: '0',
          }}
        >
          {errors && 'Name is required'}
        </p>
      );
    };
    return (
      <Grid item md={12} xs={12} style={{ marginBottom: '1rem' }}>
        <TextField
          fullWidth
          id="name"
          name="name"
          error={errors}
          value={roleName}
          variant="outlined"
          placeholder="Enter New Role Name Here"
          inputProps={{
            style: { fontFamily: 'Roboto' },
          }}
          onChange={(e) => {
            setRoleName(e.target.value);
            setErrors(false);
          }}
        />
        {renderError()}
      </Grid>
    );
  };
  return (
    <>
      {renderRoleNameField()}
      <Table size="small" style={{ border: '1px solid rgba(1,1,1,0.1)' }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={7} align="center">
              User Rights
            </TableCell>
          </TableRow>
          {renderUserRights()}
        </TableHead>
        {renderLoading()}
        {renderBody()}
      </Table>
    </>
  );
};

export default AddRolesv2;
